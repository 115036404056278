import { Link } from 'react-router-dom'
import { Box, Text } from '../../atoms/index'
import { PrimaryButton, SecondaryButton, TertiaryButton } from '../button/Buttons'
import { Colors } from './Colors'

export const SectionHeader = (props) => {

  const {
    title = '',
    subtitle,
    deleteButton = false,
    editButton = false,
    newButton = false,
    saveButton = false,
    logButton = false,
    linkIcon = false,
    linkIconHref = '/',
    style,
    barColor = 'primary.main',
    editButtonAction = () => { },
    deleteButtonAction = () => { },
    newButtonAction = () => { },
    saveButtonAction = () => { },
    logButtonAction = () => { }
  } = props

  const showDivider = logButton && (newButton || editButton || saveButton || deleteButton);
  const Divider = () => <Box sx={{ display: 'flex', width: 2, height: '70%', margin: `0px 4px`, backgroundColor: '#ccc' }} />

  return (
    <div style={{ ...{ display: 'flex', flexDirection: 'row', marginBottom: '15px' }, ...style }}>
      <Box sx={{ width: 6, backgroundColor: barColor, marginRight: 1, borderRadius: 1 }} />
      <div xs={6} style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Text style={styles.boldHeader}>{title}</Text>
          {linkIcon &&
            <Link to={{ pathname: linkIconHref }} target='_blank'>
              <Box sx={styles.linkIconContainer} />
            </Link>
          }
        </Box>
        {subtitle && <Text style={{ fontSize: 14, color: '#999' }}>{subtitle}</Text>}
      </div>
      <div xs={6} style={{ textAlign: 'right', display: 'flex', alignItems: 'center', gap: 8 }}>
        {newButton &&
          <PrimaryButton
            width='small'
            text='Novo'
            onClick={newButtonAction}
            style={{ width: 70 }}
          />
        }
        {editButton &&
          <SecondaryButton
            width='small'
            text='Editar'
            onClick={editButtonAction}
            style={{ width: 70 }}
          />
        }
        {saveButton &&
          <PrimaryButton
            width='small'
            text='Salvar'
            onClick={saveButtonAction}
            style={{ width: 70 }}
          />
        }
        {deleteButton &&
          <TertiaryButton
            width='small'
            text='Excluir'
            onClick={deleteButtonAction}
            textColor='#fff'
            style={{
              width: 70,
              backgroundColor: Colors.red,
              border: 'none',
              "&:hover": {
                backgroundColor: Colors.red + 'e8',
                cursor: 'pointer'
              }
            }}
          />
        }
        {showDivider ? <Divider /> : <></>}
        {logButton &&
          <TertiaryButton
            width='small'
            text='Histórico'
            onClick={logButtonAction}
            style={{ width: 70 }}
          />
        }
      </div>
    </div>
  )
}

const styles = {
  boldHeader: {
    fontSize: 26
  },
  linkIconContainer: {
    backgroundImage: `url('/icons/hiperlink_icon.png')`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 18,
    height: 18,
    opacity: 0.2,
    "&:hover": {
      opacity: 0.3
    }
  }
}