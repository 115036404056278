import { Link } from 'react-router-dom';
import { Box } from '../components/W3libComponents';

const Icon = {
   WhatsApp: ({ data = '' }) => (
      <IconElement
         href={`http://api.whatsapp.com/send/?phone=55${data?.replace(/\D/g, "")}`}
         iconUrl={`url('/icons/green_square_whatsapp.png')`}
      />),
   User: ({ href = '' }) => (
      <IconElement
         href={href}
         iconUrl={`url('/icons/green_user_icon.png')`}
      />),
   HomeKey: ({ href = '' }) => (
      <IconElement
         href={href}
         iconUrl={`url('/icons/home_key_icon.png')`}
      />)
}

{/* <Link to={{ pathname: `/homekeys/${contract?.home_key}` }} target='_blank'>
               <Box sx={{ ...styles.whatsappIcon, backgroundImage: `url('/icons/home_key_icon.png')` }} />
            </Link> */}


function IconElement({ href = null, iconUrl = '' }) {
   if (href) return (
      <Link to={{ pathname: href }} target="_blank">
         <Box sx={{ ...styles.icon, backgroundImage: iconUrl }} />
      </Link>
   );

   return <></>;
}

const styles = {
   icon: {
      backgroundImage: `url('/icons/green_square_whatsapp.png')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 20,
      height: 20,
      "&:hover": {
         cursor: 'pointer',
         opacity: 0.8
      }
   }
}

export default Icon;