import { pluralize } from "../helpers/pluralize";

export const getStatus = (status) => {
  if (status === "open") return "Aberto";
  if (status === "closed") return "Finalizado";
  return status;
};

export const getProjectStatus = (status) => {
  if (status === "opened") return "Aberto";
  if (status === "closed") return "Finalizado";
  return status;
};

export const getTaskType = (type) => {
  if (type === "task") return "Solicitação";
  if (type === "error") return "Erro";
  return type;
};

export const filterByKey = (arr = [], field, value) => {
  const arrField = field.split(".");

  return arr.filter((item) => {
    if (value) {
      if (arrField.length > 1) return item[arrField[0]][arrField[1]] === value;

      return item[arrField[0]] === value;
    }

    return true;
  });
};

export const calcPeriodDuration = (duration) => {
  if (!duration) return;

  const days = Math.floor(duration / 1440);
  const hours = Math.floor(Math.floor(duration % 1440) / 60);
  const minutes = duration % 60;

  return `${
    Boolean(days)
      ? `${days} ${pluralize("dia", days)}${Boolean(hours) ? "," : ""}`
      : ""
  }
  ${Boolean(hours) ? `${hours} ${pluralize("hora", hours)}` : ""}${
    Boolean(hours) && Boolean(minutes) ? " e " : ""
  }${Boolean(minutes) ? `${minutes} ${pluralize("minuto", minutes)}` : ""}`;
};
