import { useEffect, useState } from "react";
import { Format } from "../../../helpers/Formatter";
import api from "../../../services/api";
import { ContentContainer, SectionHeader, Text } from "../../W3libComponents";
import { Colors } from "../../organisms/layout/Colors";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { Table } from "../../organisms/table/Table";

const requestsStatus = [
  {
    status: 'pending',
    text: 'Pendentes',
    borderColor: Colors.yellow,
    total: 0
  },
  {
    status: 'approved',
    text: 'Aprovadas',
    borderColor: Colors.lemonGreen,
    total: 0
  },
  {
    status: 'rejected',
    text: 'Rejeitadas',
    borderColor: Colors.red,
    total: 0
  },
];

async function fetchUnits(status = null) {
  return await api.get(`${process.env.REACT_APP_API_URL}/v3/admin/unit-owners?status=${status}&fields=unit,status,building,createdAt,name`).then(response =>
    response).catch(error => error);
}

export function ListUnits() {
  const [selectedStatus, setSelectedStatus] = useState(requestsStatus[0])
  const [units, setUnits] = useState([]);

  const approvedUnits = units.reduce((sum, unit) => unit?.status === 'approved' ? (sum + 1) : sum, 0);

  useEffect(() => {
    async function fetchData() {
      const response = await fetchUnits();
      setUnits(response?.data)
    }
    return fetchData();
  }, [selectedStatus])

  return (
    <>
      <SectionHeader title={`Unidades`} subtitle={`Aprovadas: ${approvedUnits}`} />
      {units.length > 0 ?
        <ContentContainer>
          <Units units={units} />
        </ContentContainer>
        :
        <></>
      }
    </>
  )
}

const tableHeader = [
  { text: 'Status', width: 120 },
  { text: 'Data', width: 120 },
  { text: 'Nome' },
  { text: 'Locker', width: 220 },
  { text: 'Unidade', width: 120 },
];

const statusOptions = [
  {
    value: 'approved',
    display: 'Aprovado',
    color: Colors.green
  },
  {
    value: 'pending',
    display: 'Pendente',
    color: Colors.yellow
  },
  {
    value: 'rejected',
    display: 'Rejeitado',
    color: Colors.red
  }
]

function Units({ units = [] }) {
  return (
    <>
      <Table size='small'
        header={tableHeader}
        rows={units.map(unit => {
          return {
            to: `/building-unit/${unit._id}`,
            data: [
              <StatusLabel background='light' currentStatus={unit?.status} dropDownOptions={statusOptions} />,
              <Text>{Format.date(unit?.createdAt)}</Text>,
              <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{unit?.name}</Text>,
              <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{unit?.building?.description}</Text>,
              <Text>{unit?.unit}</Text>
            ]
          }
        })}
      />
    </>
  )
};