import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { ContentContainer, SectionHeader } from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { Colors } from "../../organisms/layout/Colors";
import { Row } from "../../organisms/table/DetailsRow";

export const EvaluationDetails = (props) => {
  const location = useLocation();
  const history = useHistory();
  const contract_id = location.pathname.split("/")[3];
  const { context } = props;
  const { alert, user } = context;

  const [contract, setContract] = useState({});

  const loadContract = async () => {
    try {
      const response = await api.get(
        `/v3/admin/evaluated-contract/${contract_id}`
      );
      const { contract } = response.data;
      setContract(contract);
      document.title = `TuimBox Admin - Avaliação`;
    } catch (error) {
      alert.error("Desculpe. Não foi possível carregar esta avaliação.");
      console.log(error);
    }
  };

  useEffect(() => {
    loadContract();
  }, []);

  const handleContract = useCallback(
    (id) => history.push(`/contracts/${id}`),
    [history]
  );

  const getTopicEvaluation = (topicEvaluation = null) => {
    if (topicEvaluation) {
      let greenStars = topicEvaluation;
      let grayStars = 5 - greenStars;

      return (
        <Box sx={{ display: "flex", gap: 0.2 }}>
          {[...Array(greenStars)].map((e) => (
            <Box style={styles.greenStars} />
          ))}
          {[...Array(grayStars)].map((e) => (
            <Box style={styles.grayStar} />
          ))}
        </Box>
      );
    }
    return <></>;
  };

  const getAvarageRating = () => {
    if (contract?.evaluation) {
      const { cleaning, conservation, costBenefit, quality } =
        contract?.evaluation;
      let avarage = (cleaning + conservation + costBenefit + quality) / 4;
      return `${avarage} / 5`;
    }
    return `-`;
  };

  return (
    <>
      <SectionHeader
        title={`Avaliação de ${contract?.products?.name}`}
        subtitle={`Média: ${getAvarageRating()}`}
      />
      <Box style={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
        <ContentContainer
          row
          style={{ minWidth: 550, display: "flex", flex: "1" }}
        >
          <Row
            data={[
              {
                label: "Data",
                description: formatTimestamp({
                  timestamp: contract?.createdAt,
                  showSeconds: false,
                }),
              },
              { label: "Produto", description: contract?.products?.name },
              {
                label: "Porta",
                description:
                  contract?.locker?.door?.code || contract?.id_door?.codLoopBox,
              },
              { label: "Usuário", description: contract?.id_user?.name },
              { label: "E-mail", description: contract?.id_user?.email },
              {
                label: "Telefone",
                description: (
                  <Box style={{ display: "flex" }}>
                    <Text style={styles.cellText}>
                      {contract?.id_user?.mobileNumber}
                    </Text>
                    {contract?.id_user?.mobileNumber && (
                      <Link
                        to={{
                          pathname: `https://api.whatsapp.com/send/?phone=55${contract?.id_user?.mobileNumber.replace(
                            /\D/g,
                            ""
                          )}`,
                        }}
                        target="_blank"
                      >
                        <Box sx={styles.whatsappIcon} />
                      </Link>
                    )}
                  </Box>
                ),
              },
              { label: "CPF", description: contract?.id_user?.cpf },
              {
                label: "ID Contrato",
                description: (
                  <Box style={{ display: "flex" }}>
                    <Text style={styles.cellText}>{contract?._id || "-"}</Text>
                    <Box
                      sx={styles.contractIcon}
                      onClick={() => handleContract(contract?._id)}
                    />
                  </Box>
                ),
              },
            ]}
          />
          <Row
            // rowGap={0}
            data={[
              {
                label: "Limpeza",
                description: getTopicEvaluation(contract?.evaluation?.cleaning),
              },
              {
                label: "Conservação",
                description: getTopicEvaluation(
                  contract?.evaluation?.conservation
                ),
              },
              {
                label: "Custo/Benefício",
                description: getTopicEvaluation(
                  contract?.evaluation?.costBenefit
                ),
              },
              {
                label: "Qualidade",
                description: getTopicEvaluation(contract?.evaluation?.quality),
              },
              {
                label: "Observação",
                flexColumn: true,
                description: (
                  <Box>
                    <Text style={{ ...styles.cellText, height: "auto" }}>
                      {contract?.evaluation?.observation || "-"}
                    </Text>
                  </Box>
                ),
              },
            ]}
          />
        </ContentContainer>

        {contract._id && (
          <ContentContainer style={{ flex: 1, minWidth: 550 }}>
            <InternalNotes
              reference={{ type: "contracts", id: contract._id }}
              authorId={user?._id}
            />
          </ContentContainer>
        )}
      </Box>
    </>
  );
};

const styles = {
  cellContainer: {
    backgroundColor: Colors.lightBackground,
    padding: 2,
    borderRadius: 2,
    display: "flex",
    overflow: "auto",
  },
  cellText: {
    fontSize: 14,
    height: 22,
  },
  cellTextBold: {
    fontSize: 14,
    fontFamily: "UbuntuBold",
    height: 22,
  },
  whatsappIcon: {
    backgroundImage: `url('/icons/green_square_whatsapp.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  contractIcon: {
    backgroundImage: `url('/icons/gray_contract.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  greenStars: {
    backgroundImage: `url('/icons/green_star.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  grayStar: {
    backgroundImage: `url('/icons/gray_star.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
};
