import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Format } from "../../../helpers/Formatter";
import Icon from "../../../helpers/Icon";
import { homeKeyGenerator } from "../../../helpers/homeKeyGenerator";
import { updatedFieldsConstructor } from "../../../helpers/updatedFieldsConstructor";
import api from "../../../services/api";
import { Box, ContentContainer, SectionHeader, Text, TextField } from "../../W3libComponents";
import { SecondaryButton } from "../../organisms/button/Buttons";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { Row } from "../../organisms/table/DetailsRow";

const statusOptions = [
   {
      value: 'approved',
      display: 'Aprovado',
      color: Colors.green
   },
   {
      value: 'pending',
      display: 'Pendente',
      color: Colors.yellow
   },
   {
      value: 'rejected',
      display: 'Rejeitado',
      color: Colors.red
   }
]

async function fetchUnit(id) {
   return await api.get(`${process.env.REACT_APP_API_URL}/v3/admin/unit-owners/${id}`).then(response =>
      response).catch(error => error);
}

async function updateUnit({ id, updatedData }) {
   return await api.patch(`${process.env.REACT_APP_API_URL}/v3/admin/unit-owners/${id}`, updatedData).then(response =>
      response).catch(error => error);
}

async function createHomeKey(homeKeyData) {
   return await api.post(`/v3/admin/homekey`, homeKeyData).then(response =>
      response).catch(error => error);
}

async function updateUser({ userId, updatedData }) {
   return await api.patch(`/v3/app/user/${userId}`, updatedData).then(response =>
      response).catch(error => error);
}

function notifyUser({ phone, home_key }) {
   if (phone && home_key) {
      const text = encodeURI(`Olá, sua chave TuimBox *${home_key}* já está cadastrada! Aproveite hoje mesmo os benefícios exclusivos do seu armário completo por assinatura. Faça logout e login no aplicativo para a ativação.`);
      window.open(`http://api.whatsapp.com/send/?phone=55${phone}&text=${text}`)
   }
}

export function EditUnit({ context }) {
   const { user } = context
   const { id } = useParams();
   const [unitData, setUnitData] = useState({})
   const [originalData, setOriginalData] = useState({})
   const [updatedFields, setUpdatedFields] = useState([])
   const [homeKey, setHomeKey] = useState('')
   const { _id: authorId } = user
   const userHasHomeKey = unitData?.id_user?.home_key?.home_key;
   const userHomeKey = userHasHomeKey;
   const userHomeKeyId = unitData?.id_user?.home_key?._id;
   const updatedUnitFields = updatedFieldsConstructor(updatedFields, originalData, unitData);
   const adminUpdatedFields = updatedFields.length > 0;

   function handleChange({ name, value }) {
      setUpdatedFields([...updatedFields.filter(item => item !== name), name])
      setUnitData({ ...unitData, [name]: value })
   }

   async function handleUpdate() {
      const statusApproved = updatedUnitFields?.some(item => item.name === 'status' && item.nextValue === 'approved');
      let home_key = ''

      await updateUnit({ id, updatedData: unitData });

      if (!userHasHomeKey && homeKey) {
         const newHomeKey = await createHomeKey({
            home_key: homeKey,
            buildingUnit: unitData?.unit,
            email: unitData?.email,
            locker_id: unitData?.building?._id,
         });

         if (newHomeKey?.status === 201) {
            home_key = newHomeKey?.data?.homekey?.home_key;
            await updateUser({ userId: unitData?.id_user?._id, updatedData: { home_key } })
         }
      }

      if (statusApproved) notifyUser({ phone: unitData?.phone, home_key });
      window.location.reload();
   }

   useEffect(() => {
      async function fetchData() {
         const response = await fetchUnit(id);
         const homeKey = response?.data?.id_user?.home_key?.home_key;
         if (homeKey) setHomeKey(homeKey)
         setOriginalData(response?.data)
         setUnitData(response?.data)
      }
      return fetchData();
   }, [])

   return (
      <>
         <SectionHeader
            title={`${unitData?.name}`}
            saveButton={adminUpdatedFields}
            saveButtonAction={handleUpdate}
         />
         <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
               <ContentContainer fullWidth>
                  <SmallSectionTitle title='Dados do Solicitante' />
                  <Row
                     rowGap={1.5}
                     columnGap={0}
                     data={[
                        { label: 'Status', description: <StatusLabel background='light' currentStatus={unitData?.status} dropDownOptions={statusOptions} dropDown dropDownOptionAction={(value) => handleChange({ name: 'status', value })} /> },
                        { label: 'Empreendimento', description: <Text>{unitData?.building?.description}</Text> },
                        { label: 'Unidade', description: <Text>{unitData?.unit}</Text> },
                        { label: "Chave TuimBox", description: <Box sx={{ display: 'flex', gap: 1 }}><Text style={{ color: userHomeKey ? '#555' : '#bbb' }}>{userHomeKey || 'Não Cadastrada'}</Text>{userHomeKey && <Icon.HomeKey href={`/homekeys/${userHomeKeyId}`} />}</Box> },
                        { label: 'Email', description: <Text>{unitData?.email}</Text> },
                        { label: "Telefone", description: <Box sx={{ display: 'flex', gap: 1 }}><Text>{unitData?.phone}</Text><Icon.WhatsApp data={unitData?.phone} /></Box> },
                        { label: "Já cadastrado", description: <Text>{unitData?.alreadyRegistered ? 'Sim' : 'Não'}</Text> },
                        { label: "Cadastro em", description: <Box sx={{ display: 'flex', gap: 1 }}><Text>{Format.date(unitData?.id_user?.createdAt, true)}</Text><Icon.User data={unitData?.id_user?._id} href={`/clients/${unitData?.id_user?._id}`} /></Box> },
                        { label: "Data da Solicitação", description: <Text>{Format.date(unitData?.createdAt, true)}</Text> },
                     ]}
                  />
                  {userHasHomeKey ?
                     <></>
                     :
                     <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
                        <TextField
                           variant="standard"
                           label="Chave Tuimbox"
                           name="home_key"
                           margin="dense"
                           size='small'
                           value={homeKey || ''}
                           fullWidth
                           autoFocus
                           onChange={(e) => setHomeKey(e.target.value)}
                           InputProps={{ style: { fontSize: 14 } }}
                           InputLabelProps={{ style: { color: '#888' } }}
                        />
                        {!homeKey &&
                           <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                              <SecondaryButton
                                 width='large'
                                 text='Gerar Chave'
                                 onClick={() => setHomeKey(homeKeyGenerator())}
                              />
                           </Box>
                        }
                     </Box>
                  }
               </ContentContainer>
               <ContentContainer>
                  <SmallSectionTitle title='Documentos' />
                  <Box sx={{ display: 'flex', gap: 2, flex: 1, overflow: 'hidden' }}>
                     <Image url={unitData?.documents?.id} text='RG ou CNH' />
                     <Image url={unitData?.documents?.proofOfResidence} text='Comprovante de Residência' />
                  </Box>
               </ContentContainer>
            </Box>

            <ContentContainer fullWidth>
               <InternalNotes
                  reference={{ type: 'Unit', id }}
                  authorId={authorId}
               />
            </ContentContainer>
         </Box>
      </>
   )
}

function Image({ url = null, text = null }) {
   if (url) {
      const isPDF = url.split(".").at(-1) === 'pdf';
      return (
         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', flex: 1, overflow: 'hidden', }}>
            <Box sx={{
               borderRadius: 2,
               overflow: 'hidden',
               border: `1px solid #eee`,
               "&:hover": {
                  cursor: 'pointer',
                  opacity: 0.8
               }
            }}
               onClick={() => window.open(`${url}`)}
            >
               {isPDF ?
                  <iframe
                     id="your-iframe-id"
                     src={url}
                     width={180}
                     height={180}
                     style={{
                        pointerEvents: 'none',
                        border: 'none',
                        overflow: 'hidden',
                     }} />
                  :
                  <Box sx={{
                     display: 'flex',
                     width: 180,
                     height: 180,
                     backgroundImage: `url(${url})`,
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                  }} />
               }
            </Box>
            {text && <Text small='true' style={{ fontStyle: 'italic' }}>{text}</Text>}
         </Box >
      )
   }
   return <></>
}