export const formatTimestamp = ({ timestamp = null, birthday = false, showTime = true, showSeconds = true, showFullYear = true }) => {
   if (timestamp) {
      let newTimestamp = new Date(timestamp)

      if (!birthday) {
         let timezoneDifference = newTimestamp.getTimezoneOffset()
         newTimestamp.setTime(newTimestamp.getTime() - (timezoneDifference * 60 * 1000))
      }

      let splittedTimestamp = newTimestamp.toJSON().split('T');
      let formatDate = splittedTimestamp[0].split('-').reverse();
      let formatedTime = splittedTimestamp[1].split('.');

      const day = formatDate[0];
      const month = formatDate[1];
      const year = showFullYear ? formatDate[2] : formatDate[2].slice(2);

      formatDate = `${day}/${month}/${year}`
      formatedTime = formatedTime[0]

      if (!showSeconds) {
         let splittedTime = formatedTime.split(":")
         formatedTime = `${splittedTime[0]}:${splittedTime[1]}`
      }

      return (!showTime || birthday) ? formatDate : `${formatDate} às ${formatedTime}`
   }
   return `-`
}