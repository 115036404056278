export const Colors = {
   background: '#f4f4f4',
   darkerBackground: '#e7e7e7',

   lightBackground: '#f3f3f3',
   lighterBackground: '#6b858e10',
   hover: '#ebeeef',
   selectedBackground: '#6b858e54',
   darkGreen: '#094047',
   darkGreenHover: '#caee3033',
   lemonGreen: '#02ae23',
   lightgreen: '#caee30',

   red: '#e3430a',
   yellow: '#ffd200',
   green: '#02ae23',
}