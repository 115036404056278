import { useEffect, useState } from 'react'
import moment from 'moment'
import { Paper, Box, Text, TextField, DateRangeInput, FormControl, InputLabel, Select, MenuItem, Button } from '../../W3libComponents'
import { filterByKey } from '../../../utils'

export const TaskFilters = ({ tasks = [], filteredTasks, setFilteredTasks, orderBy }) => {

    const filtersInitialState = {
        query: '',
        createdBy: '',
        company: '',
        assignTo: '',
        status: 'open',
        participants: '',
        startDate: null,
        endDate: null
    }
    const [filters, setFilters] = useState(filtersInitialState)

    useEffect(async () => {
        if (window.localStorage.getItem('w3lib-admin-filters')) {
            const localfilters = await JSON.parse(window.localStorage.getItem('w3lib-admin-filters'))
            setFilters(localfilters)
        }

    }, []);

    useEffect(() => {
        window.localStorage.setItem('w3lib-admin-filters', JSON.stringify(filters));
    }, [filters]);

    const handleFilter = ({ target }) => {
        const { name, value } = target
        console.log(target)
        setFilters({ ...filters, [name]: value })
    }

    const handleChangeDate = (value) => {
        setFilters({
            ...filters,
            startDate: value[0],
            endDate: value[1]
        })
    }

    useEffect(() => {
        let newTasks = tasks.filter(task => {
            if (task.title.toLowerCase().includes(filters.query.toLowerCase()))
                return task

            if (task.number === parseInt(filters.query))
                return task

        })
            .filter(task => {
                if (filters.startDate) {
                    return moment(new Date(task.createdAt)).format('YYYY-MM-DD 00:00:00') >= moment(new Date(filters.startDate)).subtract(1, 'days').format('YYYY-MM-DD 00:00:00')
                }
                return task
            })
            .filter(task => {
                if (filters.endDate) {
                    return moment(new Date(task.createdAt)).format('YYYY-MM-DD 00:00:00') <= moment(new Date(filters.endDate)).format('YYYY-MM-DD 23:59:59')
                }
                return task
            })


        newTasks = filterByKey(newTasks, 'createdBy._id', filters.createdBy)
        newTasks = filterByKey(newTasks, 'company._id', filters.company)
        newTasks = filterByKey(newTasks, 'assignTo._id', filters.assignTo)
        newTasks = filterByKey(newTasks, 'participants._id', filters.participants)
        newTasks = filterByKey(newTasks, 'status', filters.status)
        
        newTasks = newTasks.sort((a, b) => {
            if (orderBy.createdAt === "asc") {
                if (a.number > b.number) {
                    return 1;
                } else if (b.number > a.number) {
                    return -1;
                }
                return 0
            }
            else {
                if (a.number > b.number) {
                    return -1;
                } else if (b.number > a.number) {
                    return 1;
                }
                return 0
            }
        })

        // newTasks = newTasks.sort((a, b) => {
        //     if (orderBy.lastInteraction === "asc") {
        //         if (a.interactions[a.interactions.length -1].createdAt > b.interactions[b.interactions.length -1].createdAt) {
        //             return 1;
        //         } else if (b.interactions[b.interactions.length -1].createdAt > a.interactions[a.interactions.length -1].createdAt) {
        //             return -1;
        //         }
        //         return 0
        //     }
        //     else {
        //         if (a.interactions[a.interactions.length -1].createdAt > b.interactions[b.interactions.length -1].createdAt) {
        //             return -1;
        //         } else if (b.interactions[b.interactions.length -1].createdAt > a.interactions[a.interactions.length -1].createdAt) {
        //             return 1;
        //         }
        //         return 0
        //     }
        // })

        setFilteredTasks(newTasks)

    }, [tasks, filters, orderBy])

    return (
        <Paper sx={{ padding: 2, marginBottom: 1 }}>
            <Box sx={{ flexDirection: 'row', justifyContent: 'space-between', display: 'flex' }}>
                <Text sx={{ fontWeight: '700' }}>Filtros</Text>
                <Text>{`Mostrando ${filteredTasks.length} de ${tasks.length} chamados`}</Text>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                <TextField
                    size='small'
                    name='query'
                    placeholder='Busque pelo nome ou número do chamado'
                    onChange={handleFilter}
                    sx={{ margin: 0, marginRight: 1 }}
                />
                <DateRangeInput handleChangeDate={handleChangeDate} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: 1 }}>
                <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
                    <InputLabel id="createdByLabelselect">Autor</InputLabel>
                    <Select
                        size='small'
                        name="createdBy"
                        labelId="createdByLabelselect"
                        id="createdBy-select"
                        value={filters.createdBy}
                        label="Autor"
                        // size="small"
                        onChange={handleFilter}
                    >
                        <MenuItem value=''>Todos</MenuItem>
                        {
                            [...new Map(tasks.map(task => task.createdBy).map(item =>
                                [item['_id'], item])).values()
                            ].map(task => (
                                <MenuItem value={task._id} key={`createdBy${task._id}`}>{task.name}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
                    <InputLabel id="companyLabelselect">Cliente</InputLabel>
                    <Select
                        size='small'
                        name="company"
                        labelId="companyLabelselect"
                        id="company-select"
                        value={filters.company}
                        label="Cliente"
                        // size="small"
                        onChange={handleFilter}
                    >
                        <MenuItem value=''>Todos</MenuItem>
                        {
                            [...new Map(tasks.map(task => task.company).map(item =>
                                [item['_id'], item])).values()
                            ]
                                .map(task => (
                                    <MenuItem value={task._id} key={`company${task._id}`}>{task.name}</MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
                <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
                    <InputLabel id="assignToLabelselect">Executor</InputLabel>
                    <Select
                        size='small'
                        name="assignTo"
                        labelId="assignToLabelselect"
                        id="assignTo-select"
                        value={filters.assignTo}
                        label="Executor"
                        // size="small"
                        onChange={handleFilter}
                    >
                        <MenuItem value=''>Todos</MenuItem>
                        {
                            [...new Map(tasks.map(task => task.assignTo).map(item =>
                                [item['_id'], item])).values()
                            ]
                                .map(task => (
                                    <MenuItem value={task._id} key={`assignTo${task._id}`}>{task.name}</MenuItem>
                                ))
                        }
                    </Select>
                </FormControl>
                <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 100, marginRight: 1 }}>
                    <InputLabel id="statusLabel-select">Status</InputLabel>
                    <Select
                        size='small'
                        name="status"
                        labelId="statusLabel-select"
                        id="status-select"
                        value={filters.status}
                        label="Status"
                        onChange={handleFilter}
                    >
                        <MenuItem value={null}>Todos</MenuItem>
                        <MenuItem value={"open"}>Aberto</MenuItem>
                        <MenuItem value={"closed"}>Fechado</MenuItem>
                    </Select>
                </FormControl>
                <FormControl size='small' fullWidth sx={{ width: '8rem', minWidth: 150, marginRight: 1 }}>
                    <InputLabel id="participantLabelselect">Participante</InputLabel>
                    <Select
                        size='small'
                        name="participants"
                        labelId="participantLabelselect"
                        id="participant-select"
                        value={filters.participant}
                        label="Participante"
                        onChange={handleFilter}
                    >
                        <MenuItem value=''>Todos</MenuItem>
                        {
                            tasks.filter(task => task.participants.length > 0)
                            .map(taskParticipants => taskParticipants.participants)
                            .map(element => element.map(participant => 
                            <MenuItem value={participant._id} key={`participant${participant._id}`}>{participant.name}</MenuItem>))
                        }
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', flex: 1 }}></Box>
                <Button onClick={() => setFilters({ ...filtersInitialState, status: '' })}>Limpar Filtros</Button>
            </Box>

        </Paper>
    )
}