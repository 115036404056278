import { useEffect, useState } from 'react'
import { Box, Text } from '../../atoms'
import { Colors } from '../layout/Colors'

export const Pagination = (props) => {

   const {
      dataLength = 0,
      initialItemsPerPage,
      localStorageName = '',
      onChange = () => { }
   } = props

   const [pagination, setPagination] = useState(10)
   const [currentPage, setCurrentPage] = useState(1)
   const [showPaginationOptions, setShowPaginationOptions] = useState(false)

   const totalPages = Math.ceil(dataLength / pagination);
   const activePage = <>{`${currentPage}/${totalPages}`}</>

   const handleSetPagination = (pagination) => {
      setShowPaginationOptions(false)
      setPagination(pagination)
      setCurrentPage(1)
      window.localStorage.setItem(localStorageName, JSON.stringify(pagination));
   }

   const dataFilterExpression = (item, index) => {
      if (currentPage === 1) { return index <= pagination - 1 }
      else { return index >= (pagination) * (currentPage - 1) && index <= (pagination * currentPage) - 1 }
   };

   useEffect(() => setPagination(initialItemsPerPage), [initialItemsPerPage])
   useEffect(() => onChange(() => dataFilterExpression), [currentPage, pagination])

   return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 1.3 }}>
         <Text style={styles.text}>Exibindo</Text>
         <Box sx={{ ...styles.paginationDropDownContainer, borderRadius: showPaginationOptions ? `8px 8px 0px 0px` : '8px' }} onClick={(event) => {
            event.preventDefault()
            setShowPaginationOptions(!showPaginationOptions)
         }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
               <Text style={{ ...styles.text }}>{pagination}</Text>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box sx={{ ...styles.dropDownIcon, backgroundImage: showPaginationOptions ? `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`, }} />
               </Box>
            </Box>
            {showPaginationOptions &&
               <Box sx={{ ...styles.paginationDropDownOptionsContainer, borderRadius: `0px 0px 8px 8px` }}>
                  <Box sx={styles.paginationDropDownOptions}
                     onClick={() => handleSetPagination(10)}>
                     <Text style={styles.text}>10</Text>
                  </Box>
                  <Box sx={styles.paginationDropDownOptions}
                     onClick={() => handleSetPagination(20)}>
                     <Text style={styles.text}>20</Text>
                  </Box>
                  <Box sx={{ ...styles.paginationDropDownOptions, border: 'none' }}
                     onClick={() => handleSetPagination(50)}>
                     <Text style={styles.text}>50</Text>
                  </Box>
               </Box>
            }
         </Box>
         <Box>
            <Text style={{ ...styles.text, whiteSpace: 'nowrap' }}>por página.</Text>
         </Box>

         <Box style={{ display: 'flex' }}>
            <Box sx={styles.paginationButtonContainer} onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}>
               <Box sx={styles.paginationButton} />
            </Box>
            <Box style={{ paddingLeft: 5, paddingRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <Text style={styles.text}>{activePage}</Text>
            </Box>
            <Box sx={styles.paginationButtonContainer} onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}>
               <Box sx={{
                  ...styles.paginationButton,
                  backgroundImage: `url('/icons/gray_arrow_up.png')`,
                  transform: 'rotate(90deg)'
               }} />
            </Box>
         </Box>
      </Box>
   )
}

const styles = {
   text: {
      fontSize: '14px'
   },
   paginationDropDownContainer: {
      position: 'relative',
      width: 70,
      padding: 0.7,
      border: `1px solid #ddd`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 2,
      "&:hover": {
         backgroundColor: Colors.background,
         cursor: 'pointer'
      }
   },
   paginationDropDownOptionsContainer: {
      display: 'flex',
      position: 'absolute',
      top: 32,
      flexDirection: 'column',
      width: '100%',
      backgroundColor: '#fff',
      border: `1px solid ${Colors.darkerBackground}`,
      zIndex: 99999
   },
   paginationDropDownOptions: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: 0.7,
      paddingBottom: 0.7,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: '#ddd',
      "&:hover": {
         backgroundColor: Colors.background,
         cursor: 'pointer'
      }
   },
   paginationButtonContainer: {
      position: 'relative',
      height: 25,
      width: 25,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      "&:hover": {
         backgroundColor: '#6b858e14',
         cursor: 'pointer'
      }
   },
   paginationButton: {
      backgroundImage: `url('/icons/gray_arrow_up.png')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 15,
      height: 15,
      transform: 'rotate(-90deg)'
   },
   dropDownIcon: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 12,
      height: 12,
   },
}