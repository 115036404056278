import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import { calcPeriodDuration } from "../../../utils";
import { ContentContainer, SectionHeader } from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { NewPeriod } from "./NewPeriod";

const tableHeader = {
  name: {
    text: "Nome",
  },
  duration: {
    text: "Duração",
    width: 200,
  },
  tolerance: {
    text: "Tolerância",
    width: 200,
  },
};

export const ListRentalPeriods = () => {
  const { data, refreshData } = useFetch({ url: `/v3/rental-periods` });

  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [filters, setFilters] = useState({
    name: "name",
    order: 1,
  });

  const [showNewPeriodModal, setShowNewPeriodModal] = useState(false);

  const handleShowNewPeriodModal = () => {
    if (showNewPeriodModal) return;
    setShowNewPeriodModal(true);
  };

  const handleCloseNewPeriodModal = () => {
    setShowNewPeriodModal(false);
    setSelectedPeriod(null);
  };

  const handleEditPeriod = (periodData) => {
    setSelectedPeriod(periodData);
  };

  useEffect(() => {
    data.sort((a, b) => {
      const [itemA, itemB] = filters.order === 1 ? [a, b] : [b, a];
      const current = itemA[filters.name];
      const next = itemB[filters.name];

      if (typeof current === "number")
        return current > next ? 1 : next > current ? -1 : 0;

      return current.localeCompare(next, "pt", {
        numeric: typeof current === "number",
      });
    });
  }, [filters]);

  return (
    <>
      <SectionHeader
        title={`Períodos`}
        newButtonAction={handleShowNewPeriodModal}
        newButton={true}
      />
      <ContentContainer>
        {/* <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar
            placeholder='0512, Batedeira, Mini029...'
            onChange={({ target }) => setSearchFilter(target.value)}
          />
          <Pagination
            localStorageName='tuimbox-list-doors-pagination'
            dataLength={doors.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box> */}
        <Box
          style={{
            border: "1px solid #e4e4e4",
            backgroundColor: "#fff",
            borderRadius: 5,
          }}
        >
          <Box style={{ width: "100%", display: "flex" }}>
            {Object.entries(tableHeader).map(([field, data]) => (
              <Box
                key={field}
                style={{
                  ...styles.tableHeadRow,
                  ...(data.width ? { width: data.width } : { flex: 1 }),
                }}
              >
                <Text
                  style={{
                    ...styles.tableHeadText,
                    textDecorationLine:
                      filters.name === field ? "underline" : "none",
                  }}
                >
                  {data.text}
                </Text>
                <Box
                  sx={{
                    ...styles.checkIcon,
                    ...styles.filterIconContainer,
                    backgroundImage:
                      filters.name === field
                        ? filters.order === 1
                          ? `url('/icons/gray_arrow_up.png')`
                          : `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`,
                  }}
                  onClick={() =>
                    setFilters((prev) => {
                      return {
                        name: field,
                        order:
                          prev.name === field ? prev.order * -1 : prev.order,
                      };
                    })
                  }
                ></Box>
              </Box>
            ))}
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            {data.map((period) => (
              <Box
                sx={styles.tableRow}
                key={period._id}
                onClick={() => handleEditPeriod(period)}
              >
                <Box
                  style={{
                    ...styles.row,
                    ...(tableHeader?.["name"]?.width
                      ? { width: tableHeader?.["name"]?.width }
                      : { flex: 1 }),
                  }}
                >
                  <Text style={styles.cellText}>{period.name}</Text>
                </Box>
                <Box
                  style={{
                    ...styles.row,
                    ...(tableHeader?.duration?.width
                      ? { width: tableHeader?.duration?.width }
                      : { flex: 1 }),
                  }}
                >
                  <Text style={styles.cellText}>
                    {calcPeriodDuration(period.duration)}
                  </Text>
                </Box>
                <Box
                  style={{
                    ...styles.row,
                    ...(tableHeader?.tolerance?.width
                      ? { width: tableHeader?.tolerance?.width }
                      : { flex: 1 }),
                  }}
                >
                  <Text style={styles.cellText}>
                    {calcPeriodDuration(period.tolerance)}
                  </Text>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </ContentContainer>
      {showNewPeriodModal || selectedPeriod ? (
        <NewPeriod
          handleClose={handleCloseNewPeriodModal}
          refreshData={refreshData}
          periodData={selectedPeriod}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const styles = {
  root: {
    width: "100%",
    marginTop: 10,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadRow: {
    display: "flex",
    alignItems: "center",
    padding: `12px 20px`,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    fontWeight: 600,
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    padding: `8px 20px`,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
};
