export const DatePicker = (props) => {

   const {
      value,
      onChange = () => { },
      style = {},
      min = '',
      max = ''
   } = props;

   return (
      <input
         type="date"
         value={value}
         onChange={onChange}
         style={style}
         // The line below prevents the user from type the date
         onKeyDown={(e) => e.preventDefault()}
         min={min}
         max={max}
      />
   )
}