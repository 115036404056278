import { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
// import { cpfValidator } from '../../../helpers/user/cpfValidator';
import { emailValidator } from '../../../helpers/user/emailValidator';
import api from '../../../services/api';
import { Box, CheckBox, ContentContainer, SectionHeader, TextField } from "../../W3libComponents";
import { PrimaryButton, SecondaryButton } from '../../organisms/button/Buttons';

export const UserEdit = ({ context }) => {

   const { id } = useParams();
   const newUser = id === 'new';
   const history = useHistory()

   const reducer = (prev, next) => { return { ...prev, ...next } }
   const [user, setUser] = useReducer(reducer, { permission: 'Administrador' })

   const [lockers, setLockers] = useState([])
   const { setLoading, alert } = context

   const loadUserData = async () => {
      setLoading(true)
      await api.get(`/v3/admin/user/${id}`).then(response => {
         const { data = {} } = response
         if (data) {
            const { name, email, mobileNumber, permission, lockers } = data
            setUser({ name, email, mobileNumber, permission, lockers })
         }
         setLoading(false)
      }).catch(err => {
         setLoading(false)
      })
   }

   const handleChange = ({ target }) => {
      let { name, value } = target;

      if (name === 'mobileNumber') {
         value = value.replace(/\D/g, "").replace(/(\d{1,2})(\d{1,5})(\d{1,4})/, "($1) $2-$3")
      }

      setUser({ ...user, [name]: value, });
   };

   const handleAfterSave = () => {
      history.push(`/users`)
   }

   const handleSave = async () => {
      // if (!cpfValidator(user?.cpf)) {
      //    alert.error('O CPF contém algum digito errado ou não está no formato correto.')
      //    return
      // }

      if (!emailValidator(user.email)) {
         alert.error('O e-mail não está no formato correto.')
         return
      }

      if (!user.mobileNumber || user.mobileNumber.replace(/\D/g, "").length !== 11) {
         alert.error('Número de celular inválido. Não esqueça de colocar o DDD.')
         return
      }
      setLoading(true)
      console.log(newUser)
      if (newUser) {

         await api.post(`/v3/admin/user`, { ...user, admin: true }).then((res) => {
            handleAfterSave()
            alert.success('Usuário cadastrado com sucesso!')
         }).catch((err) => {
            alert.error(err.response.data.message)
         }).finally(() => setLoading(false))
         return
      }

      await api.patch(`/v3/app/user/${id}`, user).then(response => {
         alert.success('Usuário atualizado com sucesso!')
         loadUserData()
      }).catch(err => {
         alert.error('Desculpe. Não foi possível atualizar o usuário.')
      }).finally(() => setLoading(false))
   }

   const handlePasswordReset = async () => {
      setLoading(true)
      await api.patch(`/v3/admin/user/${id}/password-reset`).then(response => {
         setLoading(false)
         alert.success('Usuário atualizado com sucesso!')
         loadUserData()
      }).catch(err => {
         console.log(err)
         setLoading(false)
         alert.error('Desculpe. Não foi possível atualizar o usuário.')
      })
   }

   const loadLockers = async () => {
      setLoading(true)
      await api.get(`/v3/admin/lockers?select=address,description`).then(response => {
         setLoading(false)
         const { data: { lockers = [] } } = response
         setLockers(lockers)
      }).catch(err => {
         console.log(err)
         setLoading(false)
         alert.error('Desculpe. Não foi possível carregar os lockers.')
      })
   }

   useEffect(() => {
      document.title = `TuimBox Admin - Usuário`
      !newUser && loadUserData();
      window.scroll({ top: 0, behavior: 'smooth' })
   }, [])

   useEffect(() => {
      if (user?.permission?.includes('BuildingManager') && lockers.length === 0) loadLockers();
   }, [user?.permission])

   return (
      <>
         <SectionHeader title={user.name || 'Usuário'} />
         <ContentContainer>
            <Box>
               <TextField
                  variant="standard"
                  label="Nome"
                  refs="name"
                  name="name"
                  margin="dense"
                  value={user.name || ''}
                  fullWidth
                  autoFocus
                  onChange={handleChange}
                  InputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{ style: { color: '#888' } }}
               />
               <TextField
                  type="email"
                  variant="standard"
                  label="Email"
                  refs="email"
                  name="email"
                  margin="dense"
                  value={user.email || ''}
                  fullWidth
                  onChange={handleChange}
               />
               <TextField
                  variant="standard"
                  label="Celular"
                  refs="mobileNumber"
                  name="mobileNumber"
                  margin="dense"
                  value={user.mobileNumber || ''}
                  fullWidth
                  onChange={handleChange}
               />
            </Box>
            <CheckBox
               title='Permissão'
               currentValue={user?.permission}
               unique
               options={[
                  {
                     value: 'Administrador',
                     display: `Administrador`
                  },
                  {
                     value: 'Financeiro',
                     display: `Financeiro`
                  },
                  {
                     value: 'BuildingManager',
                     display: `Gerente de Empreendimento`
                  },
               ]}
               onClick={(value) => setUser({ permission: value })}
            />

            {user?.permission?.includes('BuildingManager') &&
               <CheckBox
                  vertical
                  title='Lockers'
                  currentValue={user?.lockers}
                  options={lockers?.map(locker => { return { value: locker?._id, display: locker?.description } })}
                  onClick={(value) => setUser({ lockers: value })}
               />
            }

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <SecondaryButton
                     width='large'
                     text='Reiniciar Senha'
                     onClick={() => handlePasswordReset()}
                  />
                  <PrimaryButton
                     width='large'
                     text='Salvar'
                     onClick={handleSave}
                  />
               </Box>
            </Box>
         </ContentContainer>
      </>
   );
};
