import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../../services/api';
import { Colors } from '../../organisms/layout/Colors';
import { SmallSectionTitle } from '../../organisms/layout/SmallSectionTitle';
import { Voucher } from '../../organisms/voucher/voucher';
import { Box, ContentContainer, Pagination, RadioButtonGroup, SearchBar, SectionHeader, Text, TextField } from '../../W3libComponents';
import { uid } from '../../../helpers/uid_generator';
import { formatTimestamp } from '../../../helpers/user/formatTimestamp';

export const EditVoucher = ({ context }) => {

   const history = useHistory()
   const location = useLocation()
   const pathname = location.pathname.split("/")
   const voucherId = pathname[pathname.length - 1]
   const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
   const [pagination, setPagination] = useState(10)
   const [searchFilter, setSearchFilter] = useState('')
   const { alert } = context
   const [voucher, setVoucher] = useReducer((prev, next) => { return { ...prev, ...next } }, { active: true, type: 'addTuimPoints' })

   const filter = (item) => {
      let data = item.user;
      const normalizeString = (str) => {
         return str?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };

      const normalizedFilterData = normalizeString(searchFilter);
      return normalizeString(data?.name)?.toLowerCase().includes(normalizedFilterData?.toLowerCase()) ||
         normalizeString(data?.email)?.toLowerCase().includes(normalizedFilterData?.toLowerCase());
   };

   const getVoucher = async () => {
      const voucher = await api.get(`/v3/admin/voucher/${voucherId}`)
      if (voucher?.status === 200) {
         setVoucher(voucher.data.voucher)
         return
      }
      alert.error(`Não foi possível carregar o voucher.`)
   }

   const updateVoucher = async (voucherId, updatedFields) => {
      const updatedVoucher = await api.patch(`/v3/admin/voucher/${voucherId}`, updatedFields)
      if (updatedVoucher?.status === 200) {
         alert.success(`Voucher atualizado com sucesso!`)
         await getVoucher()
         return
      }
      alert.error(`Não conseguimos atualizar o voucher.`)
   }

   const createVoucher = async () => {
      try {
         const newVoucher = await api.post(`/v3/admin/voucher/new`, voucher)
         if (newVoucher?.status === 201) {
            alert.success('Voucher criado com sucesso!')
            history.push(`/vouchers/${newVoucher?.data?.voucher?._id}`)
         }
      } catch (error) {
         alert.error(error?.response?.data?.message)
      }
   }

   const deleteVoucher = async () => {
      try {
         const deletedVoucher = await api.delete(`/v3/admin/voucher/${voucherId}`)
         if (deletedVoucher?.status === 200) {
            alert.success('Voucher excluído com sucesso!')
            history.push('/vouchers')
         }
      } catch (error) {
         console.log(error?.response?.data)
         alert.error('Não foi possível excluir o voucher.')
      }
   }

   useEffect(() => {
      document.title = 'TuimBox Admin - Vouchers'
      if (voucherId === 'new') return;
      getVoucher()
   }, [])

   return (
      <>
         <SectionHeader
            title={`Voucher ${voucher?.code || ''}`}
            subtitle={voucher.active ? `Ativo` : `Inativo`}
            saveButton
            saveButtonAction={() => {
               if (voucherId === 'new') {
                  createVoucher()
                  return
               }
               updateVoucher(voucherId, voucher)
            }}
            deleteButton={!(voucherId === 'new')}
            deleteButtonAction={() => deleteVoucher()}
         />
         <Box sx={{ display: 'flex', gap: 3 }}>
            <ContentContainer fullWidth>
               <Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                     <TextField
                        variant="standard"
                        label="Voucher"
                        refs="code"
                        name="code"
                        margin="dense"
                        value={voucher.code || ''}
                        fullWidth
                        autoFocus
                        onChange={(e) => setVoucher({ [e.target.name]: e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '') })}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: '#888' } }}
                     />
                     <TextField
                        variant="standard"
                        label="Valor"
                        refs="amount"
                        name="amount"
                        margin="dense"
                        value={voucher.amount || ''}
                        onChange={(e) => setVoucher({ [e.target.name]: e.target.value * 1 })}
                        style={{ width: 100 }}
                        InputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { color: '#888' } }}
                     />
                  </Box>
                  <TextField
                     variant="standard"
                     label="Descrição"
                     refs="description"
                     name="description"
                     margin="dense"
                     value={voucher.description || ''}
                     fullWidth
                     onChange={(e) => setVoucher({ [e.target.name]: e.target.value })}
                     InputProps={{ style: { fontSize: 14 } }}
                     InputLabelProps={{ style: { color: '#888' } }}
                  />

                  <TextField
                     variant="standard"
                     label="Expiração"
                     refs="expiresAt"
                     name="expiresAt"
                     margin="dense"
                     type="date"
                     value={(voucher?.expiresAt)?.split('T')[0]}
                     fullWidth
                     onChange={(e) => setVoucher({ [e.target.name]: e.target.value })}
                     InputProps={{ style: { fontSize: 14 } }}
                     InputLabelProps={{ style: { color: '#888' } }}
                  />

               </Box>
               <Box sx={{ display: 'flex', gap: 4 }}>
                  <RadioButtonGroup
                     title='Tipo'
                     value={voucher.type}
                     options={[
                        {
                           value: 'addTuimPoints',
                           display: 'Tuimpoints'
                        },
                        {
                           value: 'discount',
                           display: 'Desconto'
                        },
                     ]}
                     onClick={(selectedType) => setVoucher({ type: selectedType })}
                  />
                  <Box sx={styles.activeContainer} onClick={() => setVoucher({ active: !voucher.active })}>
                     <Box sx={{ ...styles.iconContainer, ...(!voucher.active && { backgroundImage: `url('/icons/gray_check_icon.png')` }) }} />
                     <Text style={styles.regularText}>
                        {voucher.active ? `Voucher Ativo` : `Voucher inativo`}
                     </Text>
                  </Box>
               </Box>
            </ContentContainer>

            <ContentContainer>
               <SmallSectionTitle title='Preview' />
               <Voucher preview voucher={voucher} />
            </ContentContainer>
         </Box>

         <ContentContainer>
            <SmallSectionTitle title="Detalhes de ultilização" />
            <Box sx={{ display: 'flex', gap: 3 }}>
               <SearchBar
                  placeholder='Forno Elétrico, Juliana...'
                  onChange={({ target }) => setSearchFilter(target.value)}
               />
               <Pagination
                  localStorageName='tuimbox-list-users-vouchers-pagination'
                  dataLength={voucher?.applied_by?.length}
                  onChange={setDataFilter}
                  initialItemsPerPage={pagination}
               />
            </Box>
            <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5 }}>
               <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                  <Box style={{ ...styles.tableHeadRow, width: '30%' }}>
                     <Text style={{ ...styles.tableHeadText, textDecorationLine: 'none' }}>Nome</Text>
                  </Box>
                  <Box style={{ ...styles.tableHeadRow, width: '30%', }}>
                     <Text style={{ ...styles.tableHeadText, textDecorationLine: 'none', }}>E-mail</Text>
                  </Box>
                  <Box style={{ ...styles.tableHeadRow, width: '30%' }}>
                     <Text style={{ ...styles.tableHeadText, textDecorationLine: 'none' }}>Ultilizado</Text>
                  </Box>
               </Box>

               <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  {voucher?.applied_by?.filter(filter).map((item, index) => {
                     const appliedByDate = item.date;
                     const appliedByUserData = item.user

                     return (

                        <Box
                           key={`${item}-${index}-${uid()}`}
                           style={{ textDecoration: 'none', }}
                        >
                           <Box sx={{ ...styles.tableRow, justifyContent: 'space-around', padding: '10px 0px' }}>
                              <Text style={{ ...styles.cellText, width: '30%' }}>
                                 {appliedByUserData?.name}
                              </Text>
                              <Text style={{ ...styles.cellText, width: '30%' }}>
                                 {appliedByUserData?.email}
                              </Text>
                              <Text style={{ ...styles.cellText, width: '30%' }}>
                                 {formatTimestamp({ timestamp: appliedByDate, showFullYear: false })}
                              </Text>
                           </Box>
                        </Box>
                     )
                  })}
               </Box>
            </Box>
         </ContentContainer>
      </>
   )
}

const styles = {
   smallText: {
      fontSize: 11,
      textTransform: 'uppercase'
   },
   regularText: {
      fontSize: 14,
   },
   largerText: {
      fontSize: 20,
   },
   boldText: {
      fontSize: 20,
      fontFamily: 'Ubuntu-Bold',
   },
   iconContainer: {
      backgroundImage: `url('/icons/green_check_icon.png')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 20,
      height: 20,
      "&:hover": {
         cursor: 'pointer'
      }
   },
   activeContainer: {
      // width: 200,
      gap: 1,
      backgroundColor: Colors.background,
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 40px',
      "&:hover": {
         backgroundColor: Colors.darkerBackground,
         cursor: 'pointer'
      }
   },
   tableHeadRow: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
   },
   tableHeadText: {
      fontSize: '14px',
      color: '#333',
      textAlign: 'center',
      fontWeight: 600,
      marginLeft: 7
   },
   tableRow: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      borderTopWidth: '1px',
      borderTopStyle: 'solid',
      borderTopColor: '#e4e4e4',
      "&:hover": {
         backgroundColor: Colors.background,
         cursor: 'pointer'
      }
   },
   row: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 8,
      paddingBottom: 8
   },
   cellText: {
      fontSize: 14,
      textAlign: 'center',
   },
   cellTextBold: {
      fontSize: 14,
      fontWeight: 600,
   },
}