import { Box } from "@mui/material";
import { ContentContainer, Text, TextField } from "../../W3libComponents";
import { PrimaryButton, TertiaryButton } from "../../organisms/button/Buttons";
import { useRentalPeriods } from "./useRentalPeriods";

const newPeriodFields = [
  {
    label: "Nome do período",
    fieldName: "name",
    helperText: "Este será o nome que o usuário verá no momento da locação.",
  },
  {
    label: "Duração",
    fieldName: "duration",
    helperText:
      "Calculada em minutos. Ex.: para 1h30min, o período deve ter duração de 90 minutos.",
    type: "number",
  },
  {
    label: "Tolerância",
    fieldName: "tolerance",
    helperText:
      "Minutos de tolerância para cobrança de novo ciclo. Ex.: uma locação com períodos de 1 hora e tolerância de 2 minutos terá a cobrança automática de um novo ciclo após 62 minutos.",
    type: "number",
  },
  {
    label: "Descrição",
    fieldName: "description",
    multiline: true,
  },
];

export const NewPeriod = ({ periodData, handleClose, refreshData }) => {
  const initialData = { name: "", duration: 0, tolerance: 0, description: "" };

  const { data, handleChange, reset, save } = useRentalPeriods(
    periodData || initialData
  );

  const isDisabled = Object.keys(initialData).some((key) => {
    let value = data[key];

    if (key === "duration") value *= 1;
    if (key === "tolerance") return false;

    return !Boolean(value);
  });

  const handleSavePeriod = async () => {
    const result = await save();
    if (result.ok) {
      handleClose();
      refreshData();
    }
  };

  const handleDismiss = () => {
    reset();
    handleClose();
  };

  return (
    <Box style={styles.root}>
      <ContentContainer style={{ maxWidth: 480, maxHeight: "90%" }}>
        <Box style={styles.wrapper}>
          <Text style={{ fontWeight: 600, fontSize: 18 }}>
            Novo período de locação
          </Text>
          {newPeriodFields.map((item) => (
            <Box
              key={item.fieldName}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <TextField
                label={item.label}
                type={item.type || "text"}
                multiline={item.multiline || false}
                rows={item.multiline ? 4 : 1}
                variant="outlined"
                value={data[item.fieldName]}
                name={item.fieldName}
                onChange={handleChange}
                InputProps={{ style: { fontSize: 14 }, inputProps: { min: 0 } }}
                InputLabelProps={{ style: { color: "#888" } }}
              />
              {item.helperText && (
                <Text small style={{ color: "#999" }}>
                  {item.helperText}
                </Text>
              )}
            </Box>
          ))}

          <Box style={{ display: "flex", gap: 12 }}>
            <PrimaryButton
              width="small"
              text="Salvar"
              disabled={isDisabled}
              onClick={handleSavePeriod}
            />
            <TertiaryButton
              width="small"
              text="Cancelar"
              onClick={handleDismiss}
            />
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
};

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000044",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    overflowY: "auto",
    paddingRight: 12,
  },
};
