import { animated, useSpring } from '@react-spring/web'
import { Text } from '../atoms';
import { numberToBRL } from '../../helpers/Number_to_BRL';

export function AnimatedNumber({ target = 0, textStyle = {}, currency = false }) {
   const { number } = useSpring({
      from: { number: 0 },
      number: target,
      delay: 200,
      config: { mass: 1, tension: 20, friction: 10 }
   });

   return (
      <Text style={{ ...textStyle }}>
         <animated.div>
            {number.to(number => currency ? numberToBRL(number.toFixed(0)) : number.toFixed(0))}
         </animated.div>

      </Text>
   )
}