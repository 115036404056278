import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { uid } from "../../../helpers/uid_generator";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import {
  Box,
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { StatusLabel } from "../../organisms/layout/StatusLabel";

export const ContractList = ({ context }) => {
  const { setLoading } = context;
  const [contracts, setContracts] = useState([]);
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item);
  const [pagination, setPagination] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [filters, setFilters] = useState({
    filterName: "createdAt",
    filterOrder: "asc",
  });

  const { filterName, filterOrder } = filters;

  const statusOptions = [
    {
      value: "active",
      display: "Ativo",
      color: Colors.green,
    },
    {
      value: "error",
      display: "Erro",
      color: Colors.red,
    },
    {
      value: "abandon-error",
      display: "Erro",
      color: Colors.red,
    },
    {
      value: "finished",
      display: "Finalizado",
      color: Colors.background,
    },
  ];
  const defaulterOptions = [
    {
      value: true,
      display: "Inadimplente",
      color: Colors.red,
    },
    {
      value: false,
      display: "Regular",
      color: Colors.green,
    },
  ];

  const getContractStatus = (status) => {
    if (status === "active") return 1;
    return 0;
  };

  const getContractRentalInterval = (contract) => {
    if (contract?.home_key) return "B2C";
    if (contract?.subscription) return "Assinatura";
    if (contract?.period?.name) return contract?.period?.name;

    if (contract?.interval === "hourly") return "Por Hora";
    if (contract?.interval === "daily") return "Diário";
    if (contract?.interval === "weekly") return "Semanal";
    if (contract?.interval === "monthly") return "Mensal";

    return "Avulso";
  };

  useEffect(() => {
    document.title = `TuimBox Admin - Contratos`;
    let limit = pagination;

    if (window.localStorage.getItem("tuimbox-list-contracts-filters")) {
      const tuimBoxLocalStorage = JSON.parse(
        window.localStorage.getItem("tuimbox-list-contracts-filters")
      );
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder,
      });
    }
    if (window.localStorage.getItem("tuimbox-list-contracts-pagination")) {
      const tuimboxListContractsPagination = JSON.parse(
        window.localStorage.getItem("tuimbox-list-contracts-pagination")
      );
      limit = tuimboxListContractsPagination;
      setPagination(tuimboxListContractsPagination);
    }

    setLoading(true);
    api
      .get(`/v3/admin/contracts?limit=0&skip=0`)
      .then((response) => {
        setLoading(false);
        setContracts(response.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "tuimbox-list-contracts-filters",
      JSON.stringify({ filterName, filterOrder })
    );
  }, [filters]);

  const sortContracts = () => {
    const { filterName, filterOrder } = filters;

    const sortedContracts = contracts
      .filter(
        (contract) =>
          contract?.products?.name
            .toLowerCase()
            .includes(searchFilter.toLowerCase()) ||
          contract?.id_user?.name
            .toLowerCase()
            .includes(searchFilter.toLowerCase())
      )
      .sort((a, b) => {
        if (filterName === "codLoopBox") {
          return filterOrder === "desc"
            ? a.id_door[filterName] - b.id_door[filterName]
            : b.id_door[filterName] - a.id_door[filterName];
        }
        if (filterName === "products" || filterName === "id_user") {
          if (
            (String(a[filterName]?.name).toLowerCase() || "") >
            (String(b[filterName]?.name).toLowerCase() || "")
          )
            return filterOrder === "asc" ? 1 : -1;
          if (
            (String(a[filterName]?.name).toLowerCase() || "") <
            (String(b[filterName]?.name).toLowerCase() || "")
          )
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        }
        if (filterName === "interval") {
          if (getContractRentalInterval(a) > getContractRentalInterval(b))
            return filterOrder === "asc" ? 1 : -1;
          if (getContractRentalInterval(a) < getContractRentalInterval(b))
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        }
        if (filterName === "status") {
          if (getContractStatus(a.status) > getContractStatus(b.status))
            return filterOrder === "asc" ? 1 : -1;
          if (getContractStatus(a.status) < getContractStatus(b.status))
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        } else {
          if ((a?.[filterName] || "") > (b?.[filterName] || ""))
            return filterOrder === "asc" ? 1 : -1;
          if ((a?.[filterName] || "") < (b?.[filterName] || ""))
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        }
      });
    return sortedContracts;
  };

  const formatUserName = (name) => {
    if (!!name) {
      const nameArray = name.trim().split(" ");
      return nameArray
        .map((word, index) =>
          index !== 0 && index !== nameArray.length - 1 && word.length > 2
            ? word.slice(0, 1) + "."
            : word
        )
        .join(" ");
    }

    return "Não informado";
  };

  return (
    <>
      <SectionHeader
        title={`Contratos`}
        subtitle={`Ativos: ${
          contracts.filter((i) => i.status === "active").length
        }`}
      />
      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Forno Elétrico, Juliana..."
            onChange={({ target }) => setSearchFilter(target.value)}
          />
          <Pagination
            localStorageName="tuimbox-list-contracts-pagination"
            dataLength={contracts.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>
        <Box
          style={{
            border: "1px solid #e4e4e4",
            backgroundColor: "#fff",
            borderRadius: 5,
          }}
        >
          <Box style={{ width: "100%", display: "flex" }}>
            <Box
              style={{ ...styles.tableHeadRow, width: 150, paddingLeft: 10 }}
            >
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "status" ? "underline" : "none",
                }}
              >
                Status
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "status"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "status",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 180 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "createdAt" ? "underline" : "none",
                }}
              >
                Início
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "createdAt"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "createdAt",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "returnDate" ? "underline" : "none",
                }}
              >
                Término
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "returnDate"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "returnDate",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "products" ? "underline" : "none",
                }}
              >
                Produto
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "products"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "products",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 65 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "codLoopBox" ? "underline" : "none",
                }}
              >
                Porta
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "codLoopBox"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "codLoopBox",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "id_user" ? "underline" : "none",
                }}
              >
                Cliente
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "id_user"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "id_user",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 100 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "defaulter" ? "underline" : "none",
                }}
              >
                Situação
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "defaulter"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "defaulter",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 130 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "interval" ? "underline" : "none",
                }}
              >
                Aluguel
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "interval"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "interval",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            {sortContracts()
              ?.filter(
                searchFilter === "" ? dataFilter : (contract, index) => contract
              )
              ?.map((contract, index) => (
                <Link
                  key={`contract_link_${uid()}`}
                  to={`/contracts/${contract?._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 150, paddingLeft: 10 }}>
                      <StatusLabel
                        background="light"
                        currentStatus={contract?.status}
                        dropDownOptions={statusOptions}
                      />
                    </Box>
                    <Box
                      style={{
                        ...styles.row,
                        flexDirection: "column",
                        width: 180,
                      }}
                    >
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: contract?.createdAt,
                          showFullYear: false,
                        })}
                      </Text>
                      <Text style={styles.cellText}>
                        {formatTimestamp({
                          timestamp: contract?.returnDate,
                          showFullYear: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1, overflow: "hidden" }}>
                      <Text
                        style={{
                          ...styles.cellText,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {contract?.products?.name}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 65 }}>
                      <Text style={styles.cellText}>
                        {contract?.id_door?.codLoopBox}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1, overflow: "hidden" }}>
                      <Text
                        style={{
                          ...styles.cellText,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {formatUserName(contract?.id_user?.name)}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 100 }}>
                      <Text style={styles.cellText}>
                        <StatusLabel
                          background="light"
                          currentStatus={contract?.defaulter}
                          dropDownOptions={defaulterOptions}
                        />
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 130 }}>
                      <Text style={styles.cellText}>
                        {getContractRentalInterval(contract)}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
};

const styles = {
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontWeight: 600,
    marginLeft: 7,
  },
  tableRow: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellText: {
    fontSize: 14,
    textAlign: "center",
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  paginationDropDownContainer: {
    position: "relative",
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#ddd",
    display: "flex",
    alignItems: "center",
    paddingLeft: 2,
    paddingRight: 2,
    // backgroundColor: 'coral',
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  dropDownIcon: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 12,
    height: 12,
    marginLeft: 2,
  },
  paginationDropDownOptionsContainer: {
    position: "absolute",
    width: 94,
    left: 0,
    top: 32,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    zIndex: 9999999,
  },
  paginationDropDownOptions: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    paddingTop: 1,
    paddingBottom: 1,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButtonContainer: {
    position: "relative",
    height: 25,
    width: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    transform: "rotate(-90deg)",
  },
  contractStatus: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "red",
  },
};
