import { TextField as MUITextField } from "@mui/material";

export const TextField = (props) => {
  const { name, InputProps } = props;

  const defaultProps = {
    variant: "outlined",
    refs: name,
    name: name,
    fullWidth: true,
    margin: "dense",
  };

  return (
    <MUITextField
      {...defaultProps}
      {...props}
      InputProps={{
        style: {
          borderRadius: 8,
          fontSize: 14,
          fontFamily: "Ubuntu",
          color: "#444",
          ...InputProps?.style,
        },
        inputProps: { ...InputProps?.inputProps },
      }}
      InputLabelProps={
        props.type === "date"
          ? {
              shrink: true,
              style: { color: "#888" },
            }
          : { style: { color: "#888" } }
      }
    />
  );
};
