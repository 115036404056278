export const updatedFieldsConstructor = (updatedFields, originalData, modifiedData) => {
   if (updatedFields && originalData && modifiedData) {

      const updatedData = []

      updatedFields.forEach(field => {
         updatedData.push({ name: field, previousValue: originalData?.[field], nextValue: modifiedData?.[field] })
      })
      return updatedData
   }
   return []
}