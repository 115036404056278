import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ContentContainer, SearchBar, SectionHeader, Text } from "../../W3libComponents";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import { Table } from "../../organisms/table/Table";

const listHomeKeysTableHeader = [
  { text: 'Locker' },
  { text: 'Unidade', width: 100 },
  { text: 'Contratos', width: 100 },
  { text: 'E-mail' },
  { text: 'Chave', width: 150 },
  { text: 'Usuários', width: 100 }
];

const lockerDetailsTableHeader = [
  { text: 'Unidade', },
  { text: 'Contratos', },
  { text: 'E-mail' },
  { text: 'Chave' },
  { text: 'Usuários', }
];

export const ListHomeKey = (props) => {

  const { context, lockerId = null, setB2CLocker = null } = props;
  const { alert, setLoading } = context;
  const history = useHistory()

  const [homeKeys, setHomeKeys] = useState([])
  const [homeKeyFilter, setHomeKeyFilter] = useState('')

  const filter = (item, index) =>
    item.home_key.toLowerCase().includes(homeKeyFilter.toLowerCase()) ||
    item.email.toLowerCase().includes(homeKeyFilter.toLowerCase()) ||
    item.buildingUnit.toLowerCase().includes(homeKeyFilter.toLowerCase());

  const loadHomeKeys = async () => {
    try {
      setLoading(true)
      const response = await api.get(`/v3/admin/homekeys${lockerId ? `?lockerId=${lockerId}` : ''}`)
      setHomeKeys(response.data)
      console.log(response.data)
      if (setB2CLocker) setB2CLocker(response.data.length > 0)
    } catch (error) {
      alert.error('Não foi possível carregar as Chaves TuimBox')
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadHomeKeys();
  }, [])

  return (
    <>
      {lockerId ?
        <>
          <SmallSectionTitle title={`Chaves`} />
          <Table
            size='small'
            header={lockerDetailsTableHeader}
            rows={homeKeys.filter(filter).map((homeKey, index) => {
              return {
                to: `/homekeys/${homeKey._id}`,
                data: [
                  <Text>{homeKey.buildingUnit || '-'}</Text>,
                  <Text>{homeKey?.rents?.length}</Text>,
                  <Text>{homeKey.email}</Text>,
                  <Text>{homeKey.home_key}</Text>,
                  <Text>{homeKey?.users?.length ?? 0}</Text>
                ]
              }
            }
            )}
          />
        </>
        :
        <>
          <SectionHeader
            title={`Chaves`}
            newButton
            newButtonAction={() => history.push('/homekeys/new')}
          />
          <ContentContainer>
            <SearchBar placeholder='2SF70NWC3O, pedro@gmail.com, 801...' onChange={({ target }) => setHomeKeyFilter(target.value)} />
            <Table
              size='small'
              header={listHomeKeysTableHeader}
              rows={homeKeys.filter(filter).map((homeKey, index) => {
                return {
                  to: `/homekeys/${homeKey._id}`,
                  data: [
                    <Text>{homeKey?.locker_id?.description}</Text>,
                    <Text>{homeKey.buildingUnit || '-'}</Text>,
                    <Text>{homeKey?.rents?.length}</Text>,
                    <Text>{homeKey.email}</Text>,
                    <Text>{homeKey.home_key}</Text>,
                    <Text>{homeKey?.users?.length}</Text>
                  ]
                }
              }
              )}
            />
          </ContentContainer>
        </>
      }
    </>
  )
};