import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../contexts";
import { formatTimestamp } from "../../helpers/user/formatTimestamp";
import api from "../../services/api";
import { ContentContainer } from "../W3libComponents";
import { Box, Text } from "../atoms";
import { SecondaryButton } from "./button/Buttons";
import { SmallSectionTitle } from "./layout/SmallSectionTitle";
import { Table } from "./table/Table";

const tableHeader = [
   { text: 'Data e Hora' },
   { text: 'Descrição' },
   { text: 'Usuário' },
   { text: '', width: 18 },
];

export const LogList = (props) => {

   const { id = null, handleClose = () => { } } = props;
   const { setLoading, loading } = useContext(AppContext)

   const [logs, setLogs] = useState([])

   const handleFetchLogs = async () => {
      setLoading(true)
      const response = await api.get(`/v3/admin/logs/${id}`)
      setLogs(response?.data)
      setLoading(false)
   }

   useEffect(() => {
      handleFetchLogs()
   }, [id])

   const noLogs = logs?.length === 0;

   return (
      <Box sx={styles.mainContainer}>
         <ContentContainer style={{ width: '60%' }}>
            {loading || noLogs ?
               <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 4
               }}>
                  <Text title='true'>{loading ? `Carregando...` : `Nenhum histórico encontrado`}</Text>
               </Box>
               :
               <>
                  <SmallSectionTitle size='medium' title={`Logs (${logs?.length})`} />
                  <Box sx={{ maxHeight: 315, overflowY: 'auto' }}>
                     <Table
                        size='small'
                        header={tableHeader}
                        rows={logs?.map(log => {
                           return {
                              data: [
                                 <Text>{formatTimestamp({ timestamp: log.createdAt, showFullYear: false })}</Text>,
                                 <Text>{log.description}</Text>,
                                 <Text>{log.email}</Text>,
                                 { expandButton: true },
                                 {
                                    expandable: true,
                                    data: (
                                       <Box sx={{
                                          backgroundColor: '#00000010',
                                          padding: `32px 0px`
                                       }}>
                                          <Table size='small'
                                             header={[
                                                { text: 'Campo' },
                                                { text: 'Valor original' },
                                                { text: 'Valor atualizado' },
                                             ]}
                                             rows={log?.updatedFields?.map(field => {
                                                return {
                                                   data: [
                                                      <Text>{field?.name?.toUpperCase()}</Text>,
                                                      <Text>{field?.previousValue?.toString()?.toUpperCase()}</Text>,
                                                      <Text>{field?.nextValue?.toString()?.toUpperCase()}</Text>,
                                                   ]
                                                }
                                             }
                                             )} />
                                       </Box>
                                    )
                                 }]
                           }
                        })}
                     />
                  </Box>
               </>
            }
            <SecondaryButton
               width='small'
               text='Fechar'
               onClick={handleClose}
            />
         </ContentContainer >
      </Box >
   )
}

const styles = {
   mainContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#00000088',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 2
   }
}