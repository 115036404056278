/* 
PrimaryButton props:

# text (any string) = Text inside the button;
# width (small, large) = Defines the width of the button.
If you want a fullwidth button, just don't pass 'width'
*/

import { Box } from "@mui/material";
import { Text } from "../../atoms";
import { Colors } from "../layout/Colors";

export const PrimaryButton = (props) => {

   const {
      text,
      width = 'fullWidth', // [small, large, full] (default 'flex: 1')
      onClick,
      style,
      disabled = false,
      textColor = null,
   } = props;

   return (
      <Box sx={styles.buttonContainer}>
         <Box
            onClick={disabled ? () => { } : onClick}
            sx={{
               ...styles.primaryButton,
               ...(width === 'small' ? { maxWidth: 150 } : width === 'large' ? { minWidth: 150, maxWidth: 250 } : { width: '100%' }),
               backgroundColor: disabled ? '#ccc' : Colors.darkGreen,
               ...(disabled && {
                  "&:hover": {
                     cursor: 'not-allowed'
                  }
               }),
               ...style,
            }}>
            <Text style={{
               ...styles.regularText,
               color: disabled ? '#777' : '#ffffff'
            }}>{text}</Text>
         </Box>
      </Box>
   )
}

export const SecondaryButton = (props) => {

   const {
      text,
      width, // [small, large] (default 'flex: 1')
      onClick,
      style,
      textColor = null,
   } = props;

   return (
      <Box sx={styles.buttonContainer}>
         <Box
            onClick={onClick}
            sx={{
               ...styles.secondaryButton,
               ...(width === 'small' ? { maxWidth: 150 } : width === 'large' ? { minWidth: 150, maxWidth: 250 } : { flex: 1 }),
               backgroundColor: '#d9d9d9',
               ...style,
            }}>
            <Text style={{
               ...styles.regularText,
               color: '#444'
            }}>{text}</Text>
         </Box>
      </Box>
   )
}

export const TertiaryButton = (props) => {

   const {
      text,
      width, // [small, large] (default 'flex: 1')
      onClick,
      style,
      textColor = null,
   } = props;

   return (
      <Box sx={styles.buttonContainer}>
         <Box
            onClick={onClick}
            sx={{
               ...styles.tertiaryButton,
               ...(width === 'small' ? { maxWidth: 150 } : width === 'large' ? { minWidth: 150, maxWidth: 250 } : { flex: 1 }),
               backgroundColor: 'transparent',
               ...style,
            }}>
            <Text style={{
               ...styles.regularText,
               color: '#666',
               ...(textColor && { color: textColor })
            }}>
               {text}
            </Text>
         </Box>
      </Box>
   )
}

const styles = {
   buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
   },
   primaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `8px 16px 8px 16px`,
      borderRadius: 2,
      width: '100%',
      "&:hover": {
         backgroundColor: Colors.darkGreen + 'e8',
         cursor: 'pointer'
      }
   },
   secondaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `8px 16px 8px 16px`,
      borderRadius: 2,
      width: '100%',
      "&:hover": {
         backgroundColor: '#d9d9d9da',
         cursor: 'pointer'
      }
   },
   tertiaryButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `8px 16px 8px 16px`,
      borderRadius: 2,
      width: '100%',
      border: `1px solid #ddd`,
      paddingRight: `13px`,
      "&:hover": {
         border: `1px solid #bbb`,
         cursor: 'pointer'
      }
   },
   regularText: {
      fontSize: 14
   },
   boldText: {
      fontSize: 14,
      fontWeight: 600
   },
}