import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { Box, Spacer, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { ContentContainer, Pagination, SearchBar, SectionHeader } from "../../W3libComponents";
import { uid } from '../../../helpers/uid_generator';
import { updatedFieldsConstructor } from "../../../helpers/updatedFieldsConstructor";

export const ListDoors = ({ context }) => {

  const history = useHistory()
  const { setLoading, alert, user } = context
  const { email: adminUserEmail } = user

  const [doors, setDoors] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [updatedFields, setUpdatedFields] = useState([])
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState({ active: false, id_loopbox_porta: 0 })
  const [pagination, setPagination] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')

  const [filters, setFilters] = useState({
    filterName: 'codLoopBox',
    filterOrder: 'asc'
  })

  const loadDoors = async () => {
    setLoading(true)
    api.get(`/v3/admin/listdoors`).then((response) => {
      setLoading(false)
      setDoors(response.data);
      setOriginalData(response.data);
    }).catch((err) => {
      setLoading(false)
      console.log(err);
    });
  }
  // useEffect(() => console.log(updatedFields), [updatedFields])
  const updateDoor = async ({ doorId, data }) => {
    setLoading(true)

    const originalDoorData = originalData.find(door => door._id === doorId)
    const updatedDoorFields = updatedFieldsConstructor(['active'], originalDoorData, data)


    const body = { data, updatedFields: updatedDoorFields, adminUserEmail }

    api.patch(`/v3/admin/door/${doorId}`, body).then(response => {
      setLoading(false)
      loadDoors()
      alert.success('Porta atualizada com sucesso!')
    }).catch((err) => {
      setLoading(false)
      alert.error('Desculpe. Não foi possível atualizar a porta.')
    });
  }

  useEffect(() => {
    document.title = `TuimBox Admin - Portas`
    loadDoors()
    if (window.localStorage.getItem('tuimbox-list-doors-filters')) {
      const tuimBoxLocalStorage = JSON.parse(window.localStorage.getItem('tuimbox-list-doors-filters'));
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder
      })
    }
    if (window.localStorage.getItem('tuimbox-list-doors-pagination')) {
      const tuimboxListDoorsPagination = JSON.parse(window.localStorage.getItem('tuimbox-list-doors-pagination'));
      setPagination(tuimboxListDoorsPagination)
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('tuimbox-list-doors-filters', JSON.stringify({ filterName, filterOrder }));
  }, [filters])

  const sortDoors = () => {

    const { filterName, filterOrder } = filters;

    const sortedDoors = doors.filter(door =>
      door?.codLoopBox.includes(searchFilter) ||
      door?.id_locker?.name?.toLowerCase().includes(searchFilter.toLowerCase()) ||
      door?.id_product?.name?.toLowerCase().includes(searchFilter.toLowerCase())
    ).sort((a, b) => {
      if (filterName === 'codLoopBox' || filterName === 'id_loopbox_porta') {
        return (filterOrder === 'desc') ? a[filterName] - b[filterName] : b[filterName] - a[filterName]
      }
      if (filterName === 'id_locker' || filterName === 'id_product') {
        if ((a[filterName]?.name || '') > (b[filterName]?.name || '')) return (filterOrder === 'asc') ? 1 : -1
        if ((a[filterName]?.name || '') < (b[filterName]?.name || '')) return (filterOrder === 'asc') ? -1 : 1
        return 0
      } else {
        if (a[filterName] > b[filterName]) return (filterOrder === 'asc') ? 1 : -1
        if (a[filterName] < b[filterName]) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
    })
    return sortedDoors
  }

  const getDoorStatus = (status) => {
    if (status === 'available') return 'Disponível'
    if (status === 'delivered') return 'Alugado'
    if (status === 'leased') return 'Não retirado'
    if (status === 'access_code_expired') return 'Código expirado'
  }

  const handleDoorActivation = (event, doorId, active) => {
    event.preventDefault();
    // setUpdatedFields([...updatedFields.filter(item => item !== 'active'), 'active'])

    updateDoor({
      doorId,
      data: {
        active: !active
      }
    })
  }

  const handleOpenDoor = async (doorId) => {
    setLoading(true)
    setShowConfirmationDialog({ ...showConfirmationDialog, active: false })

    await api
      .post(`/v3/admin/door/${doorId}/open`)
      .then(res => {
        if (!res?.data?.success) throw res.data.error;
        alert.success('Abertura relizada com sucesso!')
      })
      .catch(() => alert.error('Não foi possível fazer abertura desta porta.'))
      .finally(() => setLoading(false));
  }

  const handleNewDoor = () => history.push('/doors/new')

  const { filterName, filterOrder } = filters;

  return (
    <>
      <SectionHeader
        title={`Portas`}
        subtitle={`Ativas: ${doors.filter(i => i.active).length}`}
        newButtonAction={() => handleNewDoor()}
        newButton={true}
      />
      <ContentContainer>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar
            placeholder='0512, Batedeira, Mini029...'
            onChange={({ target }) => setSearchFilter(target.value)}
          />
          <Pagination
            localStorageName='tuimbox-list-doors-pagination'
            dataLength={doors.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>
        <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5 }}>
          <Box style={{ width: '100%', display: 'flex', }}>
            <Box style={{ ...styles.tableHeadRow, width: 90 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'id_loopbox_porta' ? 'underline' : 'none' }}>ID</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'id_loopbox_porta' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'id_loopbox_porta', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 160 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'id_locker' ? 'underline' : 'none' }}>Locker</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'id_locker' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'id_locker', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'id_product' ? 'underline' : 'none' }}>Produto</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'id_product' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'id_product', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 80 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'codLoopBox' ? 'underline' : 'none' }}>Código</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'codLoopBox' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'codLoopBox', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 125 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'productStatus' ? 'underline' : 'none' }}>Status</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'productStatus' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'productStatus', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 170 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'createdAt' ? 'underline' : 'none' }}>Data de Cadastro</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'createdAt' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'createdAt', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 60 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'active' ? 'underline' : 'none' }}>Ativo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'active' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'active', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 100 }}><Text style={styles.tableHeadText}>Abrir Porta</Text></Box>
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column', }}>
            {sortDoors().filter(dataFilter).map((door, index) =>
              <Link
                key={`doors_list_${uid()}`}
                to={`/doors/${door._id}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={styles.tableRow}>
                  <Box style={{ ...styles.row, width: 90 }}><Text style={styles.cellText}>{door?.id_loopbox_porta}</Text></Box>
                  <Box style={{ ...styles.row, width: 160 }}><Text style={styles.cellText}>{door?.id_locker?.name || 'Não informado'}</Text></Box>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={styles.cellText}>{door?.id_product?.name || 'Não informado'}</Text></Box>
                  <Box style={{ ...styles.row, width: 80 }}><Text style={styles.cellText}>{door?.codLoopBox}</Text></Box>
                  <Box style={{ ...styles.row, width: 125 }}><Text style={styles.cellText}>{getDoorStatus(door?.productStatus)}</Text></Box>
                  <Box style={{ ...styles.row, width: 170 }}><Text style={styles.cellText}>{formatTimestamp({ timestamp: door?.createdAt, showSeconds: false })}</Text></Box>
                  <Box style={{ ...styles.row, width: 60 }}>
                    <Box sx={{
                      ...styles.checkIcon,
                      zIndex: 99,
                      backgroundImage: door?.active ? `url('/icons/green_check_icon.png')` : `url('/icons/gray_check_icon.png')`,
                      "&:hover": { opacity: 0.8 }
                    }}
                      onClick={(event) => handleDoorActivation(event, door._id, door.active)}
                    />
                  </Box>
                  <Box style={{ ...styles.row, width: 100 }}>
                    {door.id_locker?.boxCode ?
                      <Text>{`-`}</Text>
                      :
                      <Box sx={{ ...styles.openDoorButton, position: 'relative' }} onClick={(event) => {
                        event.preventDefault()
                        setShowConfirmationDialog({ ...showConfirmationDialog, active: true, id_loopbox_porta: door.id_loopbox_porta })
                      }}>
                        <Text sx={styles.openDoorText}>Abrir</Text>
                        {showConfirmationDialog.active && (showConfirmationDialog.id_loopbox_porta === door.id_loopbox_porta) && <Box sx={styles.confirmationDialogContainer}
                          onClick={(e) => e.preventDefault()}>
                          <Box sx={styles.confirmationDialogArrow} />
                          <Text style={{ ...styles.cellTextBold, textAlign: 'center' }}>{`Deseja realmente abrir a porta ${door.codLoopBox}?`}</Text>
                          <Spacer size="2" />
                          <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box sx={styles.confirmationDialogButton} onClick={(event) => {
                              handleOpenDoor(door._id)
                              // handleOpenDoor(door.id_loopbox_porta)
                              event.preventDefault();
                              event.stopPropagation();

                            }}>
                              <Text style={styles.cellText}>SIM</Text>
                            </Box>
                            <Spacer horizontal />
                            <Box sx={styles.confirmationDialogButton} onClick={(event) => {
                              setShowConfirmationDialog({ ...showConfirmationDialog, active: false })
                              event.preventDefault()
                              event.stopPropagation();
                            }}>
                              <Text style={styles.cellText}>NÃO</Text>
                            </Box>
                          </Box>
                        </Box>}
                      </Box>
                    }
                  </Box>
                  {(!door?.id_product?.name || !door?.active || door?.productStatus === 'access_code_expired') &&
                    <Box style={{ width: '100%', height: '100%', backgroundColor: door?.productStatus === 'access_code_expired' ? Colors.red + '44' : '#ffffffaa', position: 'absolute', top: 0, left: 0 }} />
                  }
                </Box>
              </Link>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  )
}

const styles = {
  root: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  tableHeadRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  tableHeadText: {
    fontSize: '14px',
    color: '#333',
    textAlign: 'center',
    fontWeight: 600,
    marginLeft: 7
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#e4e4e4',
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8
  },
  cellText: {
    fontSize: 14,
    textAlign: 'center',
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  openDoorButton: {
    backgroundColor: Colors.background,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    zIndex: 998,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: 'pointer'
    }
  },
  openDoorText: {
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: 'content-box',
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: 'pointer'
    }
  },
  confirmationDialogButton: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: 'center',
    "&:hover": {
      cursor: 'pointer',
      backgroundColor: Colors.hover
    }
  },
  confirmationDialogArrow: {
    display: 'flex',
    position: 'absolute',
    right: -11,
    top: 60,
    width: 20,
    height: 20,
    backgroundColor: '#fff',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderRightColor: Colors.selectedBackground,
    transform: 'rotate(45deg)'
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: -310,
    width: 240,
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: 'center'
  }
}