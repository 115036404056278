import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Text } from '../../atoms'
import { Colors } from '../layout/Colors';
import { SecondaryButton, TertiaryButton } from '../button/Buttons';

const getFieldValue = (data, field) => {
   const fieldParts = field.split('.')
   let value = data

   for (let i = 0; i < fieldParts.length; i++) {
      const key = fieldParts[i]
      value = value[key]

      if (!value) {
         break
      }
   }

   return value || ''
}

//    // Set columns width based on content length
//    data?.forEach((dataItem) => {
//       fields?.forEach((field, index) => {
//          const contentLength = field?.field?.includes('.') ? getFieldValue(dataItem, field?.field).length : dataItem?.[field?.field]?.length;
//          if (!columnsWidth?.[index] || columnsWidth?.[index] < contentLength) {
//             setColumnsWidth((prevColumnsWidth) => ({
//                ...prevColumnsWidth,
//                [index]: header?.[index]?.text?.length < contentLength ? contentLength : header?.[index]?.text?.length,
//             }));
//          }
//       });
//    });

export const Table = (props) => {

   const {
      header,
      rows = [],
      size = 'large',
      textAlign = 'center',
   } = props;

   const [expand, setExpand] = useState({ active: false, index: null })
   const noData = rows.length === 0;

   if (noData) return <></>;

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
         <Box sx={{ ...styles.headerContainer }}>
            {header?.map((headerItem, index) => (
               <Box
                  key={`${headerItem}_header_table_${index}`}
                  sx={{
                     display: 'flex',
                     justifyContent: textAlign,
                     padding: 2,
                     ...(headerItem.width ? { width: headerItem.width } : { flex: 1 }),
                     ...(size === 'small' && { padding: `8px 16px` })
                  }}
               >
                  <Text bold='true'>{headerItem.text}</Text>
               </Box>
            ))}
         </Box>
         <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {rows?.map((dataItem, dataIndex) => {

               const rowData = (
                  <Box sx={{ ...styles.row, ...(!dataItem.to && { "&:hover": {} }) }}>
                     {dataItem.data.filter((item, index) => !item.expandable).map((item, index) =>
                        <Box sx={{
                           ...styles.rowItemContainer,
                           justifyContent: textAlign,
                           ...(size === 'small' && { padding: `8px 16px` }),
                           ...(header[index]?.width ? { width: header[index]?.width } : { flex: 1 }),
                        }}>
                           {item.expandButton ?
                              <Box sx={{
                                 backgroundSize: 'cover',
                                 backgroundRepeat: 'no-repeat',
                                 backgroundPosition: 'center',
                                 width: 13,
                                 height: 13,
                                 backgroundImage: expand.active && (expand.index === dataIndex) ? `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`,
                                 "&:hover": {
                                    cursor: 'pointer',
                                    opacity: 0.8
                                 }
                              }}
                                 onClick={() => setExpand({ active: !expand.active, index: dataIndex })}
                              />
                              :
                              item
                           }
                        </Box>
                     )}
                  </Box>
               );

               const rowExpandableData = dataItem.data.filter(item => { if (item.expandable) return item })?.[0]?.data;

               return (
                  <>
                     {dataItem?.to ?
                        <Link key={`data_content_table_${dataIndex}`} style={{ padding: 0, textDecoration: 'none', borderTop: `1px solid #e8e8e8`, }} to={dataItem?.to ? { pathname: dataItem?.to } : '#'} target='_blank'>
                           {rowData}
                           {expand.active && expand.index === dataIndex ? rowExpandableData : <></>}
                        </Link>
                        :
                        <Box
                           key={`data_content_table_${dataIndex}`}
                           sx={{
                              borderTop: `1px solid #e8e8e8`,
                              display: 'flex',
                              flexDirection: 'column',
                              // gap: 1,
                              ...(expand.active ?
                                 expand.index === dataIndex ? {
                                    // backgroundColor: Colors.background + '66',
                                    borderBottom: `1px solid #eee`,
                                    // borderRadius: 2,
                                    // padding: `8px 0px 16px 0px`
                                 }
                                    : { opacity: 0.2 }
                                 : {}
                              )
                           }}>
                           {rowData}
                           {expand.active && expand.index === dataIndex ?
                              <>
                                 {rowExpandableData}
                              </>
                              :
                              <></>
                           }
                        </Box>
                     }
                  </>
               )
            })}
         </Box>
      </Box>
   )
}

const styles = {
   headerContainer: {
      display: 'flex',
      flex: 1,
   },
   row: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      "&:hover": {
         backgroundColor: '#f4f4f4',
         cursor: 'pointer'
      }
   },
   rowItemContainer: {
      display: 'flex',
      // flex: 1,
      alignItems: 'center',
      padding: 2,
      position: 'relative',
   }
}