import { Colors } from "../layout/Colors";

export const SearchBar = (props) => {

   const { large = false, placeholder = '', style = {}, onChange = () => { } } = props;

   return (
      <>
         <input
            placeholder={placeholder}
            style={{ ...styles.searchBar, ...style }}
            onChange={(e) => setTimeout(() => onChange(e), 500)}
         />
      </>
   )
}

const styles = {
   searchBar: {
      padding: 18,
      backgroundColor: Colors.background,
      border: 'none',
      width: '100%',
      boxSizing: 'border-box',
      outline: 'none',
      borderRadius: 8,
      fontSize: 14,
      color: '#999'
   }
}