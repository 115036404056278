import { useCallback, useEffect, useReducer, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { homeKeyGenerator } from "../../../helpers/homeKeyGenerator";
import api from "../../../services/api";
import { SecondaryButton } from "../../organisms/button/Buttons";
import { Colors } from "../../organisms/layout/Colors";
import { DropDown } from "../../organisms/layout/DropDown";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import {
  Box,
  ContentContainer,
  Contracts,
  SearchBar,
  SectionHeader,
  Spacer,
  Text,
  TextField,
} from "../../W3libComponents";

export const EditHomeKey = (props) => {
  const { context } = props;
  const { alert, setLoading } = context;

  const history = useHistory();
  const location = useLocation();
  const id = location.pathname.split("/").at(-1);
  const newHomeKey = id === "new" ? true : false;

  const homeKeyDefaultData = {
    home_key: "",
    buildingUnit: "",
    email: null,
    locker_id: "",
    rents: [],
    active: true
  };

  const [homeKeyData, setHomeKeyData] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, homeKeyDefaultData);
  const [lockers, setLockers] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState({});
  // const [lockerFilter, setLockerFilter] = useState('')

  const loadLockers = async () => {
    try {
      const lockers = await api.get(`/v3/admin/lockers`);
      setLockers(lockers.data.lockers);
    } catch (error) {
      console.log(error);
    }
  };

  const saveNewHomeKey = async () => {
    try {
      setLoading(true);
      const response = await api.post(`/v3/admin/homekey`, homeKeyData);
      if (response.status === 201) {
        const newHomeKeyId = response.data.homekey._id;
        alert.success("Chave TuimBox criada com sucesso!");
        history.push(`/homekeys/${newHomeKeyId}`);
      }
    } catch (error) {
      alert.error("Não foi possível salvar esta Chave TuimBox");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateHomekey = async () => {
    try {
      setLoading(true);
      const response = await api.patch(`/v3/admin/homekey/${id}`, homeKeyData);
      alert.success("Chave TuimBox atualizada com sucesso!");
      console.log(response);
    } catch (error) {
      alert.error("Não foi possível atualizar esta Chave TuimBox");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadHomeKeyData = async () => {
    try {
      const response = await api.get(`/v3/admin/homekey/${id}`);
      setHomeKeyData(response.data);
      setSelectedLocker(response?.data?.locker_id);
    } catch (error) {
      alert.error("Não foi possível carregar os dados desta Chave TuimBox");
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      const response = await api.delete(`/v3/admin/homekey/${id}`);
      console.log(response);
      alert.success("Chave excluída com sucesso!");
      window.location.pathname = "/homekeys";
    } catch (error) {
      console.log(error);
      alert.error("Tivemos um problema do excluir a chave!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = `TuimBox Admin - Chave Tuimbox`;
    loadLockers();
    if (!newHomeKey) loadHomeKeyData();
  }, []);

  useEffect(
    () => setHomeKeyData({ locker_id: selectedLocker._id }),
    [selectedLocker]
  );

  const handleChangeKeyStatus = useCallback(
    () => {
      setHomeKeyData({ active: !homeKeyData.active })
    }, [ homeKeyData ]
    );
    console.log(homeKeyData)

  return (
    <>
      <SectionHeader
        title={homeKeyData.home_key || "Chave Tuimbox"}
        saveButton
        deleteButton
        deleteButtonAction={handleDelete}
        saveButtonAction={() =>
          newHomeKey ? saveNewHomeKey() : updateHomekey()
        }
      />
      <Box sx={{ display: "flex", width: "100%", gap: 3 }}>
        <ContentContainer style={{ width: 460 }}>
          <SmallSectionTitle title="Dados da Chave" />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box
              sx={{ display: "flex", gap: "16px", alignItems: "center" }}
              style={{ marginBottom: "8px" }}
            >
              <Text>Estatus da chave:</Text>
              <Box
                sx={styles.activeContainer}
                style={{ flex: 1 }}
                onClick={handleChangeKeyStatus}
              >
                <Box
                  sx={{
                    ...styles.checkIcon,
                    marginLeft: 2,
                    backgroundImage: homeKeyData.active
                      ? `url('/icons/green_check_icon.png')`
                      : `url('/icons/gray_check_icon.png')`,
                  }}
                />
                <Box sx={styles.activeDoorTextContainer}>
                  <Text style={styles.regularText}>
                    {homeKeyData.active ? `Chave Ativa` : `Chave Inativa`}
                  </Text>
                </Box>
              </Box>
            </Box>
            <DropDown
              title="Locker"
              options={lockers}
              currentValue={selectedLocker}
              onClick={setSelectedLocker}
            />
            <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
              <TextField
                variant="standard"
                label="Chave Tuimbox"
                name="home_key"
                margin="dense"
                value={homeKeyData.home_key || ""}
                fullWidth
                autoFocus
                onChange={(e) =>
                  setHomeKeyData({ [e.target.name]: e.target.value })
                }
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { color: "#888" } }}
              />
              {!homeKeyData.home_key && (
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  <SecondaryButton
                    width="large"
                    text="Gerar Chave"
                    onClick={() =>
                      setHomeKeyData({ home_key: homeKeyGenerator() })
                    }
                  />
                </Box>
              )}
            </Box>
            <TextField
              variant="standard"
              label="Apartamento"
              name="buildingUnit"
              margin="dense"
              value={homeKeyData.buildingUnit || ""}
              fullWidth
              onChange={(e) =>
                setHomeKeyData({ [e.target.name]: e.target.value })
              }
              InputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { color: "#888" } }}
            />
            <TextField
              variant="standard"
              label="E-mail"
              name="email"
              margin="dense"
              value={homeKeyData.email || ""}
              fullWidth
              onChange={(e) =>
                setHomeKeyData({ [e.target.name]: e.target.value })
              }
              InputProps={{ style: { fontSize: 14 } }}
              InputLabelProps={{ style: { color: "#888" } }}
            />
          </Box>
        </ContentContainer>
        <ContentContainer fullWidth>
          <SmallSectionTitle
            title={`Contratos (${homeKeyData?.rents?.length})`}
          />
          <Contracts contracts={homeKeyData?.rents} />
        </ContentContainer>
      </Box>

      {/*
            <>
            <Box sx={{ display: 'flex' }}>
            <SmallSectionTitle title={`Lockers (${lockersLength})`} />
            <Spacer horizontal size="2" />
            {!doorData?.id_locker?._id &&
               <Box sx={styles.noProduct}>
               <Box style={{ width: 10, height: 10, backgroundColor: '#d33109', borderRadius: '50%' }} />
               <Spacer horizontal />
                        <Text style={{ ...styles.regularText }}>Nenhum locker selecionado</Text>
                     </Box>
                  }
               </Box>
               <Box>
                  <SearchBar placeholder='Mini029, Residencial Jardins do Brasil...' onChange={({ target }) => handleChangeFilter(target.value)} />
                  <Spacer />
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                     {lockerData}
                  </Box>
               </Box>
            </> */}
    </>
  );
};

const styles = {
  regularText: {
    fontSize: 14,
  },
  noProduct: {
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },

  activeContainer: {
    height: 40,
    backgroundColor: Colors.background,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  activeDoorTextContainer: {
    flex: 1,
    marginRight: 1,
    display: "flex",
    justifyContent: "center",
  },
};
