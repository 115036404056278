import { useState } from "react";
import {
   Box,
   Text
} from "../W3libComponents";
import { Colors } from "../organisms/layout/Colors";

export const CheckBox = (props) => {
   const [showInfoDialog, setShowInfoDialog] = useState({ active: false })
   const {
      title = '',
      currentValue = null,
      options = [],
      onClick = () => { },
      vertical = false,
      disabled = false,
      disabledInfo = '',
      unique = false
   } = props;

   return (
      <>
         <Box sx={{
            ...styles.checkBoxGroupContainer,
            ...(vertical && {
               flexDirection: 'column',
               gap: 2,
               alignItems: 'flex-start',
            }),
         }}>
            {disabled ?
               <>
                  <Box sx={{ width: '100%', height: '100%', backgroundColor: '#ffffff88', position: 'absolute', top: 0, left: 0 }}>
                     <Box sx={{ position: 'absolute', top: 0, bottom: 0, margin: 'auto', right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                           sx={{ ...styles.iconContainer, backgroundImage: `url('/icons/info_icon.png')`, }}
                           onMouseOver={(e) => setShowInfoDialog({ active: true, left: e.clientX + (e.clientX * 0.03), top: e.clientY + (e.clientY * 0.03) })}
                           onMouseOut={() => setShowInfoDialog({ active: false })}
                        />
                     </Box>
                  </Box>
               </>
               :
               <></>
            }
            {title ? <Text bold='true'>{`${title}:`}</Text> : <></>}
            {options.map(option => {
               const checkedOption = currentValue ? (typeof currentValue === 'string' || typeof currentValue === 'boolean') ? currentValue === option.value : currentValue?.includes(option.value) : false;
               return (
                  <Box
                     key={`check_box_group_${option.value}`}
                     sx={styles.checkBoxContainer}
                     onClick={() => {
                        console.log(currentValue)
                        if (unique) return onClick(option.value);
                        if (typeof currentValue === 'boolean') return onClick(!currentValue);
                        if (typeof currentValue === 'string') return onClick(checkedOption ? currentValue?.filter(item => item !== option.value) : [...currentValue, option.value]);
                        return currentValue ? onClick(checkedOption ? currentValue?.filter(item => item !== option.value) : [...currentValue, option.value]) : onClick([option.value])
                     }}
                  >
                     <Box sx={{ ...styles.outerCheck, ...(unique && { borderRadius: '50%' }) }}>
                        {checkedOption && <Box sx={{ ...styles.innerCheck, ...(unique && { borderRadius: '50%' }) }} />}
                     </Box>
                     <Text sx={styles.regularText}>
                        {option.display}
                     </Text>
                  </Box>)
            }
            )
            }
         </Box>
         {showInfoDialog.active &&
            <Box sx={{ ...styles.infoDialogContainer, top: showInfoDialog.top, left: showInfoDialog.left }}>
               <Text>{disabledInfo}</Text>
            </Box>
         }
      </>
   )
}

const styles = {
   regularText: {
      fontSize: 14,
   },
   boldText: {
      fontSize: 14,
   },
   checkBoxGroupContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      position: 'relative'
   },
   checkBoxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      "&:hover": {
         cursor: 'pointer',
      }
   },
   outerCheck: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 14,
      height: 14,
      borderRadius: 1,
      border: `2px solid ${Colors.darkGreen}`,
      "&:hover": {
         opacity: 0.8,
         cursor: 'pointer'
      }
   },
   innerCheck: {
      width: 10,
      height: 10,
      borderRadius: 0.5,
      backgroundColor: Colors.darkGreen
   },
   iconContainer: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 20,
      height: 20,
      "&:hover": {
         // cursor: 'pointer',
         opacity: 0.8
      }
   },
   infoDialogContainer: {
      display: 'flex',
      padding: 2,
      borderRadius: 2,
      maxWidth: 200,
      backgroundColor: '#fff',
      backgroundColor: Colors.lightgreen,
      position: 'absolute',
   }
}