import { useContext, useEffect, useState } from "react";
import { AppContext, AuthContext } from "../../../contexts";
import api from "../../../services/api";
import { Box, Dialog, Spacer, Text, TextField } from "../../W3libComponents";
import { PrimaryButton, SecondaryButton } from "../button/Buttons";

export const ChangePasswordDialog = (props) => {
   const {
      handleClose,
      confimButtonLabel = "Confirmar",
      cancelButtonLabel = "Cancelar",
      question,
      title = "w3lib Admin",
   } = props;

   const { user } = useContext(AuthContext)
   const { setLoading, alert } = useContext(AppContext)

   const { _id: userId } = user
   const [errors, setErrors] = useState({});
   const [checkErrors, setCheckErrors] = useState(false);

   const [resetData, setResetData] = useState({
      password: "",
      newPassword: "",
      confirmationPassword: "",
   });

   useEffect(() => {
      if (checkErrors) validateFields();
   }, [resetData]);

   const handleChangePassword = async (resetData) => {
      const { password, newPassword, confirmationPassword } = resetData;

      setLoading(true);

      await api.post(`/v3/app/editPassword/${userId}`, {
         old_password: password,
         new_password: newPassword,
         confirmation_password: confirmationPassword
      }).then((response) => {
         if (response.status === 200) {
            setLoading(false);
            handleClose()
            alert.success('Senha alterada com sucesso!')
         }
      }).catch((err) => {
         setLoading(false);
         alert.error(err.response.data.msg)
      });
   };

   const validateFields = () => {
      const { password, newPassword, confirmationPassword } = resetData;

      let newErrors = {};

      if (password.length < 4)
         newErrors["password"] = {
            error: 'O campo "Senha" deve conter no mínimo 4 caracteres',
         };

      if (newPassword.length < 4)
         newErrors["newPassword"] = {
            error: 'O campo "Nova Senha" deve conter no mínimo 4 caracteres',
         };

      if (confirmationPassword.length < 4)
         newErrors["confirmationPassword"] = {
            error: "A confirmação da senha é obrigatória",
         };

      setErrors(newErrors);

      if (Object.keys(newErrors).length > 0) {
         return false;
      }
      return true;
   };

   const handleChange = (event) => {
      const { name, value } = event.target;
      setResetData({
         ...resetData,
         [name]: value,
      });
   };

   return (
      <>
         <Dialog open={true} onClose={handleClose}>
            <Box sx={{
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               gap: 1,
               padding: 5,
            }}>
               <Text style={{ fontFamily: 'UbuntuBold', fontSize: 22 }}>
                  {title}
               </Text>
               <Spacer size='2' />
               <Text style={{ fontSize: 14 }}>
                  {question}
               </Text>
               <Spacer />
               <TextField
                  label="Senha Atual"
                  variant="outlined"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  required
                  error={errors?.password}
                  helperText={errors?.password?.error}
                  InputProps={{ style: { fontSize: 14 } }}
               />
               <TextField
                  label="Nova Senha"
                  variant="outlined"
                  name="newPassword"
                  onChange={handleChange}
                  type="password"
                  required
                  error={errors?.newPassword}
                  helperText={errors?.newPassword?.error}
                  InputProps={{ style: { fontSize: 14 } }}
               />
               <TextField
                  label="Repita a nova senha"
                  variant="outlined"
                  name="confirmationPassword"
                  onChange={handleChange}
                  type="password"
                  required
                  error={errors?.confirmationPassword}
                  helperText={errors?.confirmationPassword?.error}
                  InputProps={{ style: { fontSize: 14 } }}
               />
               <Spacer size='2' />
               <Box sx={{
                  display: 'flex',
                  gap: 2
               }}>
                  <PrimaryButton
                     width='large'
                     text={confimButtonLabel}
                     onClick={() => {
                        setCheckErrors(true);
                        if (!validateFields()) {
                           return false
                        };
                        handleChangePassword(resetData);
                     }}
                  />
                  <SecondaryButton
                     width='large'
                     text={cancelButtonLabel}
                     onClick={handleClose}
                  />
               </Box>
            </Box>
         </Dialog>
      </>
   );
};
