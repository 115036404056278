import { useState } from 'react'
import { TableRow, TableCell, Box, Text } from '../../W3libComponents'
import { uid } from '../../../helpers/uid_generator';

export const DetailsRow = ({ label, value }) => (
   <>
      {value &&
         <TableRow style={{ border: 'none' }}>
            <TableCell align="left" sx={{ fontFamily: 'UbuntuBold', width: 140 }}>
               {label}
            </TableCell>
            <TableCell align="left" sx={{ fontFamily: 'Ubuntu' }}>
               {value}
            </TableCell>
         </TableRow>
      }
   </>
)

export const Table = ({ children }) => {
   return (
      <></>
   )
}

export const Row = ({ data = [], columnGap = 1, rowGap = 1.2, contained = false }) => {

   const [labelColumnLength, setLabelColumnLength] = useState(1)

   return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: rowGap, ...(!contained && { width: '100%' }) }}>
         {data.map(({ label = '', description = '-', labelPosition = 'center',  flexColumn = false }) => {
            if (label?.length > labelColumnLength) setLabelColumnLength(label.length);
            return (
               <Box key={`row_label_${uid()}`} sx={{ display: 'flex', width: '100%' }} style={ flexColumn ? {flexDirection: "column"} : {flexDirection: "row"}}>
                  <Box sx={{ display: 'flex', alignItems: labelPosition, width: `${(8 + columnGap) * labelColumnLength}px` }}>
                     <Text style={styles.label}>
                        {label}
                     </Text>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                     {typeof description === 'string' ?
                        <Text style={styles.description}>
                           {description}
                        </Text>
                        :
                        <>{description}</>
                     }
                  </Box>
               </Box>
            )
         }
         )}
      </Box>
   )
}

const styles = {
   label: {
      fontSize: 14,
      fontFamily: 'UbuntuBold'
   },
   description: {
      fontSize: 14,
   },
}
