import React, { useEffect, useState } from "react";
import { FaCommentDots, FaRegCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";
import { uid } from "../../../helpers/uid_generator";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import {
  ContentContainer,
  Pagination,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";

export const EvaluationsReport = ({ context }) => {
  const { setLoading } = context;
  const [evaluatedContracts, setEvaluatedContracts] = useState([]);
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item);
  const [pagination, setPagination] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");

  const [filters, setFilters] = useState({
    filterName: "createdAt",
    filterOrder: "asc",
  });
  const { filterName, filterOrder } = filters;

  useEffect(() => {
    document.title = "TuimBox Admin - Avaliações";
    const handleFetchEvaluatedContracts = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/v3/admin/evaluations`);
        const { data: evaluatedContracts } = response;
        if (evaluatedContracts?.length > 0) {
          setEvaluatedContracts(
            evaluatedContracts?.filter((contract) => contract?.evaluation)
          );
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    handleFetchEvaluatedContracts().catch(console.error);

    if (window.localStorage.getItem("tuimbox-list-evaluations-filters")) {
      const tuimBoxLocalStorage = JSON.parse(
        window.localStorage.getItem("tuimbox-list-evaluations-filters")
      );
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder,
      });
    }
    if (window.localStorage.getItem("tuimbox-list-evaluations-pagination")) {
      const tuimboxListEvaluationsPagination = JSON.parse(
        window.localStorage.getItem("tuimbox-list-evaluations-pagination")
      );
      setPagination(tuimboxListEvaluationsPagination);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      "tuimbox-list-evaluations-filters",
      JSON.stringify({ filterName, filterOrder })
    );
  }, [filters]);

  const sortEvaluations = () => {
    const { filterName, filterOrder } = filters;

    const sortedEvaluations = evaluatedContracts
      .filter((contract) =>
        contract?.products?.name
          ?.toLowerCase()
          .includes(searchFilter.toLowerCase())
      )
      .sort((a, b) => {
        if (filterName === "products") {
          if (
            (String(a[filterName]?.name).toLowerCase() || "") >
            (String(b[filterName]?.name).toLowerCase() || "")
          )
            return filterOrder === "asc" ? 1 : -1;
          if (
            (String(a[filterName]?.name).toLowerCase() || "") <
            (String(b[filterName]?.name).toLowerCase() || "")
          )
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        }
        if (
          filterName === "cleaning" ||
          filterName === "conservation" ||
          filterName === "costBenefit" ||
          filterName === "quality" ||
          filterName === "observation"
        ) {
          if (a.evaluation[filterName] > b.evaluation[filterName])
            return filterOrder === "asc" ? 1 : -1;
          if (a.evaluation[filterName] < b.evaluation[filterName])
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        } else {
          if ((a?.[filterName] || "") > (b?.[filterName] || ""))
            return filterOrder === "asc" ? 1 : -1;
          if ((a?.[filterName] || "") < (b?.[filterName] || ""))
            return filterOrder === "asc" ? -1 : 1;
          return 0;
        }
      });
    return sortedEvaluations;
  };

  const getTopicEvaluation = (topicEvaluation = null) => {
    if (topicEvaluation) {
      let greenStars = topicEvaluation;
      let grayStars = 5 - greenStars;

      return (
        <>
          {[...Array(greenStars)].map((e, i) => (
            <Box
              key={`green${i}`}
              style={{
                ...styles.star,
                backgroundImage: `url('/icons/green_star.png')`,
              }}
            />
          ))}
          {[...Array(grayStars)].map((e, i) => (
            <Box
              key={`gray${i}`}
              style={{
                ...styles.star,
                backgroundImage: `url('/icons/gray_star.png')`,
              }}
            />
          ))}
        </>
      );
    }
    return <></>;
  };

  const getAvarageRating = () => {
    if (evaluatedContracts) {
      let sumOfAllEvaluations = 0;
      evaluatedContracts.map((e) => {
        sumOfAllEvaluations +=
          (e.evaluation?.cleaning +
            e.evaluation?.conservation +
            e.evaluation?.costBenefit +
            e.evaluation?.quality) /
          4;
      });

      return `${(sumOfAllEvaluations / evaluatedContracts.length).toFixed(
        2
      )} / 5`;
    }
    return `-`;
  };

  return (
    <>
      <SectionHeader
        title={`Avaliações`}
        subtitle={`Média: ${getAvarageRating()}`}
      />
      <ContentContainer>
        <Box sx={{ display: "flex", gap: 3 }}>
          <SearchBar
            placeholder="Forno de Pizza, 0502..."
            onChange={({ target }) => setSearchFilter(target.value)}
          />
          <Pagination
            localStorageName="tuimbox-list-evaluations-pagination"
            dataLength={evaluatedContracts.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>

        <Box
          style={{
            border: "1px solid #e4e4e4",
            backgroundColor: "#fff",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Box style={{ width: "100%", display: "flex" }}>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 160 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "createdAt" ? "underline" : "none",
                }}
              >
                Data
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "createdAt"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "createdAt",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "products" ? "underline" : "none",
                }}
              >
                Produto
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "products"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "products",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 120 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "cleaning" ? "underline" : "none",
                }}
              >
                Limpeza
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "cleaning"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "cleaning",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 120 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "conservation" ? "underline" : "none",
                }}
              >
                Conservação
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "conservation"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "conservation",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 120 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "costBenefit" ? "underline" : "none",
                }}
              >
                Custo/Benef.
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "costBenefit"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "costBenefit",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 120 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "quality" ? "underline" : "none",
                }}
              >
                Qualidade
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "quality"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "quality",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 65 }}>
              <Text
                style={{
                  ...styles.tableHeadText,
                  textDecorationLine:
                    filterName === "observation" ? "underline" : "none",
                }}
              >
                Obs.
              </Text>
              <Box
                sx={{
                  ...styles.checkIcon,
                  ...styles.filterIconContainer,
                  backgroundImage:
                    filterName === "observation"
                      ? filterOrder === "asc"
                        ? `url('/icons/gray_arrow_down.png')`
                        : `url('/icons/gray_arrow_up.png')`
                      : `url('/icons/gray_arrow_down.png')`,
                }}
                onClick={() =>
                  setFilters({
                    filterName: "observation",
                    filterOrder:
                      filters?.filterOrder === "asc" ? "desc" : "asc",
                  })
                }
              />
            </Box>
          </Box>

          <Box style={{ display: "flex", flexDirection: "column" }}>
            {sortEvaluations()
              .filter(dataFilter)
              ?.map((contract, index) => (
                <Link
                  key={`evaluations_report_${uid()}`}
                  to={`/relatorios/avaliacoes/${contract._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={styles.tableRow}>
                    <Box style={{ ...styles.row, width: 160 }}>
                      <Text style={{ ...styles.cellText, textAlign: "center" }}>
                        {formatTimestamp({
                          timestamp: contract?.evaluation?.createdAt,
                          showSeconds: false,
                        })}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, flex: 1 }}>
                      <Text style={{ ...styles.cellText, textAlign: "center" }}>
                        {contract?.products?.name || "Não informado"}
                      </Text>
                    </Box>
                    <Box style={{ ...styles.row, width: 120 }}>
                      {getTopicEvaluation(contract?.evaluation?.cleaning)}
                    </Box>
                    <Box style={{ ...styles.row, width: 120 }}>
                      {getTopicEvaluation(contract?.evaluation?.conservation)}
                    </Box>
                    <Box style={{ ...styles.row, width: 120 }}>
                      {getTopicEvaluation(contract?.evaluation?.costBenefit)}
                    </Box>
                    <Box style={{ ...styles.row, width: 120 }}>
                      {getTopicEvaluation(contract?.evaluation?.quality)}
                    </Box>
                    <Box style={{ ...styles.row, width: 65 }}>
                      <Text style={{ ...styles.cellText, textAlign: "center" }}>
                        {contract?.evaluation?.observation === "" ? (
                          <FaRegCommentDots size={20} color="#ccc" />
                        ) : (
                          <FaCommentDots size={20} color={Colors.darkGreen} />
                        )}
                      </Text>
                    </Box>
                  </Box>
                </Link>
              ))}
          </Box>
        </Box>
      </ContentContainer>
    </>
  );
};

const styles = {
  cellText: {
    fontSize: 14,
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableHeadText: {
    fontSize: "14px",
    color: "#333",
    textAlign: "center",
    fontFamily: "UbuntuBold",
    marginLeft: 7,
  },
  tableRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 45,
    borderTopWidth: "1px",
    borderTopStyle: "solid",
    borderTopColor: "#e4e4e4",
    "&:hover": {
      backgroundColor: "#6b858e14",
      cursor: "pointer",
    },
  },
  row: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 5,
    paddingBottom: 5,
  },
  star: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: "content-box",
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
};
