import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import Application from './components/organisms/layout/Application';
import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';
import moment from 'moment';
import 'moment/locale/pt-br';

function App() {

   moment.locale('pt-br');

   return (
      <ThemeProvider theme={theme}>
         <AppProvider>
            <AuthProvider>
               <Application />
            </AuthProvider>
         </AppProvider>
      </ThemeProvider>
   );
}

export default App;
