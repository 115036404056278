import { useEffect, useState } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import api from "../../../services/api";
import { Box, Spacer, Text } from "../../atoms";
import { PrimaryButton } from '../../organisms/button/Buttons';
import { ContentContainer, SectionHeader } from "../../W3libComponents";
import { uid } from '../../../helpers/uid_generator';
import { formatTimestamp } from '../../../helpers/user/formatTimestamp';

export const TuimReport = (props) => {

    const { context } = props
    const { setLoading, alert } = context

    const [data, setData] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [disableButton, setDisableButton] = useState(true)

    const compareDates = () => {
        const from = new Date(startDate)
        const to = new Date(endDate)

        if ((to - from) < 0) return false
        return true
    }

    const loadReport = async () => {
        setData([])
        setLoading(true)
        try {
            const response = await api.get(`/v3/admin/cross-origin-contracts?from=${startDate}&to=${endDate}`, { timeout: 0 })
            if (response.status === 200) {
                setData(response.data)
                return
            }
            if (response.status === 204) {
                alert.info('Sem registros', 'Não encontramos nenhum contrato para o período selecionado.')
            }
        } catch (error) {
            console.log(error)
        }
        finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!!startDate && !!endDate) {
            if (compareDates()) {
                setDisableButton(false)
                return
            } else {
                alert.error('A data final deve ser maior do que a data inicial.')
                setDisableButton(true)
            }
        }
    }, [startDate, endDate])

    useEffect(() => {
        document.title = `TuimBox Admin - Relatório Tuim`
    }, [])



    return (
        <>
            <style jsx global>
                {`
                        .btnz {
                            padding: 14px;
                            font-size: 0.875rem;
                            width: 200px;
                            box-sizing: border-box;
                            font-family: "Ubuntu";
                            border-radius: 8px;
                            border: none;
                           color: #444;
                           background-color: #d9d9d9;
                          }
                          .btnz:hover {
                           background-color: #d9d9d9da;
                           cursor: pointer;
                          }
                        `}
            </style>
            <SectionHeader
                title='Relatório TUIM'
                subtitle={`Contratos: ${data?.length || '-'}`}
            />
            <ContentContainer>
                <Box style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '20px',
                }}>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Text style={styles.datePickerLabel}>
                            De:
                        </Text>
                        <input
                            type="date"
                            value={startDate}
                            onChange={({ target }) => setStartDate(target.value)}
                            style={{ width: '160px', padding: 9, borderRadius: 6, border: `1px solid #bbb`, fontFamily: 'Ubuntu' }}
                        />
                    </Box>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Text style={styles.datePickerLabel}>
                            Até:
                        </Text>
                        <input
                            type="date"
                            value={endDate}
                            onChange={({ target }) => setEndDate(target.value)}
                            style={{ width: '160px', padding: 9, borderRadius: 6, border: `1px solid #bbb`, fontFamily: 'Ubuntu' }}
                        />
                    </Box>

                    <Box style={{ display: 'flex' }}>
                        <PrimaryButton
                            text="Gerar Relatório"
                            width='large'
                            onClick={() => loadReport()}
                            disabled={disableButton}
                        />
                    </Box>
                </Box>

                {data?.length > 0 &&
                    <div style={{ maxWidth: '100%' }}>
                        <Box style={{ overflow: 'auto', borderRadius: 15, maxWidth: '100%' }}>
                            <table id="tuim_report">
                                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#fff' }}>
                                    <tr>
                                        <th colSpan={2} style={{ ...styles.rowContent, backgroundColor: '#b1cccf' }}>Contrato</th>
                                        <th colSpan={2} style={{ ...styles.rowContent, backgroundColor: '#87b5ba', borderTopRightRadius: 10 }}>Usuario</th>
                                    </tr>
                                    <tr>
                                        <th style={{ ...styles.rowContent, backgroundColor: '#b1cccf' }}>ID Contrato</th>
                                        <th style={{ ...styles.rowContent, backgroundColor: '#b1cccf' }}>Data criação</th>
                                        <th style={{ ...styles.rowContent, backgroundColor: '#87b5ba' }}>ID Usuario</th>
                                        <th style={{ ...styles.rowContent, backgroundColor: '#87b5ba' }}>Nome</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data.map((item, index) => {
                                        return (
                                            <tr key={`tuim_report-${uid()}`} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#f1f1f1' }}>
                                                <td style={styles.rowContent}>{item?._id || '-'}</td>
                                                <td style={styles.rowContent}>{formatTimestamp({ timestamp: item?.createdAt, showSeconds: false }) || '-'}</td>
                                                <td style={styles.rowContent}>{item?.id_user?.id || '-'}</td>
                                                <td style={styles.rowContent}>{item?.id_user?.name || '-'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Box>
                        <Spacer size='3' />
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ReactHTMLTableToExcel
                                table="tuim_report"
                                filename="Relatorio_Tuim_TuimBox"
                                sheet="tablexls"
                                buttonText="Exportar para Excel"
                                className='btnz'
                            />
                        </Box>
                    </div>
                }
            </ContentContainer>
        </>
    )
}

const styles = {
    rowContent: {
        fontSize: '14px',
        textAlign: 'center',
        padding: `12px`,
        whiteSpace: 'nowrap'
    },
    datePickerLabel: {
        fontSize: 14,
        width: '50px'
    },
    regularText: {
        fontSize: 14
    }
}