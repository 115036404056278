import { Box, Text, TextField } from "../../W3libComponents";
import { Colors } from "../../organisms/layout/Colors";
import { useCallback, useEffect, useRef } from "react";
import { SecondaryButton } from "../../organisms/button/Buttons";
import api from "../../../services/api";
import { useHistory, useParams } from "react-router-dom";

export const ResetPassword = ({ context }) => {
  const { alert, setLoading } = context;

  const ref = useRef({
    code: "",
    password: "",
    confirmPassword: "",
  });

  const { token } = useParams();
  const history = useHistory();
  const smallWidthDevice = window.innerWidth < 1000;

  useEffect(() => {
    setLoading(true);
    api
      .post("/v3/app/reset-password/validate-token", { token })
      .then(() => setLoading(false))
      .catch((error) => {
        console.log(error);
        history.push("/");
        setLoading(false);
      });
  }, []);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "password") {
        ref.current.password = e.target.value;
        return;
      }

      if (e.target.name === "confirmPassword") {
        ref.current.confirmPassword = e.target.value;
        return;
      }

      ref.current.code = e.target.value;
    },
    [ref]
  );

  const handleResetPassword = useCallback(async () => {
    setLoading(true);

    try {
      if (
        ref.current.code === "" ||
        ref.current.password === "" ||
        ref.current.confirmPassword === ""
      )
        return alert.error("Parece que você não preencheu um dos campos.");

      if (ref.current.password !== ref.current.confirmPassword)
        return alert.error("Senhas devem ser iguais.");

      const { data } = await api.patch("/v3/app/reset-password", {
        password: ref.current.password,
        confirmPassword: ref.current.confirmPassword,
        code: ref.current.code,
        token,
      });

      if (!data.success)
        return alert.error("Houve um erro ao atualizar sua senha");

      return history.push("/");
    } catch (error) {
      if (error.response.data?.error?.message) {
        window.alert("Tempo do token expirou.");
        return history.push("/");
      }
      return alert.error(error.response.data?.error);
    } finally {
      setLoading(false);
    }
  }, [alert, history, setLoading]);

  return (
    <Box style={styles.container}>
      <div style={styles.firstBackground} />
      <div style={styles.secondBackground} />
      <div style={styles.thirdBackground} />
      <div style={styles.fourthBackground} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
          alignItems: "center",
          maxWidth: smallWidthDevice ? "80%" : "500px",
          margin: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            flex: 1,
            gap: 8,
            padding: smallWidthDevice ? 5 : 7,
            borderRadius: "12px",
            minWidth: 400,
            backgroundColor: Colors.darkGreen,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text
              style={{ fontSize: 25, fontFamily: "UbuntuBold", color: "#fff" }}
            >
              Esqueci minha senha.
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
            }}
          >
            <TextField
              placeholder="Token"
              onChange={handleChange}
              name="code"
              margin="none"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 14,
                  backgroundColor: "#ffffff22",
                  color: "#ffffffbb",
                  outline: "none",
                },
              }}
            />
            <TextField
              placeholder="******"
              onChange={handleChange}
              name="password"
              margin="none"
              type="password"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 14,
                  backgroundColor: "#ffffff22",
                  color: "#ffffffbb",
                  outline: "none",
                },
              }}
            />
            <TextField
              placeholder="******"
              onChange={handleChange}
              name="confirmPassword"
              margin="none"
              type="password"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 14,
                  backgroundColor: "#ffffff22",
                  color: "#ffffffbb",
                  outline: "none",
                },
              }}
            />
          </Box>
          <SecondaryButton
            style={{
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor: "#f1f1f1",
            }}
            text="Alterar minha senha"
            onClick={handleResetPassword}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: Colors.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  firstBackground: {
    position: "absolute",
    top: -370,
    right: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
  secondBackground: {
    position: "absolute",
    top: 70,
    left: 400,
    width: 200,
    height: 200,
    borderRadius: 8,
    transform: "rotate(-10deg)",
    backgroundColor: Colors.darkGreen + "21",
  },
  thirdBackground: {
    position: "absolute",
    bottom: 60,
    right: 250,
    width: 200,
    height: 200,
    borderRadius: "50%",
    backgroundColor: Colors.darkGreen + "21",
  },
  fourthBackground: {
    position: "absolute",
    bottom: -370,
    left: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
};
