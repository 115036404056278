import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { uid } from "../../../helpers/uid_generator";
import { updatedFieldsConstructor } from "../../../helpers/updatedFieldsConstructor";
import api from "../../../services/api";
import {
  ContentContainer,
  LogList,
  SearchBar,
  SectionHeader,
} from "../../W3libComponents";
import { Box, Grid, Spacer, Text, TextField } from "../../atoms";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { Lockers } from "../../organisms/locker/locker";

const statusOptions = [
  {
    value: "available",
    display: "Disponível",
    color: Colors.green,
  },
  {
    value: "delivered",
    display: "Alugado",
    color: Colors.yellow,
  },
];

export const DoorEdit = ({ context }) => {
  const doorDataRef = useRef(null);
  const location = useLocation();
  const path = location.pathname.split("/");
  const newDoor = path[path.length - 1] === "new" ? true : false;
  const doorId = location.pathname.split("/")[2];
  const {
    setLoading,
    alert,
    user: { email: adminUserEmail, _id: userId },
  } = context;
  const history = useHistory();
  const [doorData, setDoorData] = useState({
    codLoopBox: "",
    id_loopbox_porta: "",
    dimension: "",
    url: "",
    active: false,
    fixed: true,
    id_locker: null,
    id_product: null,
    productStatus: "available",
    qr_code: "",
  });
  const [originalData, setOriginalData] = useState({});
  const [lockers, setLockers] = useState([]);
  const [products, setProducts] = useState([]);
  const [lockerSearchFilter, setLockerSearchFilter] = useState("");
  const [productsSearchFilter, setProductsSearchFilter] = useState("");
  const [updatedFields, setUpdatedFields] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const productsContainer = useRef(null);
  const adminUpdatedFields = updatedFields.length > 0;
  const updatedDoorFields = updatedFieldsConstructor(
    updatedFields,
    originalData,
    doorData
  );

  useEffect(() => {
    document.title = `TuimBox Admin - Nova Porta`;
    !newDoor && fetchDoorData();

    api
      .get(`/v3/admin/lockers`)
      .then((response) => {
        setLockers(response.data.lockers);
      })
      .catch((err) => {
        console.log("Error fetching lockers");
        console.log(err);
      });
  }, []);

  const fetchDoorData = () => {
    setLoading(true);
    api
      .get(`/v3/admin/door/${doorId}`)
      .then(async (response) => {
        document.title = `TuimBox Admin - Porta ${response?.data?.codLoopBox}`;
        setDoorData({
          // ...doorData,
          ...response.data,
        });
        setOriginalData(response.data);
        if (!response.data.id_locker.boxCode) await fetchProducts();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const fetchProducts = async () => {
    await api
      .get(`/v3/admin/products?fields=name,urlImg`)
      .then((response) => {
        const { products } = response.data;
        setProducts(products);
      })
      .catch((err) => {
        console.log("Error fetching products");
        console.log(err);
      });
  };

  const handleUpdateDoor = async () => {
    setLoading(true);
    if (newDoor) {
      api
        .post(`/v3/admin/door`, { ...doorData })
        .then((response) => {
          setLoading(false);
          alert.success("Nova porta salva com sucesso!");
          if (!response?.data?.data?._id) return;

          window.open(`/doors/${response.data.data._id}`, "_self");
        })
        .catch((err) => {
          setLoading(false);
          alert.error("Desculpe. Não foi possível cadastrar uma nova porta.");
        });
    } else {
      api
        .patch(`/v3/admin/door/${doorId}`, {
          data: doorData,
          updatedFields: updatedDoorFields,
          adminUserEmail,
        })
        .then((response) => {
          setLoading(false);
          alert.success("Porta atualizada com sucesso!");
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
          alert.error("Desculpe. Não foi possível atualizar a porta.");
        });
    }
  };

  const handleDeleteDoor = () => {
    setLoading(true);
    !newDoor &&
      api
        .delete(`/v3/admin/door/${doorId}`)
        .then((response) => {
          alert.success("Porta excluída com sucesso!");
          setLoading(false);
          history.push(`/doors`);
        })
        .catch((error) => {
          setLoading(false);
          alert.error("Desculpe. Não foi possível excluir a porta.");
        });
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setUpdatedFields([...updatedFields.filter((item) => item !== name), name]);
    setDoorData({
      ...doorData,
      [name]: value,
    });
  };

  const handleChangeProduct = (selectedProductId) => {
    setProductsSearchFilter("");
    setDoorData({
      ...doorData,
      id_product: {
        ...doorData.id_product,
        _id:
          selectedProductId === doorData?.id_product?._id
            ? null
            : selectedProductId,
      },
    });

    productsContainer.current.scroll({ top: 0, behavior: "smooth" });
  };

  const handleChangeLocker = (lockerId) => {
    setLockerSearchFilter("");
    setDoorData({
      ...doorData,
      id_locker: {
        ...doorData.id_locker,
        _id: lockerId === doorData?.id_locker?._id ? null : lockerId,
      },
    });
  };

  const handleChangeActivation = () => {
    setDoorData({
      ...doorData,
      active: !doorData?.active,
    });
    setUpdatedFields([
      ...updatedFields.filter((item) => item !== "active"),
      "active",
    ]);
  };

  const handleChangeFixedDoor = (state) => {
    setDoorData({
      ...doorData,
      fixed: state,
    });
  };

  const getDoorStatus = (status) => {
    if (status === "available") return "disponível";
    if (status === "delivered") return "alugado";
    if (status === "leased") return "não retirado";
    if (status === "access_code_expired") return "com código expirado";
  };

  const sortedLockers = () => {
    let sortedLockers = lockers.filter(
      (locker, index) =>
        locker?.name.toLowerCase().includes(lockerSearchFilter.toLowerCase()) ||
        locker?.description
          .toLowerCase()
          .includes(lockerSearchFilter.toLowerCase())
    );

    if (sortedLockers.length === 0) {
      return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Text style={styles.regularText}>Nenhum locker encontrado.</Text>
        </Box>
      );
    } else {
      return sortedLockers
        .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        .sort((a, b) => (a?._id === doorData?.id_locker?._id ? -1 : 1))
        .map((locker, index) => (
          <Box
            key={`locker_${uid()}`}
            onClick={() => handleChangeLocker(locker._id)}
            sx={{
              ...styles.lockersRowContainer,
              backgroundColor:
                locker?._id === doorData?.id_locker?._id
                  ? Colors.selectedBackground
                  : Colors.background,
            }}
          >
            <Box style={{ width: 33 }}>
              {locker?._id === doorData?.id_locker?._id ? (
                <Box style={{ ...styles.checkIcon }} />
              ) : (
                <></>
              )}
            </Box>
            <Box style={{ display: "flex" }}>
              <Text
                style={{
                  ...styles.regularText,
                  fontWeight:
                    locker?._id === doorData?.id_locker?._id ? 600 : 500,
                }}
              >
                {locker?.name}
              </Text>
              <Spacer horizontal />
              <Text
                style={{
                  ...styles.regularText,
                  color: "#999",
                  fontWeight: 100,
                }}
              >
                ({locker?.description || "Descrição não informada"})
              </Text>
            </Box>
          </Box>
        ));
    }
  };

  const sortedProducts = () => {
    let sortedProducts = products.filter((product, index) =>
      product?.name?.toLowerCase().includes(productsSearchFilter.toLowerCase())
    );

    if (sortedProducts.length === 0) {
      return (
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Text style={styles.regularText}>Nenhum produto encontrado.</Text>
        </Box>
      );
    } else {
      return sortedProducts
        .sort((a, b) => (a?.name > b?.name ? 1 : -1))
        .sort((a, b) => (a?._id === doorData?.id_product?._id ? -1 : 1))
        .map((product, index) => (
          <Grid
            key={`product_${uid()}`}
            item
            zeroMinWidth
            // md={2.5}
            onClick={() => handleChangeProduct(product._id)}
            sx={{
              ...styles.productCardContainer,
              borderColor:
                product._id === doorData?.id_product?._id
                  ? Colors.darkGreen
                  : Colors.darkerBackground,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                top: 5,
                right: 5,
              }}
            >
              {product._id === doorData?.id_product?._id && (
                <Box style={{ ...styles.checkIcon }} />
              )}
            </Box>
            <Box
              sx={{
                ...styles.productCardContent,
                backgroundImage: product?.urlImg
                  ? `url(${product.urlImg})`
                  : "none",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                flex: 1,
              }}
            ></Box>
            <Text style={{ ...styles.regularText, textAlign: "center" }}>
              {product.name}
            </Text>
          </Grid>
        ));
    }
  };

  return (
    <>
      <SectionHeader
        title={`Porta ${doorData.codLoopBox}`}
        subtitle={
          newDoor ? "" : `Produto ${getDoorStatus(doorData.productStatus)}`
        }
        saveButton={adminUpdatedFields}
        saveButtonAction={handleUpdateDoor}
        deleteButton={!newDoor}
        deleteButtonAction={handleDeleteDoor}
        logButton
        logButtonAction={() => setShowLogs(true)}
      />
      <Box
        style={{
          display: "flex",
          gap: 28,
        }}
      >
        <ContentContainer style={{ minWidth: 380 }}>
          <Box
            style={{ display: "flex", flexDirection: "column", gap: 8 }}
            ref={doorDataRef}
          >
            <SmallSectionTitle title="Dados da Porta" />
            <Spacer />
            <RowField label="Status">
              <StatusLabel
                background="light"
                currentStatus={doorData?.productStatus}
                dropDown
                dropDownOptions={statusOptions}
                dropDownOptionAction={(value) =>
                  handleChange({ target: { name: "productStatus", value } })
                }
              />
            </RowField>
            <RowField label="Código TuimBox">
              <TextField
                variant="standard"
                size="small"
                value={doorData?.codLoopBox || ""}
                name="codLoopBox"
                onChange={handleChange}
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { color: "#888" } }}
              />
            </RowField>
            <RowField label="ID da Porta">
              <TextField
                variant="standard"
                size="small"
                value={doorData?.id_loopbox_porta || ""}
                name="id_loopbox_porta"
                onChange={handleChange}
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { color: "#888" } }}
              />
            </RowField>
            <RowField label="Dimensões">
              <TextField
                variant="standard"
                size="small"
                value={doorData?.dimension || ""}
                name="dimension"
                onChange={handleChange}
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ style: { color: "#888" } }}
              />
            </RowField>
            <Spacer size={2} />
            <Box
              sx={styles.activeContainer}
              onClick={() => handleChangeActivation()}
            >
              <Box
                sx={{
                  ...styles.checkIcon,
                  marginLeft: 2,
                  backgroundImage: doorData.active
                    ? `url('/icons/green_check_icon.png')`
                    : `url('/icons/gray_check_icon.png')`,
                }}
              />
              <Spacer horizontal />
              <Box sx={styles.activeDoorTextContainer}>
                <Text style={styles.regularText}>
                  {doorData.active ? `Porta Ativa` : `Porta Inativa`}
                </Text>
              </Box>
            </Box>
            <Box sx={{ ...styles.fixedDoorContainer }}>
              <Box
                sx={{
                  ...styles.fixedDoorOptionContainer,
                  backgroundColor: !doorData?.fixed
                    ? Colors.selectedBackground
                    : "transparent",
                }}
                onClick={() => handleChangeFixedDoor(false)}
              >
                <Text style={styles.regularText}>Rotativa</Text>
              </Box>
              <Box
                sx={{
                  ...styles.fixedDoorOptionContainer,
                  backgroundColor: doorData?.fixed
                    ? Colors.selectedBackground
                    : "transparent",
                }}
                onClick={() => handleChangeFixedDoor(true)}
              >
                <Text style={styles.regularText}>Fixa</Text>
              </Box>
            </Box>
          </Box>
        </ContentContainer>
        <ContentContainer fullWidth>
          <Lockers
            doorData={doorData}
            lockerData={sortedLockers()}
            handleChangeFilter={setLockerSearchFilter}
            maxHeight={doorDataRef?.current?.clientHeight}
          />
        </ContentContainer>
      </Box>

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          height: 600,
          gap: 24,
        }}
      >
        <ContentContainer fullWidth>
          <Products
            doorData={doorData}
            productsLength={products?.length}
            productsData={sortedProducts()}
            reference={productsContainer}
            handleChangeFilter={setProductsSearchFilter}
          />
        </ContentContainer>
        <ContentContainer fullWidth>
          <InternalNotes
            reference={{ type: "DoorLocker", id: doorId }}
            authorId={userId}
          />
        </ContentContainer>
      </Box>

      {showLogs ? (
        <LogList id={doorId} handleClose={() => setShowLogs(false)} />
      ) : (
        <></>
      )}
    </>
  );
};

const Products = ({
  doorData,
  productsData,
  productsLength = 0,
  reference,
  handleChangeFilter = () => {},
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        overflow: "hidden",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SmallSectionTitle title={`Produtos (${productsLength})`} />
        <Spacer horizontal size="2" />
        {!doorData?.id_product?._id && (
          <Box sx={styles.noProduct}>
            <Box
              style={{
                width: 10,
                height: 10,
                backgroundColor: "#d33109",
                borderRadius: "50%",
              }}
            />
            <Spacer horizontal />
            <Text style={{ ...styles.regularText, textAlign: "center" }}>
              Nenhum produto selecionado
            </Text>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          // maxHeight: 600,
          overflowX: "auto",
        }}
      >
        <SearchBar
          placeholder="Extrator de Nutrientes..."
          onChange={({ target }) => handleChangeFilter(target.value)}
        />
        <Grid
          ref={reference}
          container
          spacing={0}
          sx={styles.productCardMainContainer}
        >
          {productsData}
        </Grid>
      </Box>
    </Box>
  );
};

export const RowField = ({ children, label = "" }) => {
  return (
    <Box sx={{ display: "flex", width: "100%", gap: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", width: "120px" }}>
        <Text style={styles.label}>{label}</Text>
      </Box>
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  );
};

const styles = {
  qrCodeCard: {
    borderRadius: 12,
    overflow: "hidden",
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 215,
    minWidth: 215,
    maxWidth: 215,
    height: 300,
    "&:hover": {
      cursor: "pointer",
    },
  },
  qrCodeOptionsContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
  },
  qrCodeOptionsContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 2,
    margin: 2,
    borderRadius: 2,
  },
  regularText: {
    fontSize: 14,
  },
  boldText: {
    fontSize: 14,
    fontWeight: 600,
  },
  productCardMainContainer: {
    width: "100%",
    display: "flex",
    gap: 1,
    padding: `0px 4px`,
  },
  productCardContainer: {
    position: "relative",
    border: `1px solid ${Colors.darkerBackground}`,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: 1,
    borderRadius: 2,
    flex: 1,
    minWidth: 150,
    maxWidth: "100%",
    height: 180,
    "&:hover": {
      cursor: "pointer",
      borderColor: Colors.darkGreen,
    },
  },
  productCardContent: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundOrigin: "content-box",
    width: "60%",
    height: 140,
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  lockersRowContainer: {
    display: "flex",
    alignItems: "center",
    padding: `12px 20px`,
    marginBottom: 0.5,
    borderRadius: 2,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  addButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 70,
    height: 70,
    borderRadius: 35,
    backgroundColor: Colors.darkerBackground,
  },
  productOptionsContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
  },
  productOptionsContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: 2,
    margin: 2,
    borderRadius: 2,
  },
  activeContainer: {
    height: 40,
    backgroundColor: Colors.background,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  fixedDoorContainer: {
    height: 40,
    backgroundColor: Colors.background,
    borderRadius: 2,
    overflow: "hidden",
    display: "flex",
  },
  noProduct: {
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
  },
  activeDoorTextContainer: {
    flex: 1,
    marginRight: 1,
    display: "flex",
    justifyContent: "center",
  },
  fixedDoorOptionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    padding: 1,
    backgroundColor: Colors.background,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.darkerBackground,
    },
  },
  filterInput: {
    padding: 10,
    backgroundColor: "#6b858e15",
    border: "none",
    width: "100%",
    boxSizing: "border-box",
    outline: "none",
    borderRadius: 5,
    paddingLeft: 15,
    fontSize: 14,
    fontFamily: "Ubuntu",
  },
  label: {
    fontSize: 14,
    fontFamily: "UbuntuBold",
  },
  description: {
    fontSize: 14,
  },
};
