
import { useRef, useState } from "react";
import { formatTimestamp } from "../../../../helpers/user/formatTimestamp";
import { useFetch } from "../../../../hooks/useFetch";
import api from "../../../../services/api";
import { ContentContainer, SectionHeader } from "../../../W3libComponents";
import { Box, Button, Text } from "../../../atoms";

const REFERENCES = [
  { text: 'Nome do cliente', value: '**NOME**' },
  { text: 'Documento', value: '**DOCUMENTO**' },
  { text: 'Dia', value: '**DIA**' },
  { text: 'Mês', value: '**MES**' },
  { text: 'Ano', value: '**ANO**' },
  { text: 'Nome do produto', value: '**NOME_DO_PRODUTO**' },
  { text: 'Descrição do produto', value: '**DESCRICAO_DO_PRODUTO**' },
  { text: 'Preço', value: '**PRECO**' },
  { text: 'Número do SAC', value: '**CONTATO_SAC**' }
]

export const AppContract = () => {
  const { data, alert } = useFetch({ url: '/v3/appsettings?fields=contract,updatedAt' })

  const ref = useRef(null);
  const [editMode, setEditMode] = useState(false)
  const contractLines = data?.contract?.split(/\\n/g) || [];

  const lastUpdate = `Última atualização em ${formatTimestamp({ timestamp: data?.updatedAt })} por ${data?.updatedBy?.name}`

  const validateContract = () => {
    const contractLines = ref.current.value;

    const missingTag = REFERENCES.some(({ value }) => !contractLines.includes(value));

    if (missingTag) {
      alert.error(`Você precisa incluir todas as tags ao contrato.`)
      return false
    }
    return true;
  }

  const update = async () => {
    if (!validateContract()) return;

    const newContractLines = ref.current.value.replace(/\\n/g, '\n');
    await api.patch(`/v3/admin/appsettings/${data._id}`, { contract: newContractLines })
      .then(({ status }) => {
        if (status === 200) window.location.reload();
      })
      .catch(error => alert.error('Tivemos um problema ao atualizar o contrato.'));
  }

  return (
    <>
      <SectionHeader
        title='Contrato de Locação'
        subtitle={lastUpdate}
        saveButton={editMode}
        saveButtonAction={update}
        editButton={!editMode}
        editButtonAction={() => setEditMode(prev => !prev)}
      />
      <ContentContainer>
        {editMode ?
          <>
            <Box style={{ display: 'flex', gap: 4 }}>
              {REFERENCES.map(({ text, value }) =>
                <ReferenceTag
                  key={value}
                  text={text}
                  action={() => {
                    const start = ref.current.selectionStart;
                    const end = start;
                    ref.current.setRangeText(value, start, end)
                    ref.current.focus();
                  }} />
              )}
            </Box>
            <textarea
              ref={ref}
              defaultValue={data?.contract?.replace(/\\n/g, "\n\n") || ''}
              rows={24}
              style={styles.textArea}
            />
          </>
          :
          <>
            {contractLines.map(line =>
              <Text key={line} style={{ whiteSpace: 'break-spaces' }}>
                {line}
              </Text>
            )}
          </>
        }
      </ContentContainer>
    </>

  )
}

const ReferenceTag = ({ text, action }) => (
  <Button
    style={{ backgroundColor: '#eee', borderRadius: 20, padding: `6px 14px` }}
    onClick={action}
  >
    <Text style={{ fontSize: 12 }}>{text}</Text>
  </Button>
)

const styles = {
  textArea: { textAlign: 'justify', resize: 'none', padding: 20, borderRadius: 8, fontSize: 14, fontFamily: 'Ubuntu', color: '#444' }
}