import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { uid } from '../../../helpers/uid_generator'
import { formatTimestamp } from "../../../helpers/user/formatTimestamp"
import api from "../../../services/api"
import { ContentContainer, Pagination, SearchBar, SectionHeader } from "../../W3libComponents"
import { Box, Text } from "../../atoms"
import { Colors } from "../../organisms/layout/Colors"
import { StatusLabel } from "../../organisms/layout/StatusLabel"

export const SuggestionsReport = ({ context }) => {

  const { setLoading } = context;
  const [suggestions, setSuggestions] = useState([])
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
  const [pagination, setPagination] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')

  const [filters, setFilters] = useState({
    filterName: 'createdAt',
    filterOrder: 'asc'
  })
  const { filterName, filterOrder } = filters;
  const statusOptions = [
    {
      value: 'aberto',
      display: 'Aberto',
      color: Colors.red
    },
    {
      value: 'pendente',
      display: 'Pendente',
      color: Colors.yellow
    },
    {
      value: 'resolvido',
      display: 'Resolvido',
      color: Colors.green
    }
  ]

  useEffect(() => {
    document.title = `TuimBox Admin - Sugestões`
    const handleFetchSuggestions = async () => {
      try {
        setLoading(true)
        const response = await api.get(`/v3/admin/suggestion`)
        const suggestions = response.data
        setSuggestions(suggestions)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
    handleFetchSuggestions().catch(console.error)

    if (window.localStorage.getItem('tuimbox-list-suggestion-filters')) {
      const tuimBoxLocalStorage = JSON.parse(window.localStorage.getItem('tuimbox-list-suggestion-filters'));
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder
      })
    }
    if (window.localStorage.getItem('tuimbox-list-suggestion-pagination')) {
      const tuimboxListSuggestionPagination = JSON.parse(window.localStorage.getItem('tuimbox-list-suggestion-pagination'));
      setPagination(tuimboxListSuggestionPagination)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem('tuimbox-list-suggestion-filters', JSON.stringify({ filterName, filterOrder }));
  }, [filters])

  const sortSuggestions = () => {

    const { filterName, filterOrder } = filters;

    const sortedSuggestions = suggestions.filter(suggestion =>
      suggestion?.name?.toLowerCase().includes(searchFilter.toLowerCase()) ||
      suggestion?.lockerLocation?.toLowerCase().includes(searchFilter.toLowerCase())
    ).sort((a, b) => {
      if (filterName === 'type' || filterName === 'name' || filterName === 'lockerLocation') {
        if ((String(a?.[filterName]).toLowerCase() || '') > (String(b?.[filterName]).toLowerCase() || '')) return (filterOrder === 'asc') ? 1 : -1
        if ((String(a?.[filterName]).toLowerCase() || '') < (String(b?.[filterName]).toLowerCase() || '')) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
      else {
        if ((a?.[filterName] || '') > (b?.[filterName] || '')) return (filterOrder === 'asc') ? 1 : -1
        if ((a?.[filterName] || '') < (b?.[filterName] || '')) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
    })
    return sortedSuggestions
  }

  const getSuggestionType = (type) => {
    if (type === 'suggestion') return `Sugestão`
    if (type === 'complaint') return `Reclamação`
    if (type === 'doubt') return `Dúvida`
    return `Outro`
  }

  return (
    <>
      <SectionHeader
        title={`Sugestões`}
      />
      <ContentContainer>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar placeholder='Luciana Matos, Residencial Jardins do Brasil...' onChange={({ target }) => setSearchFilter(target.value)} />
          <Pagination
            localStorageName='tuimbox-list-suggestion-pagination'
            dataLength={suggestions?.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>
        <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5, overflow: 'hidden' }}>
          {/* Head */}
          <Box style={{ width: '100%', display: 'flex' }}>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'status' ? 'underline' : 'none' }}>Status</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'status' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'status', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 170 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'createdAt' ? 'underline' : 'none' }}>Data</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'createdAt' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'createdAt', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 150 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'type' ? 'underline' : 'none' }}>Tipo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'type' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'type', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'name' ? 'underline' : 'none' }}>Nome</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'name' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'name', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 380 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'lockerLocation' ? 'underline' : 'none' }}>Empreendimento</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'lockerLocation' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'lockerLocation', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
          </Box>

          {/* Rows */}
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {sortSuggestions().filter(dataFilter).map((suggestion, index) =>
              <Link
                key={`suggestions_report_${uid()}`}
                to={`/relatorios/sugestoes/${suggestion._id}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={styles.tableRow}>
                  <Box style={{ ...styles.row, width: 200 }}>
                    <StatusLabel
                      background='light'
                      currentStatus={suggestion?.status}
                      dropDownOptions={statusOptions}
                    />
                  </Box>
                  <Box style={{ ...styles.row, width: 170 }}><Text style={{ ...styles.cellText, textAlign: 'center' }}>{formatTimestamp({ timestamp: suggestion.createdAt, showSeconds: false })}</Text></Box>
                  <Box style={{ ...styles.row, width: 150 }}><Text style={{ ...styles.cellText, textAlign: 'center' }}>{getSuggestionType(suggestion?.type)}</Text></Box>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={{ ...styles.cellText, textAlign: 'center' }}>{suggestion?.name || '-'}</Text></Box>
                  <Box style={{ ...styles.row, width: 380 }}><Text style={{ ...styles.cellText, textAlign: 'center' }}>{suggestion?.lockerLocation}</Text></Box>
                </Box>
              </Link>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  )
}

const styles = {
  cellText: {
    fontSize: 14,
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  tableHeadRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tableHeadText: {
    fontSize: '14px',
    color: '#333',
    textAlign: 'center',
    fontFamily: 'UbuntuBold',
    marginLeft: 7
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 45,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#e4e4e4',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 5,
    paddingBottom: 5
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: 'content-box',
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: 'pointer'
    }
  },
}