import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { numberToBRL } from "../../../helpers/Number_to_BRL";
import { updatedFieldsConstructor } from "../../../helpers/updatedFieldsConstructor";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import {
  Box,
  ContentContainer,
  LogList,
  SectionHeader,
  Text,
} from "../../W3libComponents";
import { B2CLabel } from "../../atoms/B2CLabel";
import { TertiaryButton } from "../../organisms/button/Buttons";
import { ContractUpdateDialog } from "../../organisms/dialog/ConfirmationDialog";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { Row } from "../../organisms/table/DetailsRow";
import { Table } from "../../organisms/table/Table";

const tableHeader = [
  { text: "Data e Hora", width: 140 },
  { text: "Valor", width: 50 },
  { text: "ID", width: 70 },
  { text: "Status", width: 50 },
];

const getLockerDescription = (locker) => {
  if (locker) {
    return (
      <div style={{ display: "flex", gap: 8 }}>
        <Text style={styles.regularText}>{locker?.name || "-"}</Text>
        <Link to={{ pathname: `/lockers/${locker?.id}` }}>
          <Box
            sx={{
              ...styles.whatsappIcon,
              backgroundImage: `url('/icons/locker_icon.png')`,
            }}
          />
        </Link>
        <Link
          to={{ pathname: `http://www.tuimbox.com.br/locker/${locker?.id}` }}
          target="_blank"
        >
          <Box
            sx={{
              ...styles.whatsappIcon,
              backgroundImage: `url('/icons/catalog_icon.png')`,
            }}
          />
        </Link>
      </div>
    );
  }
  return `-`;
};

const getLockerAddress = (lockerAddress) => {
  if (lockerAddress) {
    const completeAddress = `${lockerAddress?.rua}, ${lockerAddress?.numero}${
      lockerAddress?.bairro ? ` - ${lockerAddress?.bairro}` : ``
    }`;
    return completeAddress;
  }
  return `-`;
};

const getDoorDescription = (door) => {
  if (door) {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Text>{door?.codLoopBox}</Text>
        <Link to={{ pathname: `/doors/${door?._id}` }} target="_blank">
          <Box
            sx={{
              ...styles.whatsappIcon,
              backgroundImage: `url('/icons/door_icon.png')`,
            }}
          />
        </Link>
      </Box>
    );
  }

  return `-`;
};

export const ContractDetail = ({ context }) => {
  const { id } = useParams();
  const history = useHistory();

  const [contract, setContract] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [updatedFields, setUpdatedFields] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [statistics, setStatistics] = useState({});
  const totalPaid = contract?.payments
    ?.filter((payment) => payment?.status !== "canceled")
    ?.reduce((a, b) => a + b?.value * 1, 0);
  const updatedContractFields = updatedFieldsConstructor(
    updatedFields,
    originalData,
    contract
  );

  const { setLoading, alert, user } = context;
  const { _id, email: adminUserEmail } = user;
  const adminUpdatedFields = updatedFields.length > 0;
  const statusOptions = [
    {
      value: "active",
      display: "Ativo",
      color: Colors.green,
    },
    {
      value: "abandon-error",
      display: "Erro",
      color: Colors.red,
    },
    {
      value: "finished",
      display: "Finalizado",
      color: Colors.background,
    },
  ];

  useEffect(() => {
    fetchContractDetails();
  }, []);

  const fetchContractDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/v3/admin/contract/${id}`);
      document.title = `TuimBox Admin - ${response?.data?.contract?.id_user?.name}`;
      setContract(response.data.contract);
      setOriginalData(response.data.contract);
      setStatistics(response.data.statistics);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;

    setUpdatedFields([...updatedFields.filter((item) => item !== name), name]);
    setContract({
      ...contract,
      [name]: value,
    });
  };

  const getContractIntervalName = () => {
    if (contract.home_key)
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          <B2CLabel />
          <Link
            to={{ pathname: `/homekeys/${contract?.home_key}` }}
            target="_blank"
          >
            <Box
              sx={{
                ...styles.whatsappIcon,
                backgroundImage: `url('/icons/home_key_icon.png')`,
              }}
            />
          </Link>
        </Box>
      );

    if (contract?.subscription) return "Assinatura";
    if (contract?.period?.name) return contract?.period?.name;

    if (contract?.interval === "hourly") return "Por Hora";
    if (contract?.interval === "daily") return "Diário";
    if (contract?.interval === "weekly") return "Semanal";
    if (contract?.interval === "monthly") return "Mensal";

    return "Avulso";
  };

  const getPaymentStatus = (paymentStatus) => {
    let status = "-";
    let border = "#eee";

    if (paymentStatus === "paid") {
      status = "Pago";
      border = Colors.lemonGreen;
    }
    if (paymentStatus === "pending") {
      status = "Pendente";
      border = Colors.red;
    }

    if (paymentStatus === "canceled") {
      status = "Cancelado";
      border = "#ccc";
    }

    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#eee",
          paddingLeft: 5,
          paddingRight: 1,
          borderRadius: 3,
          borderLeft: `6px solid ${border}`,
          borderRight: `6px solid #eee`,
        }}
      >
        <Text
          style={{
            fontSize: 11,
            fontFamily: "UbuntuBold",
            textTransform: "uppercase",
            color: "#888",
          }}
        >
          {status}
        </Text>
      </Box>
    );
  };

  const getVindiId = (idVindi) => {
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Text style={styles.regularText}>{idVindi}</Text>
        {idVindi && (
          <Link
            to={{
              pathname: `${process.env.REACT_APP_VINDI_URL}admin/customers/${idVindi}`,
            }}
            target="_blank"
          >
            <Box
              sx={{
                ...styles.whatsappIcon,
                width: 17,
                height: 17,
                backgroundImage: `url('/icons/vindi_icon.png')`,
              }}
            />
          </Link>
        )}
      </Box>
    );
  };

  const userHasDebtsLabel = () => {
    return (
      <Box
        style={{
          width: 130,
          paddingTop: 3,
          paddingBottom: 2,
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
          backgroundColor: Colors.red,
        }}
      >
        <Text
          style={{
            fontSize: 11,
            fontFamily: "UbuntuBold",
            color: "#fff",
            textTransform: "uppercase",
          }}
        >
          Inadimplente
        </Text>
      </Box>
    );
  };

  const handleChangeContractStatus = async ({ updateDoor, returnDate }) => {
    setLoading(true);
    let formatedReturnDate = "";

    if (returnDate?.active) {
      const date = returnDate?.date?.split("/").reverse().join("-");
      const time = returnDate?.time;
      formatedReturnDate = new Date(`${date}T${time}`);
    }

    const contractData = {
      status: contract.status,
      product_status: contract.status === "active" ? "delivered" : "returned",
      ...(returnDate?.active && { returnDate: formatedReturnDate }),
    };
    try {
      const response = await api.patch(
        `/v3/admin/contract/${id}?updateDoor=${updateDoor}&doorId=${contract?.id_door?._id}`,
        {
          data: contractData,
          updatedFields: updatedContractFields,
          adminUserEmail,
        }
      );
      if (response.status === 200) {
        const alertMsg = updateDoor
          ? `Contrato e porta atualizados com sucesso.`
          : `Contrato atualizado com sucesso.`;
        alert.success(alertMsg);
        await fetchContractDetails();
      }
    } catch (error) {
      alert.error("Não foi possível atualizar este contrato.");
      console.log(error);
      setLoading(false);
    }
  };

  const handleShowLogs = () => {
    setShowLogs(true);
  };

  return (
    <>
      <SectionHeader
        title={contract?.id_user?.name}
        subtitle={statistics.userHasDebts && userHasDebtsLabel()}
        saveButton={adminUpdatedFields}
        saveButtonAction={() => setOpenConfirmationDialog(true)}
        logButton="true"
        logButtonAction={handleShowLogs}
      />
      <Box sx={{ display: "flex", gap: 3 }}>
        <ContentContainer fullWidth>
          <SmallSectionTitle title="Dados do Contrato" />
          <Row
            // rowGap={1}
            data={[
              {
                label: "Status",
                description: (
                  <StatusLabel
                    background="light"
                    currentStatus={contract?.status}
                    dropDown
                    dropDownOptions={statusOptions}
                    dropDownOptionAction={(value) =>
                      handleChange({ target: { name: "status", value } })
                    }
                  />
                ),
              },
              { label: "Aluguel", description: getContractIntervalName() },
              {
                label: "Porta",
                description: getDoorDescription(contract?.id_door),
              },
              {
                label: "Locker",
                description: getLockerDescription(
                  contract?.locker || contract?.id_door?.id_lock
                ),
              },
              {
                label: "Localização",
                description: getLockerAddress(contract?.locker?.address),
              },
              {
                label: "Data de retirada",
                description: formatTimestamp({
                  timestamp: contract?.createdAt,
                }),
              },
              {
                label: "Data de devolução",
                description:
                  contract?.returnDate &&
                  formatTimestamp({ timestamp: contract?.returnDate }),
              },
            ]}
          />
        </ContentContainer>
        <ContentContainer>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              gap: 3,
            }}
          >
            <Text style={{ ...styles.boldText, textAlign: "center" }}>
              {contract?.products?.name}
            </Text>
            <Box
              sx={{
                ...styles.productImg,
                backgroundImage: `url('${contract?.products?.urlImg}')`,
              }}
            />
            <TertiaryButton
              text="Ver Produto"
              width="large"
              onClick={() =>
                history.push(`/products/${contract?.products?._id}`)
              }
            />
          </Box>
        </ContentContainer>
      </Box>
      <Box sx={{ display: "flex", width: "100%", gap: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
            minWidth: 480,
          }}
        >
          <ContentContainer>
            <SmallSectionTitle title="Histórico de Pagamentos" />
            <Table
              size="small"
              header={tableHeader}
              rows={contract?.payments
                ?.sort((a, b) => {
                  return a.paymentDate >= b.paymentDate ? 1 : -1;
                })
                ?.map((payment) => {
                  return {
                    to: `${process.env.REACT_APP_VINDI_URL}admin/bills/${payment.bill_id}`,
                    data: [
                      <Text>
                        {formatTimestamp({
                          timestamp: payment?.paymentDate,
                          showFullYear: false,
                        })}
                      </Text>,
                      <Text>R$ {Number(payment?.value).toFixed(0)}</Text>,
                      <Text>{payment?.bill_id}</Text>,
                      <Text>{getPaymentStatus(payment?.status)}</Text>,
                    ],
                  };
                })}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: `0px 16px`,
                gap: 1,
              }}
            >
              <Box sx={{ display: "flex", gap: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Text>Tuimpoints</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `1px dotted #ddd`,
                    marginBottom: 0.6,
                  }}
                />
                <Box sx={{ display: "flex" }}>
                  <Text>{contract?.usedTuimPoints}</Text>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Box sx={{ display: "flex" }}>
                  <Text bold="true">Total</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    borderBottom: `1px dotted #ddd`,
                    marginBottom: 0.6,
                  }}
                />
                <Box sx={{ display: "flex" }}>
                  <Text bold="true">{numberToBRL(totalPaid)}</Text>
                </Box>
              </Box>
            </Box>
          </ContentContainer>
          <ContentContainer>
            <SmallSectionTitle title="Dados do Cliente" />
            <Row
              data={[
                {
                  label: "Nome",
                  description: (
                    <div style={{ display: "flex", gap: 8 }}>
                      <Text>{contract?.id_user?.name || `-`}</Text>
                      <Link
                        to={{ pathname: `/clients/${contract?.id_user?._id}` }}
                        target="_blank"
                      >
                        <Box
                          sx={{
                            ...styles.whatsappIcon,
                            backgroundImage: `url('/icons/green_user_icon.png')`,
                          }}
                        />
                      </Link>
                      {contract?.id_user?.home_key ? <B2CLabel /> : <></>}
                    </div>
                  ),
                },
                {
                  label: "E-mail",
                  description: contract?.id_user?.email || `-`,
                },
                {
                  label: "Celular",
                  description: (
                    <>
                      {contract?.id_user?.mobileNumber ? (
                        <div style={{ display: "flex", gap: 8 }}>
                          <Text>{contract?.id_user?.mobileNumber || "-"}</Text>
                          <Link
                            to={{
                              pathname: `http://api.whatsapp.com/send/?phone=55${contract?.id_user?.mobileNumber.replace(
                                /\D/g,
                                ""
                              )}`,
                            }}
                            target="_blank"
                          >
                            <Box sx={styles.whatsappIcon} />
                          </Link>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ),
                },
                { label: "CPF", description: contract?.id_user?.cpf },
                { label: "RG", description: contract?.id_user?.rg },
                {
                  label: "ID Vindi",
                  description: contract?.id_user?.idVindi
                    ? getVindiId(contract?.id_user?.idVindi)
                    : `-`,
                },
                {
                  label: "Data de Nascimento",
                  description: formatTimestamp({
                    timestamp: contract?.id_user?.dateBirth,
                    birthday: true,
                  }),
                },
                {
                  label: "Newsletter",
                  description: contract?.id_user?.receberEmails ? "Sim" : "Não",
                },
              ]}
            />
          </ContentContainer>
        </Box>

        <ContentContainer fullWidth>
          <InternalNotes reference={{ type: "Contract", id }} authorId={_id} />
        </ContentContainer>
      </Box>
      <ContractUpdateDialog
        title="Atualização de Status"
        message={`Atenção! Este contrato será alterado permanentemente.`}
        updatedFields={updatedContractFields}
        open={openConfirmationDialog}
        closeDialogAction={() => setOpenConfirmationDialog(false)}
        confirmationButtonText="Atualizar"
        confirmationAction={handleChangeContractStatus}
        rejectionButtonText="Cancelar"
        doorCode={contract?.id_door?.codLoopBox}
        CRLocker={!!contract?.id_door?.id_locker?.boxCode}
      />
      {showLogs ? (
        <LogList id={id} handleClose={() => setShowLogs(false)} />
      ) : (
        <></>
      )}
    </>
  );
};

const styles = {
  boldText: {
    fontSize: 15,
    fontFamily: "UbuntuBold",
  },
  regularText: {
    fontSize: 15,
  },
  whatsappIcon: {
    backgroundImage: `url('/icons/green_square_whatsapp.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 20,
    "&:hover": {
      cursor: "pointer",
      opacity: 0.8,
    },
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  productCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 2,
    padding: 2,
  },
  productCardProductDetails: {
    backgroundColor: "#6b858e24",
    borderRadius: 1,
    width: "100%",
    height: 35,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#6b858e34",
      cursor: "pointer",
    },
  },
  productImg: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: "70%",
    height: "100%",
  },
  confirmationDialogButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.hover,
    },
  },
  confirmationDialogArrow: {
    display: "flex",
    position: "absolute",
    right: 30,
    top: -11,
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: Colors.selectedBackground,
    transform: "rotate(45deg)",
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 155,
    right: 30,
    width: 230,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftColor: "#cb5028",
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: "center",
  },
  confirmationButtonText: {
    fontSize: 13,
    fontWeight: 600,
  },
  deleteButton: {
    backgroundColor: Colors.red,
    padding: 0.35,
    borderRadius: 2,
    paddingLeft: 1,
    paddingRight: 1,
    display: "flex",
    justifyContent: "center",
    "&:hover": { backgroundColor: Colors.red + "dd", cursor: "pointer" },
  },
};
