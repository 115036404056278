import { useEffect, useState } from "react";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { CheckBox, ContentContainer } from "../../W3libComponents";
import { Box, Text, TextField } from "../../atoms";
import { PrimaryButton, SecondaryButton } from "../button/Buttons";
import { Table } from "../table/Table";

export const ConfirmationDialog = (props) => {

   const {
      confirmationAction = () => { },
      confirmationButtonText = 'Sim',
      rejectionAction = () => { },
      rejectionButtonText = 'Não',
      closeDialogAction = () => { },
      title = '',
      message = '',
      open = false
   } = props;

   return (
      <>
         {open && (
            <Box sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundColor: '#00000055',
               position: 'absolute',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%'
            }}
               onClick={closeDialogAction}
            >
               <ContentContainer onClick={(e) => e.stopPropagation()}>
                  <Text title='true' bold='true' center='true'>{title}</Text>
                  <Text style={{ whiteSpace: 'pre' }}>{message}</Text>
                  <Box sx={{ display: 'flex', gap: 1, marginTop: 1 }}>
                     <PrimaryButton
                        text={confirmationButtonText}
                        onClick={() => {
                           confirmationAction()
                           closeDialogAction()
                        }} />
                     <SecondaryButton
                        text={rejectionButtonText}
                        onClick={() => {
                           rejectionAction()
                           closeDialogAction()
                        }} />
                  </Box>
               </ContentContainer>
            </Box>
         )
         }
      </>
   );
};

export const ContractUpdateDialog = (props) => {

   const {
      confirmationAction = () => { },
      confirmationButtonText = 'Sim',
      rejectionAction = () => { },
      rejectionButtonText = 'Não',
      closeDialogAction = () => { },
      title = '',
      message = '',
      open = false,
      updatedFields = [],
      doorCode = '0',
      CRLocker = false
   } = props;

   const [updateDoor, setUpdateDoor] = useState(false)
   const [returnDate, setReturnDate] = useState({ active: false, date: '', time: '' })

   const handleChangeReturnDate = ({ target }) => {
      const { name, value } = target
      let formatedValue = ''

      if (name === 'date') formatedValue = value.replace(/[^\d/\\]/g, '').slice(0, 10).replace(/^(\d{2})\/?/, '$1').replace(/^(\d{2})\/?(\d{1,2})\/?/, '$1/$2').replace(/^(\d{2})\/?(\d{2})\/?(\d{1,4})$/, '$1/$2/$3');
      if (name === 'time') formatedValue = value.replace(/[^\d:\\]/g, '').slice(0, 8).replace(/^(\d{2})\:?/, '$1').replace(/^(\d{2})\:?(\d{1,2})\:?/, '$1:$2').replace(/^(\d{2})\:?(\d{2})\:?(\d{1,2})$/, '$1:$2:$3');

      setReturnDate({
         ...returnDate,
         [name]: formatedValue
      })
   }

   useEffect(() => {

      const today = formatTimestamp({ timestamp: new Date() })?.split(' às ');
      const date = today[0]
      const time = today[1]

      setReturnDate({ ...returnDate, date, time })
   }, [])

   return (
      <>
         {open && (
            <Box sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               backgroundColor: '#00000055',
               position: 'fixed',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               zIndex: 2
            }}
               onClick={closeDialogAction}
            >
               <ContentContainer onClick={(e) => e.stopPropagation()} gap={4}>
                  <Text title='true' bold='true' center='true'>{title}</Text>
                  <Text center='true' style={{ whiteSpace: 'pre' }}>{message}</Text>
                  <Table size='small'
                     header={[
                        { text: 'Campo', width: 100 },
                        { text: 'Valor original', width: 140 },
                        { text: 'Valor atualizado', width: 140 },
                     ]}
                     rows={updatedFields?.map((field, index) => {
                        return {
                           to: null,
                           data: [
                              <Text>{field?.name?.toUpperCase()}</Text>,
                              <Text>{field?.previousValue?.toUpperCase()}</Text>,
                              <Text>{field?.nextValue?.toUpperCase()}</Text>,
                           ]
                        }
                     }
                     )} />
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                     <CheckBox
                        currentValue={returnDate.active}
                        options={[{
                           value: true,
                           display: `Data de Devolução`
                        }]}
                        onClick={(value) => setReturnDate({ ...returnDate, active: value })}
                     />
                     {returnDate.active ?
                        <Box sx={{ display: 'flex', gap: 2 }}>
                           <TextField name='date' value={returnDate?.date} label='Data' onChange={handleChangeReturnDate} />
                           <TextField name='time' value={returnDate?.time} label='Hora' onChange={handleChangeReturnDate} />
                        </Box>
                        :
                        <></>
                     }
                     <CheckBox
                        disabled={CRLocker}
                        disabledInfo={`Este locker trabalha um sistema de portas rotativas. Alterar o status desta porta manualmente poderia causar dano ao fluxo das locações.`}
                        currentValue={updateDoor}
                        options={[{
                           value: true,
                           display: `Alterar status do produto na porta ${doorCode} para "Disponível".`
                        }]}
                        onClick={setUpdateDoor}
                     />
                  </Box>

                  <Box sx={{ display: 'flex', gap: 1, marginTop: 2 }}>
                     <PrimaryButton
                        text={confirmationButtonText}
                        onClick={() => {
                           confirmationAction({ updateDoor, returnDate })
                           closeDialogAction()
                        }} />
                     <SecondaryButton
                        text={rejectionButtonText}
                        onClick={() => {
                           rejectionAction()
                           closeDialogAction()
                        }} />
                  </Box>
               </ContentContainer>
            </Box>
         )
         }
      </>
   );
};