import { Box, SearchBar, Spacer, Text } from "../../W3libComponents";
import { SmallSectionTitle } from "../layout/SmallSectionTitle";

export const Lockers = ({
  doorData,
  lockerData,
  maxHeight,
  handleChangeFilter = () => {},
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 20,
        overflow: "auto",
        ...(maxHeight && { maxHeight }),
      }}
    >
      <Box sx={{ display: "flex" }}>
        <SmallSectionTitle title={`Locker`} />
        <Spacer horizontal size="2" />
        {!doorData?.id_locker?._id && (
          <Box sx={styles.noProduct}>
            <Box
              style={{
                width: 10,
                height: 10,
                backgroundColor: "#d33109",
                borderRadius: "50%",
              }}
            />
            <Spacer horizontal />
            <Text style={{ ...styles.regularText }}>
              Nenhum locker selecionado
            </Text>
          </Box>
        )}
      </Box>
      <Box>
        <SearchBar
          placeholder="Mini029, Residencial Jardins do Brasil..."
          onChange={({ target }) => handleChangeFilter(target.value)}
        />
        <Spacer />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {lockerData}
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  regularText: {
    fontSize: 14,
  },
  noProduct: {
    display: "flex",
    alignItems: "center",
    borderRadius: 1,
  },
};
