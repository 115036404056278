import { useEffect, useReducer } from "react";
import { useSave } from "../../../hooks/useSave";

const reducer = (prev, next) => ({ ...prev, ...next });

export const useRentalPeriods = (initialData) => {
  const [data, setData] = useReducer(reducer, initialData);
  const [url, setUrl] = useReducer(reducer, {
    url: "/v3/rental-periods",
    method: "post",
  });

  const { saveData } = useSave(url);

  const handleChange = ({ target }) => {
    setData({ [target.name]: target.value });
  };

  const reset = () => setData(initialData);

  const save = async () => {
    try {
      if (!data) throw "Você precisa preencher todos os dados obrigatórios";

      const result = await saveData({ data });

      if (result?.status !== 200) throw "Houve um problema do criar o período";
      alert("Tudo certo!\n\nPeríodo salvo com sucesso.");
      reset();
      return { ok: true };
    } catch (error) {
      alert("Desculpe\n\nHouve um problema ao criar o período.");
      return { ok: false, error };
    }
  };

  useEffect(() => {
    if (data?._id)
      setUrl({ url: `/v3/rental-periods/${data._id}`, method: "patch" });
  }, [data?._id]);

  return {
    data,
    handleChange,
    reset,
    save,
  };
};
