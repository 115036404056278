import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { ContentContainer, Pagination, SearchBar, SectionHeader } from "../../W3libComponents";
import { uid } from '../../../helpers/uid_generator';

export const ClientsList = ({ context }) => {

  const { setLoading } = context
  const [clients, setClients] = useState([])
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
  const [pagination, setPagination] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')
  const [filters, setFilters] = useState({
    filterName: 'name',
    filterOrder: 'asc'
  })

  const { filterName, filterOrder } = filters;

  useEffect(() => {
    document.title = `TuimBox Admin - Clientes`
    loadClients()

    if (window.localStorage.getItem('tuimbox-list-clients-filters')) {
      const tuimBoxLocalStorage = JSON.parse(window.localStorage.getItem('tuimbox-list-clients-filters'));
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder
      })
    }
    if (window.localStorage.getItem('tuimbox-list-clients-pagination')) {
      const tuimboxListClientsPagination = JSON.parse(window.localStorage.getItem('tuimbox-list-clients-pagination'));
      setPagination(tuimboxListClientsPagination)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem('tuimbox-list-clients-filters', JSON.stringify({ filterName, filterOrder }));
  }, [filters])

  const loadClients = async () => {
    setLoading(true)
    api.get(`/v3/admin/clients`)
      .then(response => {
        setClients(response.data)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const updateClient = async ({ clientId, data }) => {
    setLoading(true)
    await api.patch(`/v3/admin/clients/${clientId}`, data)
      .then(response => {
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        console.log(error)
      }).finally(async () => await loadClients())
  }

  const sortClients = () => {

    const { filterName, filterOrder } = filters;

    const sortedClients = clients.filter(client => client.name.toLowerCase().includes(searchFilter.toLowerCase()) || client.email.toLowerCase().includes(searchFilter.toLowerCase())).sort((a, b) => {
      // if (filterName === 'codLoopBox' || filterName === 'id_loopbox_porta') {
      //    return (filterOrder === 'desc') ? a[filterName] - b[filterName] : b[filterName] - a[filterName]
      // }
      if (filterName === 'name' || filterName === 'email') {
        if ((String(a?.[filterName]).toLowerCase() || '') > (String(b?.[filterName]).toLowerCase() || '')) return (filterOrder === 'asc') ? 1 : -1
        if ((String(a?.[filterName]).toLowerCase() || '') < (String(b?.[filterName]).toLowerCase() || '')) return (filterOrder === 'asc') ? -1 : 1
        return 0
      } else {
        if (a[filterName] > b[filterName]) return (filterOrder === 'asc') ? 1 : -1
        if (a[filterName] < b[filterName]) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
    })
    return sortedClients
  }

  const handleClientActivation = (event, clientId, active) => {
    event.preventDefault();
    updateClient({
      clientId,
      data: {
        active: !active
      }
    })
  }

  return (
    <>
      <SectionHeader
        title={`Clientes`}
        subtitle={`Ativos: ${clients.filter(i => i.active).length}`}
      />
      <ContentContainer>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar
            placeholder='Marina, marcos@tuim.com.br...'
            onChange={({ target }) => setSearchFilter(target.value)}
          />
          <Pagination
            localStorageName='tuimbox-list-clients-pagination'
            dataLength={clients.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>
        <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5 }}>
          <Box style={{ width: '100%', display: 'flex', }}>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'name' ? 'underline' : 'none' }}>Nome</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'name' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'name', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'email' ? 'underline' : 'none' }}>E-mail</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'email' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'email', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'createdAt' ? 'underline' : 'none' }}>Data do Cadastro</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'createdAt' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'createdAt', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 100 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'active' ? 'underline' : 'none' }}>Ativo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'active' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'active', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', }}>
            {sortClients().filter(searchFilter === '' ? dataFilter : (client, index) => client).map((client, index) =>
              <Link
                key={`clients_list_${uid()}`}
                to={`/clients/${client._id}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={styles.tableRow}>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={styles.cellText}>{client?.name}</Text></Box>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={styles.cellText}>{client?.email || 'Não informado'}</Text></Box>
                  <Box style={{ ...styles.row, width: 200 }}><Text style={styles.cellText}>{formatTimestamp({ timestamp: client?.createdAt, showSeconds: false })}</Text></Box>
                  <Box style={{ ...styles.row, width: 100 }}>
                    <Box sx={{
                      ...styles.checkIcon,
                      zIndex: 99,
                      backgroundImage: client?.active ? `url('/icons/green_check_icon.png')` : `url('/icons/gray_check_icon.png')`,
                      "&:hover": { opacity: 0.8 }
                    }}
                      onClick={(event) => handleClientActivation(event, client._id, client.active)}
                    />
                  </Box>
                </Box>
              </Link>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  )
}

const styles = {
  tableHeadRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  tableHeadText: {
    fontSize: '14px',
    color: '#333',
    textAlign: 'center',
    fontFamily: 'UbuntuBold',
    marginLeft: 7
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: 'content-box',
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: 'pointer'
    }
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#e4e4e4',
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8
  },
  cellText: {
    fontSize: 14,
    textAlign: 'center',
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  dropDownIcon: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 12,
    height: 12,
    marginLeft: 2
  },
  paginationDropDownOptionsContainer: {
    position: 'absolute',
    width: 94,
    left: 0,
    top: 32,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#eee',
    zIndex: 9999999
  },
  paginationDropDownOptions: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 0.5,
    paddingBottom: 0.5,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  paginationButtonContainer: {
    position: 'relative',
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    transform: 'rotate(-90deg)'
  },
  paginationDropDownContainer: {
    position: 'relative',
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ddd',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
}