import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Text } from "../Text";

export const CustomButton = ({
  startText = "Botão",
  endText = "Botão",
  onClick = () => {},
  startIcon: StartIcon = null,
  endIcon: EndIcon = null,
}) => {
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (!done) return;

    const revertDoneFlag = setTimeout(() => {
      setDone(false);
    }, 3000);

    return () => clearTimeout(revertDoneFlag);
  }, [done]);

  return (
    <Button
      onClick={() => {
        onClick();
        setDone(true);
      }}
      startIcon={StartIcon ? done ? <EndIcon /> : <StartIcon /> : null}
      style={{
        padding: `0px 24px`,
        whiteSpace: "nowrap",
        textTransform: "none",
      }}
    >
      <Text>{done ? endText : startText}</Text>
    </Button>
  );
};
