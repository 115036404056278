import { useState } from "react";
import { Box, ChangePasswordDialog, Text, UserBadgeIcon } from "../../W3libComponents";
import { Colors } from "./Colors";
import { uid } from "../../../helpers/uid_generator";

export const Header = ({ context }) => {

   const LineDivider = ({ noGap = false, strong = false }) => <Box style={{ width: '100%', height: '1px', backgroundColor: strong ? Colors.background : Colors.background, ...(!noGap && { marginTop: 10, marginBottom: 10 }), opacity: strong ? 0.2 : 1 }} />;
   const { user, doLogout, statistics } = context
   const { name, permission } = user
   const { totalContracts = 0, totalRevenue = 0, totalDefaulters = 0, usedTuimPoints = 0 } = statistics
   const [showNewPasswordDialog, setShowNewPasswordDialog] = useState(false)
   const [showUserOptions, setShowUserOptions] = useState(false)

   let nameSplit = name.split(' ');
   let firstName = nameSplit[0];

   const today = new Date();
   const monthName = new Intl.DateTimeFormat('pt', { month: 'long' }).format(today);
   const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

   const userIsAdmin = permission?.includes('Administrador')

   return (
      <Box sx={styles.header}>
         {userIsAdmin &&
            <>
               {/* <CurrentMonthData
                  currentMonth={capitalizedMonthName}
                  data={[
                     { value: totalRevenue, label: 'Faturado' },
                     { value: totalContracts, label: 'Contratos' },
                     { value: usedTuimPoints, label: 'Tuimpoints' },
                     { value: totalDefaulters, label: 'Inadimplentes' },
                  ]}
               /> */}



               <HeaderItem
                  itemData={
                     <Box sx={{ display: 'flex', width: '100%' }}>
                        <Box sx={{ display: 'flex', flex: 1 }}>
                           <Text smaller='true' style={{ color: '#888' }}>{`${capitalizedMonthName}:`}</Text>
                        </Box>
                        <Text smaller='true' bold='true' style={{ color: '#888' }}>{totalRevenue}</Text>
                     </Box>
                  }
                  itemHoverData={
                     <>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                           <Box sx={{ display: 'flex', flex: 1 }}>
                              <Text smaller='true' style={{ color: 'inherit' }}>Contratos:</Text>
                           </Box>
                           <Text smaller='true' bold='true' style={{ color: 'inherit' }}>
                              {totalContracts}
                           </Text>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                           <Box sx={{ display: 'flex', flex: 1 }}>
                              <Text smaller='true' style={{ color: 'inherit' }}>Tuimpoints:</Text>
                           </Box>
                           <Text smaller='true' bold='true' style={{ color: 'inherit' }}>
                              {usedTuimPoints}
                           </Text>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                           <Box sx={{ display: 'flex', flex: 1 }}>
                              <Text smaller='true' style={{ color: 'inherit' }}>Inadimplentes:</Text>
                           </Box>
                           <Text smaller='true' bold='true' style={{ color: 'inherit' }}>
                              {totalDefaulters}
                           </Text>
                        </Box>
                     </>
                  }
               />
            </>
         }

         <Box sx={styles.userBadgeContainer}>
            <Box sx={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               width: '100%',
               gap: 1,
               padding: `4px 8px`,
               borderRadius: 1.5,
               boxSizing: 'border-box',
               "&:hover": {
                  opacity: 0.8,
                  cursor: 'pointer'
               }
            }}
               onClick={() => setShowUserOptions(!showUserOptions)}
            >
               <UserBadgeIcon />
               <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}>
                  <Text bold='true'>{firstName}</Text>
               </Box>
               <Box sx={{
                  ...styles.menuIcon,
                  backgroundImage: showUserOptions ? `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`,

               }} />

            </Box>
            {showUserOptions &&
               <>
                  <Box sx={styles.containerUserOpitions}>
                     <Box onClick={() => {
                        setShowUserOptions(!showUserOptions)
                        setShowNewPasswordDialog(true)
                     }} sx={{ borderRadius: 1, padding: `4px 8px`, "&:hover": { backgroundColor: '#ddd', cursor: 'pointer' }, }}>
                        <Text style={{ ...styles.text, textAlign: 'center', }}>Alterar Senha</Text>
                     </Box>
                     <Box onClick={doLogout} sx={{ borderRadius: 1, padding: `4px 8px`, "&:hover": { backgroundColor: '#ddd', cursor: 'pointer' } }}>
                        <Text style={{ ...styles.text, textAlign: 'center' }}>Sair</Text>
                     </Box>
                  </Box>
               </>
            }
         </Box>
         {showNewPasswordDialog && (
            <ChangePasswordDialog
               handleClose={() => setShowNewPasswordDialog(false)}
               title="Alterar Senha"
               confimButtonLabel="Alterar Senha"
               question={"Preencha os campos para alterar a senha:"}
            />
         )}
      </Box >
   );
};

// const CurrentMonthData = ({ currentMonth = '', data = [] }) => {
//    return (
//       <Box sx={{
//          display: 'flex',
//          flex: 1,
//          gap: 1,
//       }}>
//          <Text>{currentMonth}</Text>
//          {data.map(item =>
//             <Box key={uid()} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#00000010', borderRadius: 2, padding: `8px 20px`, flex: 1 }}>
//                <Text bold='true' style={{ color: '#777' }}>{item?.value}</Text>
//                <Text small='true' style={{ color: '#777' }}>{item?.label}</Text>
//             </Box>
//          )}
//       </Box>
//    )
// }

const HeaderItem = ({ itemData, itemHoverData }) => {
   const [showItemHoverData, setShowItemHoverData] = useState(false)
   return (
      <Box sx={{
         display: 'flex',
         position: 'relative',
         padding: `4px 12px`,
         borderRadius: 1.5,
         backgroundColor: Colors.darkerBackground,
         border: `1px solid #ddd`,
         minWidth: 120
      }}
         onMouseOver={() => setShowItemHoverData(true)}
         onMouseLeave={() => setShowItemHoverData(false)}
      >
         {itemData}
         {showItemHoverData ?
            <Box sx={{
               display: 'flex',
               flexDirection: 'column',
               position: 'absolute',
               top: 50,
               left: 0,
               gap: 0.6,
               width: '100%',
               padding: `8px 12px`,
               borderRadius: 1.5,
               backgroundColor: Colors.darkerBackground,
               border: `1px solid #ddd`,
               boxSizing: 'border-box',
               color: '#888'
            }}>
               {itemHoverData}
            </Box>
            :
            <></>
         }
      </Box>
   )
}

const styles = {
   header: {
      display: 'flex',
      width: '100%',
      backgroundColor: Colors.background,
      borderBottom: `1px solid #e7e7e7`,
      padding: `16px 32px`,
      alignItems: 'center',
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
      gap: 1
   },
   text: {
      color: '#888'
   },
   containerUserOpitions: {
      backgroundColor: Colors.darkerBackground,
      borderRadius: 2,
      padding: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: 48,
      width: '100%',
      boxSizing: 'border-box',
   },
   menuIcon: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 13,
      height: 13,
   },
   userBadgeContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: 125,
      gap: 1,
      position: 'relative',
      borderRadius: 1.5
   }
};
