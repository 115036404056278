/*

Props:

- active: Boolean (sets the dialog open or closed);
- type: String [success, error, info];
- title: String;
- message: String;

The progressbar showing the remaining time for the dialog to close automatically
is only available when type is different from 'error'. This behavior guarantees
the user will have enough time to read the whole message. Considering the other
two types being a success or info message, the 'error message' would be the only
one the user would want to read twice or even take a screenshot.

*/

import { useEffect, useState } from "react";
import { Box, Text } from "../../atoms";
import { Colors } from "../layout/Colors";

const getColors = (alertType) => {
   if (alertType === 'success') return {
      backgroundColor: '#f2f3f5',
      progressBarColor: '#31666c',
      borderColor: '#31666c44',
      progressBarBackgroundColor: '#b5c5c8',
      textColor: '#566669',
   }
   if (alertType === 'error') return {
      backgroundColor: '#f5f2f2',
      progressBarColor: Colors.red,
      borderColor: Colors.red + '44',
      progressBarBackgroundColor: '#f5f2f2',
      textColor: '#91604e',
   }
   return {
      backgroundColor: '#f5f3f2',
      progressBarColor: Colors.yellow,
      borderColor: '#71644844',
      progressBarBackgroundColor: '#716448',
      textColor: '#716448',
   }
}

export const Alert = (props) => {

   const defaultTimer = props.type === 'success' ? 3 : 5

   const [timer, setTimer] = useState(defaultTimer)
   const theme = getColors(props.type)

   useEffect(() => setTimer(defaultTimer), [props.active])
   useEffect(() => {
      if (props.type !== 'error') {
         if (props.active && timer > 0) {
            setTimeout(() => setTimer(prev => prev - 0.015), 15)
            return
         }
         props.handleClose()
      }
   }, [timer, props.active])

   return (
      <>
         {props.active ?
            <Box sx={styles.alertContainer}>
               <Box sx={{
                  ...styles.alertContent,
                  backgroundColor: theme.backgroundColor,
                  border: `1px solid ${theme.borderColor}`,
               }}>
                  <Box sx={{ ...styles.progressBarContainer, backgroundColor: theme.progressBarBackgroundColor, }}>
                     <Box sx={{
                        position: 'absolute',
                        width: '100%',
                        height: `${(timer * 100) / defaultTimer}%`,
                        backgroundColor: theme.progressBarColor
                     }} />
                  </Box>
                  <Box sx={styles.innerAlertContent}>
                     <Box sx={styles.alertTitleContainer}>
                        <Text style={{ ...styles.alertTitle, color: theme.textColor }}>
                           {props.title}
                        </Text>
                     </Box>
                     <Box sx={styles.messageContainer}>
                        <Text style={{ ...styles.alertMessage, color: theme.textColor }}>
                           {props.message}
                        </Text>
                     </Box>
                  </Box>
                  <Box
                     onClick={() => props.handleClose()}
                     sx={styles.closeButtonContainer}>
                     <Text style={{ fontSize: 12, color: theme.textColor, fontFamily: 'UbuntuBold' }}>
                        X
                     </Text>
                  </Box>
               </Box>
            </Box>
            :
            <></>
         }
      </>
   )
}

const styles = {
   alertContainer: {
      position: 'relative',
      zIndex: 9999999,
      // top: 0,
      // left: 0,
      // width: '100%',
      // height: '100%',
      // backgroundColor: 'bisque'
      // backgroundImage: `linear-gradient(to top, #ffffff88, #ffffff22, #ffffff22, transparent)`

   },
   alertContent: {
      position: 'fixed',
      top: 20,
      // right: 40,
      right: 0,
      left: 0,
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      width: 400,
      gap: 2,
      padding: 2,
      borderRadius: 3,
      border: `2px solid ${Colors.darkGreen + '10'}`,
      backgroundColor: '#f2f3f5'
   },
   innerAlertContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
   },
   alertTitle: {
      fontSize: 16,
      fontFamily: 'UbuntuBold',
      color: '#555'
   },
   alertMessage: {
      fontSize: 14,
      color: '#555'
   },
   closeButtonContainer: {
      borderRadius: 2,
      padding: `10px`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      "&:hover": {
         cursor: 'pointer'
      }
   },
   alertTitleContainer: {
      width: '100%',
      display: 'flex',
   },
   progressBarContainer: {
      width: 6,
      height: 45,
      borderRadius: 1,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      position: 'relative'
   },
   themeIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%'
   },
   themeOuterContainer: {
      width: 35,
      height: 35,
      borderRadius: '50%',
      backgroundColor: '#f2f3f5',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
   },
   messageContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
   }
}