import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { uid } from "../../../helpers/uid_generator";
import api from "../../../services/api";
import {
  ContentContainer,
  Div,
  SectionHeader,
  Spacer,
  Text,
  TextField,
  UploadBox,
} from "../../W3libComponents";
import { Box } from "../../atoms";
import { CloseIcon } from "../../atoms/icons";
import { SecondaryButton } from "../../organisms/button/Buttons";
import { Colors } from "../../organisms/layout/Colors";
import { DropDown } from "../../organisms/layout/DropDown";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";
import { StatusLabel } from "../../organisms/layout/StatusLabel";
import { ProductPeriodsManager } from "./ProductPeriodsManager";

export const ProductEdit = ({ context }) => {
  const { id } = useParams();
  const { setLoading, alert } = context;
  const history = useHistory();
  const [showDeleteProductPopUp, setShowDeleteProductPopUp] = useState(false);
  const [showPeriod, setShowPeriod] = useState({});
  const [availability, setAvailability] = useState([]);

  const [lockers, setLocker] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState({});
  const [product, setProduct] = useState({
    name: "",
    description: "",
    active: false,
    technicalDescription: "",
    manual: "",
    id_sap: "",
    cost: {
      productCost: 0,
      lossCost: 0,
      lockers: [],
    },
    quantity: 0,
    partner: false,
  });

  const intervalOptions = () => {
    const x = product?.cost?.lockers?.find(
      (locker) => locker?.locker_id === selectedLocker?._id
    );
    const price = x?.intervals;
    return [
      {
        name: "hourly",
        display: "Hora",
        intervalPrice: price
          ? { ...price?.hourly }
          : { first: 0, aditional: 0 },
      },
      {
        name: "daily",
        display: "Dia",
        intervalPrice: price ? { ...price?.daily } : { first: 0, aditional: 0 },
      },
      {
        name: "weekly",
        display: "Semana",
        intervalPrice: price
          ? { ...price?.weekly }
          : { first: 0, aditional: 0 },
      },
      {
        name: "monthly",
        display: "Mês",
        intervalPrice: price
          ? { ...price?.monthly }
          : { first: 0, aditional: 0 },
      },
    ];
  };

  useEffect(() => {
    setLoading(true);

    try {
      api
        .get(`/v3/admin/lockers?select=name,description`)
        .then((response) => {
          setLocker(response.data.lockers);
          setSelectedLocker(response.data.lockers[0]);
        })
        .catch((err) => {
          alert.error("Tivemos um problema ao carregar os lockers.");
        });
    } catch (error) {
      alert.error(
        "Tivemos um problema com o carregamento dos dados do locker."
      );
    }

    if (id === "new") {
      document.title = `TuimBox Admin - Novo Produto`;
      setLoading(false);
      setShowPeriod(intervalOptions()[0]);
      return;
    }

    try {
      api
        .get(`/v3/admin/product/${id}`)
        .then((response) => {
          document.title = `TuimBox Admin - ${response?.data?.product?.name}`;
          setProduct(response.data.product);
          setAvailability(response.data.availability);
          setShowPeriod(intervalOptions()[0]);
        })
        .catch((err) => {
          alert.error("Tivemos um problema ao carregar este produto.");
        });
    } catch (error) {
      alert.error("Tivemos um problema com o carregamento dos dados.");
    } finally {
      setLoading(false);
    }
  }, []);

  // Add locker to product.cost.lockers if it wasn't in the array.
  useEffect(() => {
    // console.log(selectedLocker);
    const selectedLockerData = product.cost.lockers.find(
      (locker) => locker?.locker_id === selectedLocker?._id
    );

    if (!selectedLockerData) {
      const newLockerPrices = {};
      intervalOptions().map((interval) => {
        newLockerPrices[interval.name] = interval.intervalPrice;
      });

      setProduct({
        ...product,
        cost: {
          ...product.cost,
          lockers: [
            ...product.cost.lockers,
            {
              locker_id: selectedLocker?._id,
              intervals: newLockerPrices,
            },
          ],
        },
      });
    }
  }, [selectedLocker]);

  const handleUpload = ({ url, product }) => {
    setProduct({
      ...product,
      urlImg: url,
    });
  };

  const handleUploadGuide = ({ url, product }) => {
    setProduct({
      ...product,
      manual: url,
    });
  };

  const handleDeleteThumb = () => {
    setProduct({ ...product, urlImg: null });
  };

  const handleDeleteGuide = () => {
    setProduct({ ...product, manual: null });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setProduct({
      ...product,
      [name]: value,
    });
  };

  const handleChangeCost = (name, value) => {
    const interval = showPeriod?.name;
    const selectedLockerData = product.cost.lockers.find(
      (locker) => locker?.locker_id === selectedLocker?._id
    );
    const allLockersExceptSelected = product.cost.lockers.filter(
      (locker) => locker?.locker_id !== selectedLocker?._id
    );
    if (value != null) {
      value = value.replace(/\D/, "");
    }

    setProduct({
      ...product,
      cost: {
        ...product.cost,
        ...(name === "lossCost" || name === "productCost"
          ? { [name]: value != null ? value * 1 : null }
          : {
              lockers: [
                ...allLockersExceptSelected,
                {
                  ...selectedLockerData,
                  ...(name === "minPrice"
                    ? { [name]: value != null ? value * 1 : null }
                    : {
                        intervals: {
                          ...selectedLockerData.intervals,
                          [interval]: {
                            ...selectedLockerData.intervals[interval],
                            [name]: value != null ? value * 1 : null,
                          },
                        },
                      }),
                },
              ],
            }),
      },
    });
  };

  const handleSave = async () => {
    // const updatedProductData = {
    //    ...product,
    //    cost: {
    //       ...product.cost,
    //       lockers: [
    //          {
    //             locker_id: '60070daff96ee50017f76a2e',
    //             intervals: { ...product.cost.intervals }
    //          },
    //          {
    //             locker_id: '6218f7fd3ff1a20018091042',
    //             intervals: { ...product.cost.intervals }
    //          },
    //       ]
    //    }
    // };
    // // return
    if (id === "new") {
      handleCreate();
      return;
    }

    setLoading(true);
    await api
      .patch(`/v3/admin/product/${id}`, { product })
      .then((response) => {
        setLoading(false);
        if (response.data.error) {
          alert.error(response.data.error.message);
          return;
        }
        alert.success("Produto salvo com sucesso!");
        // history.push(`/products`)
      })
      .catch((err) => {
        console.log(err.response.data);
        alert.error("Desculpe. Não foi possível salvar o produto.");
        setLoading(false);
      });
  };

  const handleCreate = () => {
    setLoading(true);
    api
      .post(`/v3/admin/product`, { product })
      .then((response) => {
        alert.success("Produto criado com sucesso!");
        history.push(`/products`);
        setLoading(false);
      })
      .catch((err) => {
        alert.error("Desculpe. Não foi possível salvar o produto.");
        setLoading(false);
      });
  };

  const handleChangeActivation = () => {
    setProduct({
      ...product,
      active: !product.active,
    });
  };

  const handleChangePartnership = () => {
    setProduct({
      ...product,
      partner: !product.partner,
    });
  };

  const handleDeleteProduct = async () => {
    setLoading(true);
    await api
      .delete(`/v3/admin/product/${id}`)
      .then((response) => {
        alert.success("Produto excluído com sucesso!");
        setLoading(false);
        history.push(`/products`);
      })
      .catch((error) => {
        alert.error("Desculpe. Não foi possível excluir o produto.");
        setLoading(false);
      });
  };

  const price = intervalOptions()?.find(
    (interval) => interval?.name === showPeriod?.name
  )?.intervalPrice;
  const minPrice = product?.cost?.lockers?.find(
    (locker) => locker?.locker_id === selectedLocker?._id
  )?.minPrice;

  const intervalHasAditionalPrice = typeof price?.aditional !== "undefined";
  const showContent = id === "new" || product?._id;

  if (!showContent) return <></>;

  return (
    <>
      <SectionHeader
        title={product?.name || (id === "new" && "Novo Produto")}
        subtitle={`Produto ${product.active ? "Ativo" : "Inativo"} (${
          product.partner ? "Parceiro" : "Não parceiro"
        })`}
        saveButton
        deleteButton
        saveButtonAction={handleSave}
        deleteButtonAction={() => setShowDeleteProductPopUp(true)}
      />

      {/* 
      <ContentContainer>
        <DropDown
          options={lockers}
          currentValue={selectedLocker}
          onClick={setSelectedLocker}
        />

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 12,
            backgroundColor: "lightblue",
          }}
        >
          Periods
          {periods.map((period) => (
            <span key={period._id}>
              {period.name} - {period.duration}
            </span>
          ))}
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 12,
            backgroundColor: "lightblue",
          }}
        >
          productLockerPeriods
          {productLockerPeriods.map((item) => (
            <span key={item._id}>
              {item.periodId.name} - {item.periodId.duration} | {item.first},{" "}
              {item.additional}
            </span>
          ))}
        </Box>
      </ContentContainer> */}

      <Box sx={{ display: "flex", gap: 3 }}>
        <Box
          sx={{ display: "flex", flexDirection: "column", width: 280, gap: 3 }}
        >
          <ContentContainer fullWidth>
            <SmallSectionTitle title="Imagem" />
            {!product.urlImg && (
              <UploadBox
                sx={{
                  display: "flex",
                  border: "1px dashed lightgray",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 2,
                }}
                handleUpload={({ url }) => handleUpload({ product, url })}
                data={product}
                preview={true}
              >
                <p style={{ fontSize: 14, color: "#444" }}>Arraste para cá</p>
              </UploadBox>
            )}
            {product?.urlImg && (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  maxHeight: 200,
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="small"
                  onClick={handleDeleteThumb}
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    backgroundColor: "#e1e1e1",
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>

                <img
                  src={product?.urlImg}
                  alt="Imagem do Produto"
                  style={{
                    height: "90%",
                    width: "75%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            )}
          </ContentContainer>
          <ContentContainer>
            <SmallSectionTitle title={`Manual`} />
            {!product.manual && (
              <>
                <Box
                  sx={{
                    marginTop: 0,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UploadBox
                    handleUpload={({ url, name }) =>
                      handleUploadGuide({ product, url })
                    }
                    data={product}
                    sx={{
                      display: "flex",
                      border: "1px dashed lightgray",
                      height: 95,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 2,
                    }}
                  >
                    <p style={{ fontSize: 14, color: "#444" }}>
                      Arraste para cá
                    </p>
                  </UploadBox>
                </Box>
              </>
            )}
            {product.manual && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <a
                  href={product.manual}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <img src="/pdf.svg" alt="manual" width={50} />
                    <Text style={{ fontSize: 14, marginLeft: 20 }}>
                      Ver Manual
                    </Text>
                  </Box>
                </a>
                <Div
                  style={{ justifyContent: "flex-end", alignItems: "center" }}
                >
                  <IconButton
                    size="small"
                    onClick={handleDeleteGuide}
                    style={{ backgroundColor: "#e1e1e1", marginLeft: 5 }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Div>
              </Box>
            )}
          </ContentContainer>

          <ContentContainer>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box
                sx={styles.activeContainer}
                onClick={() => handleChangeActivation()}
              >
                <Box
                  sx={{
                    ...styles.checkIcon,
                    backgroundImage: product.active
                      ? `url('/icons/green_check_icon.png')`
                      : `url('/icons/gray_check_icon.png')`,
                  }}
                />
                <Spacer horizontal />
                <Box sx={styles.activeProductTextContainer}>
                  <Text style={styles.regularText}>
                    {product.active ? `Produto Ativo` : `Produto Inativo`}
                  </Text>
                </Box>
              </Box>
              <Box
                sx={styles.activeContainer}
                onClick={() => handleChangePartnership()}
              >
                <Box
                  sx={{
                    ...styles.checkIcon,
                    width: 30,
                    height: 30,
                    backgroundImage: product.partner
                      ? `url('/icons/green_partner_icon.png')`
                      : `url('/icons/gray_partner_icon.png')`,
                  }}
                />
                <Spacer horizontal />
                <Box sx={styles.activeProductTextContainer}>
                  <Text style={styles.regularText}>
                    {product.partner ? "Parceiro" : "Não parceiro"}
                  </Text>
                </Box>
              </Box>
            </Box>
          </ContentContainer>
        </Box>
        <ContentContainer fullWidth>
          <SmallSectionTitle title="Dados do Produto" />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <TextField
              variant="standard"
              label="Nome"
              refs="name"
              name="name"
              margin="dense"
              value={product.name}
              fullWidth
              autoFocus
              onChange={handleChange}
              InputProps={{ style: { fontSize: 14 } }}
            />
            <Box sx={{ display: "flex" }}>
              <TextField
                variant="standard"
                label="Tamanho"
                refs="designationSize"
                name="designationSize"
                margin="dense"
                value={product?.designationSize || ""}
                fullWidth
                onChange={handleChange}
                InputProps={{ style: { fontSize: 14 } }}
              />
              <Spacer horizontal size="2" />
              <TextField
                variant="standard"
                label="ID SAP"
                refs="id_sap"
                name="id_sap"
                margin="dense"
                value={product.id_sap}
                onChange={handleChange}
                style={{ width: 100 }}
                InputProps={{ style: { fontSize: 14 } }}
              />
            </Box>
            <TextField
              multiline
              // rows={5}
              variant="outlined"
              label="Descrição"
              refs="description"
              name="description"
              margin="dense"
              value={product.description}
              fullWidth
              onChange={handleChange}
              style={{ marginTop: 20 }}
              InputProps={{ style: { fontSize: 14 } }}
            />
            <TextField
              multiline
              // rows={5}
              variant="outlined"
              label="Descrição Técnica"
              refs="technicalDescription"
              name="technicalDescription"
              margin="dense"
              value={product.technicalDescription}
              fullWidth
              onChange={handleChange}
              style={{ marginTop: 20, display: "flex", flex: 1 }}
              InputProps={{ style: { fontSize: 14 } }}
            />
          </Box>
        </ContentContainer>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <ProductPeriodsManager productId={id} />
          <ContentContainer fullWidth>
            <SmallSectionTitle title={`Valores do Produto`} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: 2,
                overflow: "hidden",
                width: "100%",
                padding: `10px 30px`,
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ display: "flex", flex: 1 }}>
                  <div
                    style={{
                      ...styles.iconContainer,
                      backgroundImage: `url('/icons/value_icon.png')`,
                    }}
                  />
                  <Text style={{ ...styles.regularText, marginTop: 3 }}>
                    Valor do Produto:
                  </Text>
                </Box>
                <TextField
                  variant="outlined"
                  refs="productCost"
                  name="productCost"
                  margin="dense"
                  size="small"
                  value={product?.cost?.productCost}
                  onChange={(e) =>
                    handleChangeCost(e.target.name, e.target.value)
                  }
                  sx={{ maxWidth: 90, input: { textAlign: "center" } }}
                  InputProps={{ style: { fontSize: 14, maxHeight: 40 } }}
                />
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <Box sx={{ display: "flex", flex: 1 }}>
                  <div
                    style={{
                      ...styles.iconContainer,
                      backgroundImage: `url('/icons/cart_icon.png')`,
                    }}
                  />
                  <Text style={{ ...styles.regularText, marginTop: 3 }}>
                    Valor de Venda:
                  </Text>
                </Box>
                <TextField
                  variant="outlined"
                  refs="lossCost"
                  name="lossCost"
                  margin="dense"
                  size="small"
                  value={product?.cost?.lossCost}
                  onChange={(e) =>
                    handleChangeCost(e.target.name, e.target.value)
                  }
                  sx={{
                    maxWidth: 90,
                    padding: 0,
                    input: { textAlign: "center" },
                  }}
                  InputProps={{ style: { fontSize: 14, maxHeight: 40 } }}
                />
              </Box>
            </Box>
          </ContentContainer>
        </Box>
      </Box>

      <ContentContainer fullWidth>
        <Availability availability={availability} />
      </ContentContainer>

      {showDeleteProductPopUp && (
        <DeleteProductConfirmation
          handleDeleteProduct={async () => {
            setShowDeleteProductPopUp(false);
            await handleDeleteProduct();
            history.push(`/products/`);
          }}
          handleClosePopUp={() => setShowDeleteProductPopUp(false)}
        />
      )}
    </>
  );
};

const statusOptions = [
  {
    value: "available",
    display: "Disponível",
    color: Colors.green,
  },
  {
    value: "delivered",
    display: "Alugado",
    color: Colors.red,
  },
  {
    value: "access_code_expired",
    display: "Código expirado",
    color: Colors.red,
  },
];

const Availability = ({ availability }) => (
  <>
    <SmallSectionTitle title={`Disponibilidade (${availability.length})`} />
    <Box sx={styles.availabilityContainer}>
      {availability.map((door, index) => (
        <Link
          key={`availability_${uid()}`}
          to={`/doors/${door._id}`}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Box sx={styles.availabilityItem}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Text style={{ ...styles.boldText, width: 45 }}>Porta:</Text>
              <Text style={styles.regularText}>{door.codLoopBox}</Text>
            </Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Text style={{ ...styles.boldText, width: 45 }}>Locker:</Text>
              <Text style={styles.regularText}>{door.id_locker.name}</Text>
            </Box>
            <Box sx={styles.availabilityStatusContainer}>
              <StatusLabel
                background="white"
                currentStatus={door.productStatus}
                dropDownOptions={statusOptions}
              />
            </Box>
          </Box>
        </Link>
      ))}
    </Box>
  </>
);

const DeleteProductConfirmation = ({
  handleDeleteProduct,
  handleClosePopUp,
}) => {
  return (
    <Box sx={styles.confirmationDialogContainer}>
      <Box sx={styles.confirmationDialogArrow} />
      <Text
        style={{ ...styles.boldText, textAlign: "center" }}
      >{`Deseja realmente excluir este produto?`}</Text>
      <Spacer />
      <Text
        style={{
          ...styles.boldText,
          color: "rgb(197 68 26)",
          textAlign: "center",
        }}
      >{`Esta ação é irreversível!`}</Text>
      <Spacer size="2" />
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={styles.confirmationDialogButton} onClick={handleDeleteProduct}>
          <Text style={styles.confirmationButtonText}>SIM</Text>
        </Box>
        <Spacer horizontal />
        <Box sx={styles.confirmationDialogButton} onClick={handleClosePopUp}>
          <Text style={styles.confirmationButtonText}>NÃO</Text>
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  activeContainer: {
    height: 30,
    backgroundColor: Colors.background,
    borderRadius: 2,
    display: "flex",
    alignItems: "center",
    padding: 1,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: "pointer",
    },
  },
  activeProductTextContainer: {
    flex: 1,
    marginRight: 1,
    display: "flex",
    justifyContent: "center",
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  confirmationDialogButton: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: Colors.hover,
    },
  },
  confirmationDialogArrow: {
    display: "flex",
    position: "absolute",
    right: 32,
    top: -11,
    width: 20,
    height: 20,
    backgroundColor: "#fff",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    borderLeftColor: Colors.selectedBackground,
    transform: "rotate(45deg)",
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 150,
    right: 28,
    width: 230,
    backgroundColor: "#fff",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftColor: "#cb5028",
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: "center",
  },
  confirmationButtonText: {
    fontSize: 13,
    fontWeight: 600,
  },
  boldText: {
    fontSize: 14,
    fontFamily: "UbuntuBold",
  },
  regularText: {
    fontSize: 14,
  },
  toggleIntervalButton: {
    width: "80%",
    height: 30,
    borderRadius: 2,
    backgroundColor: Colors.darkGreen + "22",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: "pointer",
    },
  },
  iconContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 25,
    height: 25,
    marginRight: 10,
  },
  availabilityContainer: {
    display: "grid",
    gap: "20px",
    justifyContent: "start",
    gridTemplateColumns: "repeat(4, calc((100% / 4) - 20px))",
    gridTemplateRows: "auto",
    overflow: "auto",
  },
  availabilityItem: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.background,
    padding: 3,
    borderRadius: 2,
    minHeight: 110,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
    },
  },
  availabilityStatusContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    flex: 1,
  },
};
