import { useContext, useEffect, useState } from "react";
import { AppContext } from "../contexts/AppContext";
import api from "../services/api";

export function useFetch({ url, loadingMsg: msg }) {
  const { setLoading, loading: isLoading, alert } = useContext(AppContext);

  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const dataLength = data?.length;

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(url);
      setData(response.data);
    } catch (error) {
      console.log(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  function refreshData() {
    fetchData();
  }

  useEffect(() => {
    if (!Boolean(url)) return;
    fetchData();
  }, [url]);

  return { data, dataLength, error, isLoading, refreshData, alert };
}
