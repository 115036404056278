import { memo, useEffect, useRef, useState } from "react";
import { MdDeleteForever } from 'react-icons/md';
import { Link, useHistory, useParams } from "react-router-dom";
import { Format } from "../../../helpers/Formatter";
import { uid } from '../../../helpers/uid_generator';
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { ContentContainer, SearchBar, SectionHeader } from "../../W3libComponents";
import { Box, Grid, Spacer, Text, TextField } from "../../atoms";
import { B2CLabel } from "../../atoms/B2CLabel";
import { PrimaryButton } from "../../organisms/button/Buttons";
import { InternalNotes } from "../../organisms/internalNotes/internalNotes";
import { Colors } from "../../organisms/layout/Colors";
import { SmallSectionTitle } from "../../organisms/layout/SmallSectionTitle";

export const ClientEdit = ({ context }) => {

  const { setLoading, alert, user } = context
  const { id } = useParams()
  const { _id: authorId } = user
  const history = useHistory()

  const [client, setClient] = useState({})
  const [showDeleteClientPopUp, setShowDeleteClientPopUp] = useState(false)

  const isHomeKeyClient = !!client?.home_key?.home_key;

  useEffect(() => {
    (async () => {
      setLoading(true)
      await loadClient()
      setLoading(false)
    })();
  }, [])

  const loadClient = async () => {
    await api.get(`/v3/admin/user/${id}`)
      .then(response => {
        setClient(response.data)
        document.title = `TuimBox Admin - ${response?.data?.name}`
      }).catch(error => {
        console.log(error)
      })
  }

  const updateClient = async () => {
    setLoading(true)
    await api.patch(`/v3/app/user/${id}`, { active: client.active, home_key: client.home_key })
      .then(async response => {
        setLoading(false)
        alert.success('Cliente atualizado com sucesso!')
      }).catch(error => {
        setLoading(false)
        alert.error('Desculpe. Tivemos um problema na atualização do cliente.')
        console.log(error)
      }).finally(async () => await loadClient())
  }

  const deleteClient = async () => {
    setLoading(true)
    await api.delete(`/v3/admin/clients/${id}`)
      .then(response => {
        setLoading(false)
        alert.success('Cliente excluído com sucesso!')
      }).catch(error => {
        setLoading(false)
        alert.error('Desculpe. Não foi possível excluído o cliente!')
      })
    history.push(`/clients/`)
  }

  const handleClientActivation = () => {
    setClient({
      ...client,
      active: !client.active
    })
  }

  const getPhoneNumber = () => {

    return (
      <Box sx={{ display: 'flex' }}>
        <Text style={styles.regularText}>{client?.mobileNumber || '-'}</Text>
        {client?.mobileNumber &&
          <Link to={{ pathname: `https://api.whatsapp.com/send/?phone=55${client?.mobileNumber.replace(/\D/g, "")}` }} target="_blank">
            <Box sx={styles.whatsappIcon} />
          </Link>
        }
      </Box>
    )
  }

  const getVindiId = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <Text style={styles.regularText}>{client?.idVindi || '-'}</Text>
        {client?.idVindi &&
          <Link to={{ pathname: `${process.env.REACT_APP_VINDI_URL}admin/customers/${client?.idVindi}` }} target="_blank">
            <Box sx={{ ...styles.whatsappIcon, width: 17, height: 17, backgroundImage: `url('/icons/vindi_icon.png')` }} />
          </Link>
        }
      </Box>
    )
  }

  return (
    <>
      <SectionHeader
        title={client?.name}
        saveButton
        deleteButton
        saveButtonAction={() => updateClient()}
        deleteButtonAction={() => setShowDeleteClientPopUp(true)}
      />
      <Box sx={{ display: 'flex', gap: 3 }}>
        <ContentContainer fullWidth>
          <SmallSectionTitle title="Dados do Cliente" />
          <Box sx={styles.clientDataContainer}>
            <Box sx={{ minWidth: 160, display: 'flex', flexDirection: 'column', gap: 0.5 }}>
              <Text style={styles.boldText}>Nome</Text>
              <Text style={styles.boldText}>E-mail</Text>
              <Text style={styles.boldText}>Celular</Text>
              {client?.cpf && <Text style={styles.boldText}>CPF</Text>}
              {client?.passport && <Text style={styles.boldText}>Nº Passaporte</Text>}
              <Text style={styles.boldText}>RG</Text>
              <Text style={styles.boldText}>ID Vindi</Text>
              <Text style={styles.boldText}>Recebe Newsletter?</Text>
              <Text style={styles.boldText}>Data de Nascimento</Text>
              <Text style={styles.boldText}>Data de Cadastro</Text>
              <Text style={styles.boldText}>Chave TuimBox</Text>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Text style={styles.regularText}>{client?.name || '-'}</Text>
                {isHomeKeyClient && <B2CLabel />}
              </Box>
              <Text style={styles.regularText}>{client?.email || '-'}</Text>
              {getPhoneNumber() || '-'}
              {client?.cpf && <Text style={styles.regularText}>{client?.cpf || '-'}</Text>}
              {client?.passport && <Text style={styles.regularText}>{client?.passport || '-'}</Text>}
              <Text style={styles.regularText}>{client?.rg || '-'}</Text>
              {getVindiId() || '-'}
              <Text style={styles.regularText}>{client?.receberEmails ? 'Sim' : 'Não'}</Text>
              <Text style={styles.regularText}>{formatTimestamp({ timestamp: client?.dateBirth, birthday: true }) || '-'}</Text>
              <Text style={styles.regularText}>{formatTimestamp({ timestamp: client?.createdAt, showSeconds: false }) || '-'}</Text>
              <input
                type={'text'}
                placeholder='Não cadastrada'
                style={{ fontSize: 14, fontFamily: 'Ubuntu', outline: 'none', paddingTop: 5, color: '#555', border: 'none' }}
                value={client?.home_key?.home_key ?? (client?.home_key || '')}
                spellcheck={false}
                onChange={({ target }) => setClient({ ...client, home_key: target.value })}
              />
            </Box>
          </Box>
          <Box sx={styles.activeContainer} onClick={() => handleClientActivation()}>
            <Box sx={{ ...styles.checkIcon, backgroundImage: client?.active ? `url('/icons/green_check_icon.png')` : `url('/icons/gray_check_icon.png')`, }} />
            <Spacer horizontal />
            <Box sx={styles.activeUserTextContainer}>
              <Text style={styles.regularText}>{client?.active ? `Cliente ativo` : `Cliente inativo`}</Text>
            </Box>
          </Box>
        </ContentContainer>
        <ContentContainer gap={0}>
          <MemoizedClientContracts clientId={id} />
        </ContentContainer>
      </Box>

      <Box sx={{ display: 'flex', gap: 3, height: 500 }}>
        <ContentContainer gap={0}>
          {client.idVindi && <MemoizedClientPaymentProfiles idVindi={client.idVindi} />}
        </ContentContainer>
        <ContentContainer fullWidth>
          <InternalNotes
            reference={{ type: 'User', id }}
            authorId={authorId}
          />
        </ContentContainer>
      </Box>
      <ContentContainer>
        <MemoizedClientTuimPoints clientId={id} alert={alert} />
      </ContentContainer>
      {showDeleteClientPopUp &&
        <DeleteClientConfirmation
          handleDeleteClient={async () => {
            setShowDeleteClientPopUp(false);
            await deleteClient();
          }}
          handleClosePopUp={() => setShowDeleteClientPopUp(false)}
        />
      }
    </>
  )
}

const ClientContracts = ({ clientId }) => {

  const [clientContracts, setClientContracts] = useState([])
  const [contractsFilter, setContractsFilter] = useState('')

  useEffect(() => {
    (async () => await loadClientContracts())();
  }, [])

  const loadClientContracts = async () => {
    await api.get(`/v3/admin/contract/user/${clientId}?fields=p:id_door.codLoopBox,createdAt,returnDate,status,interval,products.urlImg,products.name`)
      .then(response => {
        setClientContracts(response.data)
      }).catch(error => {
        console.log(error)
      })
  }

  const getContractStatus = (status) => {
    if (status === 'finished') { return { status: 'Finalizado', color: Colors.hover } }
    if (status === 'active') { return { status: 'Ativo', color: '#CCE4CF' } }
    if (status === 'abandon' || status === 'abandon-error') { return { status: 'Cancelado', color: Colors.selectedBackground } }
  }

  const getContractRentalInterval = (contract) => {
    if (contract.interval === 'daily') return 'Diário'
    if (contract.interval === 'weekly') return 'Semanal'
    if (contract.interval === 'monthly') return 'Mensal'
    if (contract.subscription) return 'Assinatura'
    return 'Avulso'
  }

  const sortedClientContracts = () => {
    return clientContracts.filter((contract, index) =>
      contract?.products?.name?.toLowerCase().includes(contractsFilter.toLowerCase()))
      .map((contract, index) =>
        <Grid
          key={`clientContracts_${contract._id}`}
          item
          onClick={() => window.open(`/contracts/${contract._id}`, "_blank")}
          sx={{
            ...styles.productCardContainer,
            borderColor: contract?.status === 'active' ? Colors.darkGreen : '#ddd'
          }}
        >
          <Box sx={{ ...styles.productCardContent, backgroundImage: contract?.products?.urlImg ? `url(${contract?.products?.urlImg})` : 'none' }} />
          <Box sx={styles.clientContractCardData}>
            <Text style={{ ...styles.boldText, textAlign: 'center', }}>{contract?.products?.name}</Text>
            <Spacer />
            <Spacer />
            <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Text style={styles.regularText}>Porta:</Text>
                <Text style={styles.regularText}>Retirada:</Text>
                <Text style={styles.regularText}>Devolução:</Text>
                <Text style={styles.regularText}>Intervalo:</Text>
                <Text style={styles.regularText}>Status:</Text>
              </Box>
              <Box>
                <Text style={styles.regularText}>{contract?.id_door?.codLoopBox}</Text>
                <Text style={styles.regularText}>{formatTimestamp({ timestamp: contract?.createdAt, showTime: false, showFullYear: false })}</Text>
                <Text style={styles.regularText}>{formatTimestamp({ timestamp: contract?.returnDate, showTime: false, showFullYear: false })}</Text>
                <Text style={styles.regularText}>{getContractRentalInterval(contract)}</Text>
                <Text style={styles.regularText}>{getContractStatus(contract?.status).status}</Text>
              </Box>
            </Box>
          </Box>
        </Grid>
      )
  }

  return (
    <>
      <SmallSectionTitle title={`Contratos (${clientContracts.length})`} />
      <Spacer size={3} />
      <SearchBar
        placeholder='Batedeira, aspirador...'
        onChange={({ target }) => setContractsFilter(target.value)}
      />
      <Spacer />
      <Grid container spacing={0} sx={styles.productCardMainContainer}>
        {sortedClientContracts()}
      </Grid>
    </>
  )
}

const ClientTuimPoints = ({ clientId, alert, showSuccess }) => {

  const [clientTuimPoints, setClientTuimPoints] = useState([])
  const [tuimPointsFilter, setTuimPointsFilter] = useState('')
  const [tuimpointId, setTuimpointId] = useState()
  const [showDeleteTuimPointPopUp, setShowDeleteTuimPointPopUp] = useState(false)

  const [editTuimPoint, setEditTuimPoint] = useState({
    active: false,
    mode: '',
    tuimPoint: {}
  })

  useEffect(() => {
    (async () => await loadClientTuimPoints())();
  }, [])

  const loadClientTuimPoints = async () => {
    await api.get(`/v3/admin/${clientId}/tuimpoints/`)
      .then(response => {
        setClientTuimPoints(response.data.tuimPoints)
      }).catch(error => {
        console.log(error)
      })
  }

  const deleteClientTuimPoint = async () => {
    await api.delete(`/v3/admin/tuimpoints/${tuimpointId}`)
      .then(async response => {
        alert.success('TuimPoint excluido com sucesso!')
        setTuimpointId()
      }).catch(error => {
        alert.error('Desculpe. Tivemos um problema ao excluir o TuimPoint.')
        console.log(error)
      }).finally(async () => await loadClientTuimPoints())
  }

  const getTotalTuimPoints = () => clientTuimPoints.reduce((current, next) => current + next.value, 0)

  const sortedClientTuimPoints = () => {
    return clientTuimPoints.filter((tuimpoint, index) =>
      tuimpoint?.description.toLowerCase().includes(tuimPointsFilter.toLowerCase()))
      .map((tuimpoint, index) =>
        <div key={`clientTuimPoints_${uid()}`}>
          <Box
            sx={{ ...styles.clientTuimPointsRowContainer, gap: 0.3, borderTopWidth: index === 0 ? 0 : 1 }}
            onClick={() => setEditTuimPoint({ active: true, mode: 'edit', tuimPoint: tuimpoint })}
          >
            <Text style={{ ...styles.regularText, textAlign: 'center', width: 170, }}>
              {formatTimestamp({ timestamp: tuimpoint?.createdAt, showSeconds: false })}
            </Text>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Text style={styles.regularText}>{tuimpoint.description}</Text>
            </Box>
            <Box sx={{ width: 100, display: 'flex', justifyContent: 'center' }}>
              <Text style={{ ...styles.boldText, color: tuimpoint?.value > 0 ? '#569b02' : '#ff2b00' }}>
                {tuimpoint?.value}
              </Text>
            </Box>
            <Box sx={{ width: 180, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Text style={{ ...styles.regularText, color: tuimpoint?.expires ? tuimpoint?.isValid ? 'initial' : Colors.red : 'initial' }}>
                {tuimpoint?.expires ? tuimpoint?.isValid ? Format.date(tuimpoint?.expiresAt) : 'Expirado' : 'Não expira'}
              </Text>
            </Box>
            <Box sx={{ width: 60, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 22, height: 22, borderRadius: 40, padding: '4px', '&:hover': { backgroundColor: 'lightcoral', transition: 'background .5s', opacity: 0.7, cursor: 'pointer' } }}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setTuimpointId(tuimpoint?.id)
                  setShowDeleteTuimPointPopUp(true)
                }}>
                <MdDeleteForever size={20} />
              </Box>
            </Box>
          </Box>
        </div>
      )
  }

  const saveTuimPoint = async (tuimPoint) => {
    if (editTuimPoint.mode === 'new') {
      await api.post(`/v3/admin/tuimpoints/addpoints`, {
        ...tuimPoint,
        user: clientId
      }).then(response => {
        loadClientTuimPoints()
        alert.success(`TuimPoints adicionados com sucesso!`)
      }).catch(error => {
        alert.error(`Desculpe. Não foi possível adicionar TuimPoints.`)
      })
      return
    }

    const { _id } = tuimPoint;

    await api.patch(`/v3/admin/${_id}/update`, tuimPoint).then(response => {
      loadClientTuimPoints()
      alert.success(`Alteração de TuimPoints feita com sucesso!`)
    }).catch(error => {
      alert.error(`Desculpe. Não foi possível fazer a alteração de TuimPoints.`)
    })
  }

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex' }}>
          <SmallSectionTitle title={`Tuimpoints (${getTotalTuimPoints()})`} />
          <Box
            sx={{ ...styles.whatsappIcon, backgroundImage: `url('/icons/green_add_icon.png')`, }}
            onClick={() => setEditTuimPoint({ active: true, mode: 'new' })}
          />
        </Box>
        <Spacer size="3" />
        <SearchBar
          placeholder='Bônus de locação, Aspirador...'
          onChange={({ target }) => setTuimPointsFilter(target.value)}
        />
        <Spacer />
        <Box sx={{ ...styles.clientTuimPointsHeadContainer, gap: 0.3 }}>
          <Box sx={{ ...styles.clientTuimPointsHeadColumn, width: 170 }}>
            <Text style={styles.boldText}>Data</Text>
          </Box>
          <Box sx={{ ...styles.clientTuimPointsHeadColumn, flex: 1 }}>
            <Text style={styles.boldText}>Descrição</Text>
          </Box>
          <Box sx={{ ...styles.clientTuimPointsHeadColumn, width: 100 }}>
            <Text style={styles.boldText}>Quantidade</Text>
          </Box>
          <Box sx={{ ...styles.clientTuimPointsHeadColumn, width: 180 }}>
            <Text style={styles.boldText}>Validade</Text>
          </Box>
          <Box sx={{ ...styles.clientTuimPointsHeadColumn, width: 60 }} />
        </Box>
        <Box sx={styles.tuimPointsCardMainContainer}>
          {sortedClientTuimPoints()}
        </Box>
      </Box>
      {editTuimPoint.active &&
        <AddTuimPoints
          mode={editTuimPoint.mode}
          selectedTuimPoint={editTuimPoint.tuimPoint}
          handleSave={(tuimPoint) => saveTuimPoint(tuimPoint)}
          handleClose={() => setEditTuimPoint({ active: false })}
        />
      }
      {showDeleteTuimPointPopUp &&
        <DeleteTuimPointConfirmation
          handleDeleteTuimpoint={async () => {
            setShowDeleteTuimPointPopUp(false);
            await deleteClientTuimPoint();
          }}
          handleClosePopUp={() => setShowDeleteTuimPointPopUp(false)}
        />
      }
    </>
  )
}

const ClientPaymentProfiles = ({ idVindi }) => {

  const [clientPaymentProfiles, setClientPaymentProfiles] = useState([])
  const [paymentProfilesFilter, setPaymentProfilesFilter] = useState('')

  useEffect(() => {
    (async () => await loadClientPaymentProfiles())();
  }, [])

  const loadClientPaymentProfiles = async () => {
    await api.get(`/v3/admin/vindi/paymentprofile/${idVindi}`)
      .then(response => {
        setClientPaymentProfiles(response.data.data.payment_profiles)
      }).catch(error => {
        console.log(error)
      })
  }

  const getCardExpirationDate = (expirationDate) => {
    expirationDate = expirationDate.split("T")[0].split("-")
    return `${expirationDate[1]}/${expirationDate[0].slice(2, 4)}`
  }

  const sortedClientPaymentProfiles = () => {
    return clientPaymentProfiles.filter((profile, index) =>
      profile?.card_number_last_four.toLowerCase().includes(paymentProfilesFilter.toLowerCase()))
      .map((profile, index) =>
        <Box key={`clientPaymentProfile_${uid()}`} style={{ marginBottom: 5, position: 'relative', overflow: 'hidden' }}
          onClick={() => { }}>
          <Box style={{ ...styles.cardContainer }}>
            <Box style={{ ...styles.cardContainerEffect }} />
            <Box style={{ width: '100%', flex: 1, display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
              <Box style={{ width: 50, height: 36, backgroundColor: '#ddd', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', borderRadius: 3, overflow: 'hidden' }}>
                <Box style={{ width: 1, height: '100%', backgroundColor: '#ddd' }} />
                <Box style={{ width: '100%', height: 1, backgroundColor: '#ccc', position: 'absolute', top: 18, left: 0 }} />
                <Box style={{ width: 8, height: 10, backgroundColor: '#ddd', position: 'absolute', top: 12, left: 20, borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 2 }} />
                <Box style={{ width: 46, height: 22, position: 'absolute', top: 7, left: -27, borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 4 }} />
                <Box style={{ width: 46, height: 22, position: 'absolute', top: 7, right: -27, borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 4 }} />
              </Box>
              <Box style={{ flex: 1 }} />
              <Text style={{ ...styles.boldText, fontSize: 18, color: '#333' }}>{profile.payment_company.name}</Text>
            </Box>
            <Box sx={{ zIndex: 1 }}>
              <Box style={{ alignSelf: 'center', marginBottom: 1 }}>
                <Text style={{
                  fontSize: 16,
                  letterSpacing: 4,
                  textAlign: 'center',
                  color: '#333'
                }}>
                  {profile.card_number_first_six.slice(0, 4)}  ****  ****  {profile.card_number_last_four}
                </Text>
              </Box>

              <Box style={{ marginTop: 10, alignSelf: 'flex-start', width: 238, display: 'flex', flexDirection: 'row' }}>
                <Box style={{ display: 'flex', flex: 1 }}>
                  <Text style={{
                    fontSize: 14,
                    color: '#333'
                  }}>{profile.holder_name}</Text>
                </Box>
                <Box style={{ display: 'flex' }}>
                  <Text style={{
                    fontSize: 14,
                    marginRight: 2,
                    color: '#333'
                  }}>{getCardExpirationDate(profile.card_expiration)}</Text>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Text style={{ fontSize: 13 }}>Cadastrado em {formatTimestamp({ timestamp: profile.created_at })}</Text>
          </Box>
          <Spacer />
        </Box>
      )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, overflowY: 'auto' }}>
      <SmallSectionTitle title={`Perfis de Pagamento (${clientPaymentProfiles.length})`} />
      <SearchBar
        placeholder='2539...'
        onChange={({ target }) => setPaymentProfilesFilter(target.value)}
      />
      <Box sx={{ overflowX: 'auto', paddingRight: 1, width: 310 }}>
        {sortedClientPaymentProfiles()}
      </Box>
    </Box>
  )
}

const DeleteClientConfirmation = ({ handleDeleteClient, handleClosePopUp }) => {
  return (
    <Box sx={styles.confirmationDialogContainer}>
      <Box sx={styles.confirmationDialogArrow} />
      <Text style={{ ...styles.boldText, textAlign: 'center' }}>{`Deseja realmente excluir este cliente?`}</Text>
      <Spacer />
      <Text style={{ ...styles.boldText, color: 'rgb(197 68 26)', textAlign: 'center' }}>{`Esta ação é irreversível!`}</Text>
      <Spacer size="2" />
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Box sx={styles.confirmationDialogButton} onClick={handleDeleteClient}>
          <Text style={styles.confirmationButtonText}>SIM</Text>
        </Box>
        <Spacer horizontal />
        <Box sx={styles.confirmationDialogButton} onClick={handleClosePopUp}>
          <Text style={styles.confirmationButtonText}>NÃO</Text>
        </Box>
      </Box>
    </Box>
  )
}

const DeleteTuimPointConfirmation = ({ handleDeleteTuimpoint, handleClosePopUp }) => {
  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#00000033',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Box sx={{
        width: 230,
        backgroundColor: '#fff',
        borderLeft: `10px solid ${Colors.red}`,
        borderRadius: 2,
        padding: 4,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}>
        <Text style={{ ...styles.boldText, textAlign: 'center', marginBottom: 20 }}>{`Deseja realmente excluir o TuimPoint do cliente?`}</Text>
        <Text style={{ ...styles.boldText, color: 'rgb(197 68 26)', textAlign: 'center' }}>{`Esta ação é irreversível!`}</Text>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={styles.confirmationDialogButton} onClick={handleDeleteTuimpoint}>
            <Text style={styles.confirmationButtonText}>SIM</Text>
          </Box>
          <Spacer horizontal />
          <Box sx={styles.confirmationDialogButton} onClick={handleClosePopUp}>
            <Text style={styles.confirmationButtonText}>NÃO</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const AddTuimPoints = (props) => {

  const {
    selectedTuimPoint,
    mode = '',
    handleSave,
    handleClose,
  } = props;

  const [tuimPoint, setTuimPoint] = useState({
    description: '',
    value: '',
    createdAt: ''
  })

  useEffect(() => {
    mode === 'edit' && setTuimPoint(selectedTuimPoint)
  }, [])

  const handleChange = ({ target }) => {

    const { name, value } = target;

    setTuimPoint({
      ...tuimPoint,
      [name]: value
    })
  }

  return (
    <Box sx={styles.clientTuimPointsModificationPopUp}>
      <Box sx={styles.clientTuimPointsModificationPopUpContent}>
        <Text style={{ ...styles.boldText, textAlign: 'center', fontSize: 18 }}>
          {mode === 'edit' ? 'Alteração de TuimPoints cadastrados' : 'Novo Cadastro de TuimPoints'}
        </Text>
        <Spacer size="3" />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ ...styles.boldText, width: 90 }}>Descrição:</Text>
          <Spacer horizontal />
          <TextField
            name="description"
            placeholder="Descrição"
            size='small'
            InputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { color: '#888' } }}
            value={tuimPoint?.description}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text style={{ ...styles.boldText, width: 90 }}>Pontos:</Text>
          <Spacer horizontal />
          <TextField
            name='value'
            placeholder="Pontos"
            size='small'
            InputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { color: '#888' } }}
            value={tuimPoint?.value}
            onChange={handleChange}
          />
        </Box>
        <Spacer size="1" />

        <Box sx={{ display: 'flex', gap: 2 }}>
          <Text style={{ ...styles.boldText, marginTop: '5px' }}>Expiração:</Text>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <input
              type="date"
              name="expiresAt"
              value={tuimPoint?.expiresAt?.split('T')?.[0]}
              onChange={handleChange}
              style={{ flex: 1, padding: '8.5px 14px', borderRadius: 8, border: `1px solid #bbb`, fontFamily: 'Ubuntu', fontSize: 14, color: '#888' }}
            />
            <Text style={{ color: '#999' }}>* Caso a data de expiração não seja definida, os TuimPoints vão expirar em 30 dias.</Text>
          </Box>
        </Box>
        <Spacer size="3" />
        <PrimaryButton
          text={mode === 'edit' ? 'Atualizar' : 'Cadastrar'}
          width="small"
          onClick={() => {
            handleSave(tuimPoint);
            handleClose();
          }}
        />
        <Spacer />
        <Box
          sx={styles.clientTuimPointsModificationPopUpContentCloseButton}
          onClick={() => handleClose()}
        >
          <Text style={styles.regularText}>
            X
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export const MemoizedClientContracts = memo(ClientContracts);
export const MemoizedClientTuimPoints = memo(ClientTuimPoints);
export const MemoizedClientPaymentProfiles = memo(ClientPaymentProfiles);

const styles = {
  regularText: {
    fontSize: 14
  },
  boldText: {
    fontSize: 14,
    fontFamily: 'UbuntuBold'
  },
  noContracts: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 1,
  },
  filterInput: {
    padding: 10,
    backgroundColor: Colors.lighterBackground,
    border: 'none',
    width: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    borderRadius: 5,
    paddingLeft: 15,
    fontSize: 14
  },
  productCardMainContainer: {
    width: 575,
    height: 280,
    overflowX: 'auto',
    display: 'flex',
  },
  productCardContainer: {
    position: 'relative',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ddd',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 2,
    borderRadius: 3,
    margin: 0.5,
    width: 177,
    height: 270,
    overflow: 'hidden',
    "&:hover": {
      cursor: 'pointer',
      borderWidth: 1,
      borderColor: '#09404766',
    }
  },
  productCardContent: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundOrigin: 'content-box',
    width: 60,
    maxWidth: 60,
    height: '100%',
    maxHeight: '100%',
  },
  clientContractCardData: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  activeContainer: {
    flex: 1,
    backgroundColor: Colors.background,
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
    padding: 1,
    maxHeight: 30,
    "&:hover": {
      backgroundColor: Colors.darkerBackground,
      cursor: 'pointer'
    }
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  whatsappIcon: {
    backgroundImage: `url('/icons/green_square_whatsapp.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  clientDataContainer: {
    display: 'flex',
    maxWidth: 420,
    flex: 1,
  },
  tuimPointsCardMainContainer: {
    position: 'relative',
    width: '100%',
    maxHeight: 290,
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: `1px solid ${Colors.hover}`,
    borderBottom: `1px solid ${Colors.hover}`,
    borderRadius: '0px 0px 5px 5px'
  },
  paymentProfilesMainContainer: {
    overflowX: 'auto',
    backgroundColor: 'lightblue'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 120,
    borderRadius: 10,
    backgroundColor: Colors.darkerBackground,
    padding: 25,
    marginBottom: 8,
    position: 'relative',
    overflow: 'hidden',
  },
  cardContainerEffect: {
    position: 'absolute',
    top: -120,
    left: -210,
    backgroundColor: Colors.background,
    width: 400,
    height: 320,
    borderRadius: 200,
    transform: 'rotate(-35deg)',
    zIndex: 0
  },
  findButton: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 22,
    height: 22,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  confirmationDialogButton: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: 'center',
    "&:hover": {
      cursor: 'pointer',
      backgroundColor: Colors.hover
    }
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 140,
    right: 28,
    width: 230,
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftColor: '#cb5028',
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: 'center'
  },
  confirmationButtonText: {
    fontSize: 13,
    fontWeight: 600
  },
  activeUserTextContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  clientTuimPointsRowContainer: {
    display: 'flex',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: Colors.darkerBackground,
    padding: `8px 16px`,
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: 'pointer'
    }
  },
  clientTuimPointsModificationPopUp: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#00000077',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2
  },
  clientTuimPointsModificationPopUpContent: {
    position: 'relative',
    width: 500,
    backgroundColor: '#fff',
    padding: `32px 56px`,
    paddingTop: 7,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.1
  },
  clientTuimPointsModificationPopUpContentCloseButton: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 35,
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: Colors.lightBackground,
    "&:hover": {
      backgroundColor: Colors.hover,
      cursor: 'pointer'
    }
  },
  clientTuimPointsHeadContainer: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: Colors.background,
    padding: `8px 26px`,
    height: 33,
    borderRadius: '8px 8px 0px 0px',
  },
  clientTuimPointsHeadColumn: {
    display: 'flex',
    justifyContent: 'center',
  }
}
