import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { formatTimestamp } from '../../../helpers/user/formatTimestamp';
import api from '../../../services/api';
import { Box, Text } from "../../atoms";
import { InternalNotes } from '../../organisms/internalNotes/internalNotes';
import { Colors } from '../../organisms/layout/Colors';
import { StatusLabel } from '../../organisms/layout/StatusLabel';
import { Row } from '../../organisms/table/DetailsRow';
import { ContentContainer, SectionHeader } from "../../W3libComponents";

export const SuggestionsDetails = (props) => {

   const { id: suggestionId } = useParams()
   const { context } = props
   const { setLoading, alert, user } = context
   const statusOptions = [
      {
         value: 'aberto',
         display: 'Aberto',
         color: Colors.red
      },
      {
         value: 'pendente',
         display: 'Pendente',
         color: Colors.yellow
      },
      {
         value: 'resolvido',
         display: 'Resolvido',
         color: Colors.green
      }
   ]

   const [suggestion, setSuggestion] = useState({})

   const loadSuggestion = async () => {
      setLoading(true)
      try {
         const response = await api.get(`/v3/admin/suggestion/${suggestionId}`)
         setSuggestion(response.data)
      } catch (error) {
         console.log({
            message: `Error while fetching suggestion (${suggestionId})`,
            error
         })
      } finally {
         setLoading(false)
      }
   }

   const updateSuggestion = async () => {
      setLoading(true)
      try {
         const response = await api.patch(`/v3/admin/suggestion/${suggestionId}`, { status: suggestion.status })
         alert.success(`Sugestão atualizada com sucesso!`)
         await loadSuggestion()
      } catch (error) {
         alert.error(`Desculpe. Não foi possível atualizar a sugestão.`)
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      document.title = `TuimBox Admin - Sugestão`
      loadSuggestion()
   }, [])

   const getSuggestionType = (type = null) => {
      if (type === null) return 'Não informado'

      if (type === 'suggestion') return `Sugestão`
      if (type === 'complaint') return `Reclamação`
      if (type === 'doubt') return `Dúvida`
      return `Outro`
   }

   return (
      <>
         <SectionHeader
            title={`Sugestão`}
            saveButton
            saveButtonAction={updateSuggestion}
         />
         <ContentContainer row>
            <Box sx={{ width: 500 }}>
               <Row
                  data={[
                     {
                        label: 'Status', description: (<StatusLabel
                           background='light'
                           currentStatus={suggestion?.status}
                           dropDown
                           dropDownOptions={statusOptions}
                           dropDownOptionAction={(status) => setSuggestion({ ...suggestion, status })}
                        />)
                     },
                     { label: "Data", description: formatTimestamp({ timestamp: suggestion?.createdAt, showSeconds: false }) },
                     { label: "Empreend.", description: suggestion?.lockerLocation },
                     { label: "Tipo", description: getSuggestionType(suggestion?.type) },
                     { label: "Nome", description: suggestion?.name },
                     { label: "E-mail", description: suggestion?.email },
                     {
                        label: "Telefone", description: (<Box style={{ display: 'flex' }}>
                           <Text style={styles.cellText}>{suggestion?.phone || 'Não informado'}</Text>
                           {suggestion?.phone && <Link to={{ pathname: `https://api.whatsapp.com/send/?phone=55${suggestion?.phone.replace(/\D/g, "")}` }} target="_blank">
                              <Box sx={styles.whatsappIcon} />
                           </Link>}
                        </Box>)
                     },
                     {
                        label: "Comentário", labelPosition: 'flex-start', description: (<Box style={{}}>
                           <Text style={{ ...styles.cellText, height: 'auto' }}>{suggestion?.comments || 'Não informado'}</Text>
                        </Box>)
                     },
                  ]}
               />
            </Box>
            <InternalNotes
               reference={{ type: 'Suggestions', id: suggestionId }}
               authorId={user._id}
            />
         </ContentContainer>
      </>
   )
}

const styles = {
   cellContainer: {
      backgroundColor: '#fff',
      padding: 3,
      borderRadius: 3,
      display: 'flex',
      overflow: 'auto'
   },
   cellText: {
      fontSize: 14,
      marginBottom: 10
   },
   cellTextBold: {
      fontSize: 14,
      fontFamily: 'UbuntuBold',
      marginBottom: 10
   },
   whatsappIcon: {
      backgroundImage: `url('/icons/green_square_whatsapp.png')`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 20,
      height: 20,
      marginLeft: 1,
      "&:hover": {
         cursor: 'pointer'
      }
   }
}