const { useEffect } = require("react");
const { withRouter } = require("react-router-dom");

function ScrollToTop({ history }) {
   useEffect(() => {
      const unlisten = history.listen(() => {
         window.scrollTo({ top: 0, behavior: 'smooth' });
      });
      return () => {
         unlisten();
      }
   }, []);

   return (null);
}

export default withRouter(ScrollToTop);