import { Link } from "react-router-dom";
import { uid } from '../../../helpers/uid_generator';
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import { Box, Grid, Text } from "../../atoms";
import { Colors } from "../layout/Colors";

export const Contracts = (props) => {

   const { contracts = [] } = props;

   return (
      <Grid container sx={{
         overflowX: 'auto',
         display: 'flex',
         gap: 1,
         borderRadius: 2,
         width: '100%',
         maxHeight: 280
      }}>
         {contracts.map(contract =>
            <ContractElement key={`contracts_${uid()}`} contract={contract} />
         )}
      </Grid>
   )
}

const ContractElement = ({ contract }) => {
   return (
      <Link to={`/contracts/${contract._id}`} style={{ textDecoration: 'none' }} target='_blank'>
         <Box sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            padding: 1,
            border: `1px solid ${Colors.darkerBackground}`,
            backgroundColor: '#fff',
            width: 240,
            height: 70,
            borderRadius: 2, "&:hover": { borderColor: '#bbb' }
         }}>
            <Box sx={{ ...styles.imgContainer, marginLeft: 1, backgroundImage: `url(${contract?.products?.urlImg})`, }} />
            <Box sx={{
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
               gap: 0.5,
               flex: 1,
            }}>
               <Box sx={{ display: 'flex', gap: 1 }}>
                  <Box sx={{ ...styles.imgContainer, width: 20, height: 20, backgroundImage: `url('/icons/delivery_icon.png')`, }} />
                  <Text style={styles.smallText}>
                     {formatTimestamp({ timestamp: contract.createdAt, showFullYear: false })}
                  </Text>
               </Box>
               <Box sx={{ display: 'flex', gap: 1, flex: 1 }}>
                  <Box sx={{ ...styles.imgContainer, width: 20, height: 20, backgroundImage: `url('/icons/return_icon.png')`, }} />
                  <Text style={styles.smallText}>
                     {formatTimestamp({ timestamp: contract?.returnDate, showFullYear: false })}
                  </Text>
               </Box>
            </Box>
         </Box>
      </Link>
   )
}

const styles = {
   smallText: {
      fontSize: 13,
   },
   imgContainer: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 45,
      height: 45
   }
}