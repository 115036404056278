import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { Box, Text } from "../../atoms";
import { Colors } from "../../organisms/layout/Colors";
import { ContentContainer, Pagination, SearchBar, SectionHeader } from "../../W3libComponents";
import { uid } from '../../../helpers/uid_generator';

export const ProductList = ({ context }) => {

  const history = useHistory()
  const { setLoading, alert } = context
  const [products, setProducts] = useState([])
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
  const [pagination, setPagination] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')

  const [filters, setFilters] = useState({
    filterName: 'name',
    filterOrder: 'asc'
  })

  const loadProducts = async () => {
    setLoading(true)
    api.get(`/v3/admin/products?fields=id_sap,name,partner,active,createdAt`)
      .then((response) => {
        setLoading(false)
        setProducts(response.data.products);
      })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      })
  }

  const updateProduct = async ({ productId, data }) => {
    setLoading(true)
    api.patch(`/v3/admin/product/${productId}`, { product: data }).then(response => {
      alert.success('Produto atualizado com sucesso!')
      setLoading(false)
    }).catch((err) => {
      alert.error('Desculpe. Não foi possível atualizar o produto.')
      setLoading(false)
    }).finally(async () => await loadProducts());
  }

  useEffect(() => {
    document.title = `TuimBox Admin - Produtos`
    loadProducts()
    if (window.localStorage.getItem('tuimbox-list-products-filters')) {
      const tuimBoxLocalStorage = JSON.parse(window.localStorage.getItem('tuimbox-list-products-filters'));
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder
      })
    }
    if (window.localStorage.getItem('tuimbox-list-products-pagination')) {
      const tuimboxListProductsPagination = JSON.parse(window.localStorage.getItem('tuimbox-list-products-pagination'));
      setPagination(tuimboxListProductsPagination)
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('tuimbox-list-products-filters', JSON.stringify({ filterName, filterOrder }));
  }, [filters])

  const sortProducts = () => {

    const { filterName, filterOrder } = filters;

    const sortedProducts = products?.filter(product =>
      product?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase()) || product?.id_sap?.toLowerCase()?.includes(searchFilter?.toLowerCase()))?.sort((a, b) => {
        if (filterName === 'name') {
          if ((a?.[filterName] || '') > (b?.[filterName] || '')) return (filterOrder === 'asc') ? 1 : -1
          if ((a?.[filterName] || '') < (b?.[filterName] || '')) return (filterOrder === 'asc') ? -1 : 1
          return 0
        } else {
          if (a[filterName] > b[filterName]) return (filterOrder === 'asc') ? 1 : -1
          if (a[filterName] < b[filterName]) return (filterOrder === 'asc') ? -1 : 1
          return 0
        }
      })
    return sortedProducts
  }

  const handleChange = ({ event, id, field, value }) => {
    event.preventDefault();

    updateProduct({
      productId: id,
      data: {
        [field]: !value
      }
    })
  }

  const handleNewProduct = () => history.push('/products/new')

  const { filterName, filterOrder } = filters;

  return (
    <>
      <SectionHeader
        title={`Produtos`}
        subtitle={`Ativos: ${products.filter(i => i.active).length}`}
        newButtonAction={() =>
          handleNewProduct()
        }
        newButton={true}
      />
      <ContentContainer fullWidth>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar placeholder='Aspirador, IV02301...' onChange={({ target }) => setSearchFilter(target.value)} />
          <Pagination
            localStorageName='tuimbox-list-products-pagination'
            dataLength={products.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>
        <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5 }}>
          <Box style={{ width: '100%', display: 'flex', }}>
            <Box style={{ ...styles.tableHeadRow, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'id_sap' ? 'underline' : 'none' }}>ID SAP</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'id_sap' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'id_sap', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'name' ? 'underline' : 'none' }}>Produto</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'name' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'name', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'partner' ? 'underline' : 'none' }}>Parceiro</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'partner' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'partner', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'createdAt' ? 'underline' : 'none' }}>Data de Cadastro</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'createdAt' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'createdAt', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
            <Box style={{ ...styles.tableHeadRow, width: 120 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'active' ? 'underline' : 'none' }}>Ativo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'active' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'active', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              ></Box>
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {sortProducts().filter(searchFilter === '' ? dataFilter : (product, index) => product).map((product, index) =>
              <Link
                key={`products_list_${uid()}`}
                to={`/products/${product._id}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={styles.tableRow}>
                  <Box style={{ ...styles.row, width: 200 }}><Text style={styles.cellText}>{product?.id_sap || 'Não cadastrado'}</Text></Box>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={styles.cellText}>{product?.name}</Text></Box>
                  <Box style={{ ...styles.row, width: 200 }}>
                    <Box sx={{
                      ...styles.checkIcon,
                      width: 30,
                      height: 30,
                      backgroundImage: product?.partner ? `url('/icons/green_partner_icon.png')` : `url('/icons/gray_partner_icon.png')`,
                      "&:hover": { opacity: 0.8 }
                    }}
                      onClick={(event) => handleChange({ event, id: product._id, field: 'partner', value: product.partner })}
                    />
                  </Box>
                  <Box style={{ ...styles.row, width: 200 }}><Text style={styles.cellText}>{formatTimestamp({ timestamp: product?.createdAt, showSeconds: false }) || 'Não informado'}</Text></Box>
                  <Box style={{ ...styles.row, width: 120 }}>
                    <Box sx={{
                      ...styles.checkIcon,
                      zIndex: 99,
                      backgroundImage: product?.active ? `url('/icons/green_check_icon.png')` : `url('/icons/gray_check_icon.png')`,
                      "&:hover": { opacity: 0.8 }
                    }}
                      onClick={(event) => handleChange({ event, id: product._id, field: 'active', value: product.active })}
                    />
                  </Box>
                  {!product?.active &&
                    <Box style={{ width: '100%', height: '100%', backgroundColor: '#ffffffaa', position: 'absolute', top: 0, left: 0 }} />
                  }
                </Box>
              </Link>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  )
}

const styles = {
  root: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  arrowUpIcon: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    marginLeft: 1,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  tableHeadRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  tableHeadText: {
    fontSize: '14px',
    color: '#333',
    textAlign: 'center',
    fontFamily: 'UbuntuBold',
    marginLeft: 7
  },
  tableRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 40,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#e4e4e4',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8
  },
  cellText: {
    fontSize: 14,
    textAlign: 'center',
  },
  cellTextBold: {
    fontSize: 14,
    fontWeight: 600,
  },
  openDoorButton: {
    backgroundColor: '#eee',
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    zIndex: 998,
    "&:hover": {
      backgroundColor: Colors.hover,
      cursor: 'pointer'
    }
  },
  openDoorText: {
    fontSize: 14,
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: 'content-box',
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: 'pointer'
    }
  },
  paginationDropDownContainer: {
    position: 'relative',
    width: 60,
    height: 30,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#ddd',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  dropDownIcon: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 12,
    height: 12,
    marginLeft: 2
  },
  paginationDropDownOptionsContainer: {
    position: 'absolute',
    width: 94,
    left: 0,
    top: 32,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#eee',
    zIndex: 9999999
  },
  paginationDropDownOptions: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#ddd',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  paginationButtonContainer: {
    position: 'relative',
    height: 25,
    width: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  paginationButton: {
    backgroundImage: `url('/icons/gray_arrow_up.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 15,
    height: 15,
    transform: 'rotate(-90deg)'
  },
  confirmationDialogButton: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    height: 40,
    backgroundColor: Colors.lightBackground,
    borderRadius: 2,
    alignItems: 'center',
    "&:hover": {
      cursor: 'pointer',
      backgroundColor: Colors.hover
    }
  },
  confirmationDialogArrow: {
    display: 'flex',
    position: 'absolute',
    right: -11,
    top: 60,
    width: 20,
    height: 20,
    backgroundColor: '#fff',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    borderTopColor: Colors.selectedBackground,
    borderRightStyle: 'solid',
    borderRightWidth: 1,
    borderRightColor: Colors.selectedBackground,
    transform: 'rotate(45deg)'
  },
  confirmationDialogContainer: {
    zIndex: 10000,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: -310,
    width: 240,
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.selectedBackground,
    borderLeftWidth: 10,
    borderRadius: 2,
    padding: 2.5,
    alignItems: 'center'
  }
}