import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { uid } from '../../../helpers/uid_generator';
import api from '../../../services/api';
import { ContentContainer, Grid, SectionHeader } from '../../W3libComponents';
import { Voucher } from '../../organisms/voucher/voucher';

export const ListVouchers = ({ context }) => {

  const { alert } = context
  const history = useHistory()
  const [allVouchers, setAllVouchers] = useState([])

  const getAllVouchers = async () => {
    const allVouchers = await api.get(`/v3/admin/voucher/list`)
    if (allVouchers?.status === 200) {
      setAllVouchers(allVouchers.data)
      return
    }
    alert.error(`Não foi possível carregar os vouchers.`)
  }

  const updateVoucher = async (voucherId, updatedFields) => {
    const updatedVoucher = await api.patch(`/v3/admin/voucher/${voucherId}`, updatedFields)
    if (updatedVoucher?.status === 200) {
      alert.success(`Voucher atualizado com sucesso!`)
      await getAllVouchers()
      return
    }
    alert.error(`Não conseguimos atualizar o voucher.`)
  }

  useEffect(() => {
    document.title = 'TuimBox Admin - Vouchers'
    getAllVouchers()
  }, [])

  return (
    <>
      <SectionHeader
        title={`Vouchers`}
        subtitle={`Ativos: ${allVouchers.filter(voucher => voucher.active).length}`}
        newButton
        newButtonAction={() => history.push('/vouchers/new')}
      />
      <ContentContainer>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          sx={styles.voucherContainer}
        >
          {allVouchers.map(voucher =>
            <Voucher
              key={`voucher_list_${uid()}`}
              voucher={voucher}
              updateVoucherAction={() => updateVoucher(voucher._id, { active: !voucher.active })}
            />
          )}
        </Grid>
      </ContentContainer>
    </>
  )
}

const styles = {
  voucherContainer: {
    display: 'flex',
    gap: 2,
    overflow: 'hidden',
  }
}