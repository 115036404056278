import { useEffect, useState } from "react"
import { Box, Text } from "../../atoms"
import { Colors } from "./Colors"

/*
- dropDownOptions are always needed even if dropDown are set to false.
- dropDownOptions model: 
   {
      value: 'active', // real field value
      display: 'Ativo', // how the current value will be displayed
      color: olors.green // value's border color
   }
*/

export const StatusLabel = (props) => {

   const {
      currentStatus = '',
      background = 'light',
      dropDown = false,
      dropDownOptions = [],
      dropDownOptionAction = () => { }
   } = props

   const [showDropDownOptions, setShowDropDownOptions] = useState(false)
   const [color, setColor] = useState('#eee')
   const [text, setText] = useState('')

   const getBackgroundColor = () => {
      if (background === 'light') return Colors.background
      if (background === 'white') return '#fff'
      return '#fff'
   }

   useEffect(() => {
      if (currentStatus !== '') {
         const currentOption = dropDownOptions?.find(option => String(option?.value).toLowerCase() === String(currentStatus).toLowerCase())
         setColor(currentOption?.color)
         setText(currentOption?.display)
      }
   }, [currentStatus])

   return (
      <Box style={styles.mainContainer}>
         <Box
            onClick={() => dropDown && setShowDropDownOptions(!showDropDownOptions)}
            sx={{
               ...styles.labelContainer,
               backgroundColor: getBackgroundColor(),
               borderLeft: `8px solid ${color}`,
               ...(dropDown && {
                  "&:hover": {
                     backgroundColor: Colors.hover,
                     cursor: 'pointer'
                  }
               })
            }}>
            <Box style={styles.labelTextContainer}>
               <Text style={styles.regularText}>{text}</Text>
            </Box>
            <Box style={styles.arrowDropDownContainer}>
               {dropDown &&
                  <Box style={{
                     ...styles.iconContainer,
                     backgroundImage: showDropDownOptions ? `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`,
                  }} />
               }
            </Box>
         </Box>

         {showDropDownOptions &&
            <Box style={styles.dropDownOptionsContainer}>
               <Box style={styles.dropDownOptionsDivider}
               />
               {dropDownOptions.map((option, index) =>
                  <Box
                     key={`${index}_dropDownOption_${option.display}`}
                     onClick={() => {
                        setShowDropDownOptions(false)
                        dropDownOptionAction(option.value)
                     }}
                     sx={{
                        ...styles.dropDownCell,
                        backgroundColor: getBackgroundColor(),
                        borderLeft: `8px solid ${option.color}`,
                        ...(dropDown && {
                           "&:hover": {
                              backgroundColor: Colors.hover,
                              cursor: 'pointer'
                           }
                        })
                     }}>
                     <Box style={styles.dropDownOptionTextContainer}>
                        <Text style={styles.regularText}>
                           {option.display}
                        </Text>
                     </Box>
                     <Box style={styles.paddingRight} />
                  </Box>
               )}
            </Box>
         }
      </Box>
   )
}

const styles = {
   mainContainer: {
      marginBottom: 3,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      zIndex: 1
   },
   labelContainer: {
      display: 'flex',
      borderRadius: 1,
      width: 125,
      overflow: 'hidden',
      padding: 0.5,
   },
   regularText: {
      fontSize: 12,
      fontFamily: 'UbuntuBold',
      textTransform: 'uppercase'
   },
   labelTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      marginLeft: 3
   },
   arrowDropDownContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 3,
   },
   iconContainer: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: 13,
      height: 13,
      "&:hover": {
         cursor: 'pointer',
         opacity: 1,
      }
   },
   dropDownOptionsContainer: {
      position: 'absolute',
      top: 0,
      marginTop: 26,
      backgroundColor: '#fff'
   },
   dropDownOptionsDivider: {
      width: '100%',
      height: 1,
      backgroundColor: '#ddd',
      marginTop: 6,
      marginBottom: 6
   },
   dropDownCell: {
      display: 'flex',
      borderRadius: 1,
      width: 125,
      overflow: 'hidden',
      padding: 0.5,
      marginBottom: '2px',
   },
   dropDownOptionTextContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      marginLeft: 3
   },
   paddingRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingRight: 3,
   }
}