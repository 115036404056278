import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import api from "../services/api";

export function useSave({ url, method = "post" }) {
  const { setLoading, loading: isLoading, alert } = useContext(AppContext);

  async function saveData({ data, successMsg = "Dados salvos com sucesso" }) {
    try {
      if (!data || !Boolean(url)) throw "URL ou dados não informados.";
      const apiCall = api[method];

      setLoading(true);
      const response = await apiCall(url, data);
      if (!response.data.ok) throw "Não foi possível salvar os dados.";
      alert.success(successMsg);
      return response;
    } catch (error) {
      const errorMsg = typeof error === "string" ? error : "Houve um problema";
      alert.error(errorMsg);
    } finally {
      setLoading(false);
    }
  }

  return { isLoading, saveData };
}
