import { Colors } from '../organisms/layout/Colors';
import { Box, Text } from '../W3libComponents';
import { uid } from '../../helpers/uid_generator';

export const RadioButtonGroup = (props) => {

   const {
      title = '',
      value = '',
      options = [],
      onClick = () => { },
      vertical = false
   } = props;

   return (
      <Box sx={{
         ...styles.radioGroupContainer,
         ...(vertical && {
            flexDirection: 'column',
            gap: 1.5,
            alignItems: 'flex-start'
         })
      }}>
         <Text style={styles.boldText}>
            {`${title}:`}
         </Text>
         {options.map(option =>
            <Box
               key={`radio_group_${uid()}`}
               sx={styles.radioButtonContainer}>
               <Box
                  onClick={() => onClick(option.value)}
                  sx={styles.outerRadio}>
                  {option.value === value &&
                     <Box sx={styles.innerRadio} />
                  }
               </Box>
               <Text style={styles.regularText}>
                  {option.display}
               </Text>
            </Box>
         )}
      </Box >
   )
}

const styles = {
   regularText: {
      fontSize: 14,
   },
   boldText: {
      fontSize: 14,
      fontFamily: 'Ubuntu-Bold'
   },
   radioGroupContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 0.7,
   },
   radioButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 0.7,
      padding: '0px 5px'
   },
   outerRadio: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 14,
      height: 14,
      borderRadius: '50%',
      border: `2px solid ${Colors.darkGreen}`,
      "&:hover": {
         backgroundColor: Colors.darkGreen + '22',
         cursor: 'pointer'
      }
   },
   innerRadio: {
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: Colors.darkGreen
   }
}