import { Link } from "react-router-dom";
import { Colors } from "../layout/Colors";
import { Box, Text } from "../../W3libComponents";
import { pluralize } from "../../../helpers/pluralize"

const getVoucherValue = (voucher = null) => {
  if (voucher) {
    if (voucher.type === 'addTuimPoints') {
      return (
        <>
          <Text style={{ ...styles.boldText, lineHeight: '80%' }}>
            {voucher?.amount}
          </Text>
          <Text style={{ ...styles.smallBoldText, lineHeight: '100%' }}>
            TUIMPOINTS
          </Text>
        </>
      )
    }
    if (voucher.type === 'discount') {
      return (
        <>
          <Text style={{ ...styles.boldText, lineHeight: '80%' }}>
            R${voucher?.amount}
          </Text>
          <Text style={{ ...styles.smallBoldText, lineHeight: '100%' }}>
            OFF
          </Text>
        </>
      )
    }
  }
  return `-`
}

const AppliedByCounter = ({ appliedByCount }) => (
  <Text style={{ color: '#999', fontSize: 12, padding: `4px 8px 0px 8px` }}>
    {appliedByCount > 0 ? `Aplicado por ${appliedByCount} ${pluralize('usuário', appliedByCount)}.` : ``}
  </Text>);

export const Voucher = (props) => {
  const {
    voucher,
    updateVoucherAction,
    preview = false
  } = props;

  const appliedByCount = voucher?.applied_by?.length;

  return (
    <>
      {preview ?
        <VoucherComponent voucher={voucher} />
        :
        <Box>
          <Link style={{ textDecoration: 'none' }} to={`/vouchers/${voucher._id}`}>
            <VoucherComponent voucher={voucher} updateVoucherAction={updateVoucherAction} />
          </Link>
          <AppliedByCounter appliedByCount={appliedByCount} />
        </Box>
      }
    </>
  )
}

const VoucherComponent = ({ voucher, updateVoucherAction = () => { } }) => (
  <Box sx={{
    ...styles.voucherContentContainer,
    ...(!voucher.active && {
      backgroundImage: `linear-gradient(to bottom right, #00000022, #00000055)`,
    })
  }}>
    <Box sx={styles.leftAreaContainer}>
      <Box sx={styles.piercesContainer}>
        <Box sx={styles.pierce} />
        <Box sx={styles.pierce} />
      </Box>
      <Box sx={styles.leftAreaContent}>
        <Box sx={{
          ...styles.icon,
          marginLeft: 2,
          marginTop: 2,
          "&:hover": {
            ...(!voucher.active ? { filter: 'brightness(80%)' } : { opacity: 0.8 })
          },
          ...(!voucher.active && { backgroundImage: `url('/icons/gray_check_icon.png')` }),
        }}
          onClick={(event) => {
            event.preventDefault()
            updateVoucherAction()
          }}
        />
        <Box sx={styles.voucherDataContainer}>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 0.7 }}>
            {getVoucherValue(voucher)}
          </Box>
          <Box sx={{ padding: `3px 0px`, borderBottom: `1px solid #ccc`, display: 'flex' }}>
            <Text style={styles.smallText}>{voucher?.code}</Text>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box sx={styles.rightAreaContainer}>
      <Box sx={styles.iconsContainer}>
        <Box sx={{
          ...styles.icon,
          padding: 0.7,
          borderRadius: 2,
          backgroundSize: '60%',
          backgroundImage: `url('/icons/gray_voucher_icon.png')`,
          "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f1f1f1'
          }
        }}
          onClick={(event) => {
            event.preventDefault()
            navigator.clipboard.writeText(voucher?.code)
          }}
        />
        <Box sx={{
          ...styles.icon,
          height: 17,
          padding: 1,
          borderRadius: 2,
          backgroundSize: '50%',
          backgroundImage: `url('/icons/gray_url_icon.png')`,
          "&:hover": {
            cursor: 'pointer',
            backgroundColor: '#f1f1f1',
          }
        }}
          onClick={(event) => {
            event.preventDefault()
            navigator.clipboard.writeText(`https://www.tuimbox.com.br/cadastro?voucher=${voucher?.code}`)
          }}
        />
      </Box>
    </Box>
  </Box>
);

const styles = {
  smallText: {
    fontSize: 11,
    textTransform: 'uppercase'
  },
  regularText: {
    fontSize: 14,
  },
  largerText: {
    fontSize: 20,
  },
  boldText: {
    fontSize: 32,
    fontFamily: 'Ubuntu-Bold',
  },
  smallBoldText: {
    fontSize: 14,
    fontFamily: 'Ubuntu-Bold',
  },
  mediumBoldText: {
    fontSize: 16,
    fontFamily: 'Ubuntu-Bold',
  },
  icon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  voucherContentContainer: {
    backgroundColor: Colors.darkerBackground,
    backgroundImage: `linear-gradient(to bottom right, #f3f3f3, #eaeaea, #e3e3e3)`,
    borderRadius: 2,
    display: 'flex',
    flex: 1,
    minWidth: 340,
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    "&:hover": {
      backgroundImage: 'none',
    }
  },
  leftAreaContainer: {
    width: '70%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: `4px dotted #fff`,
    gap: 1,
    position: 'relative',
  },
  piercesContainer: {
    position: 'absolute',
    top: -11,
    right: -11,
    height: 'calc(100% + 22px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  pierce: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: '#fff'
  },
  leftAreaContent: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 1
  },
  voucherDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1.2,
    flex: 1,
    padding: '18px',
  },
  rightAreaContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    gap: 0.3
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0.2
  },
}