import { createContext, useState, useContext } from "react";
import { setCookie, parseCookies, destroyCookie } from 'nookies'
import api from '../services/api'
import { AppContext } from "./AppContext";

const SESSION_COOKIE = 'tuimbox-admin-token';
// Session cookie expires every hour
const SESSION_COOKIE_MAX_AGE = 60 * 60;

// Validates sessions every minute
// const VALIDATE_TIMEOUT = 60 * 1000;

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

   const [user, setUser] = useState()
   const [loadingCurrentUser, setLoadingCurrentUser] = useState(true)
   const [statistics, setStatistics] = useState({})
   const { setLoading } = useContext(AppContext)

   const doLogin = (user, handleError) => {

      const { email, password } = user
      setLoading(true)

      api.post('/v3/admin/login', { email, password }).then(response => {
         const { token, data, statistics, totals } = response.data

         api.defaults.headers['Authorization'] = `Bearer ${token}`;

         setCookie(undefined, SESSION_COOKIE, token, {
            maxAge: SESSION_COOKIE_MAX_AGE
         })

         setUser(data)
         setStatistics({ ...statistics, totals })
         setLoading(false)
      }).catch(err => {
         handleError(err.response)
      })
   }

   const requestUserInformation = async () => {

      const token = await getSessionCookie()
      api.defaults.headers['Authorization'] = `Bearer ${token}`;

      api.get('/v3/admin/account/login').then(response => {
         const { token, user, statistics, totals } = response.data

         api.defaults.headers['Authorization'] = `Bearer ${token}`;

         setCookie(undefined, SESSION_COOKIE, token, {
            maxAge: SESSION_COOKIE_MAX_AGE
         })
         setUser(user)
         setStatistics({ ...statistics, totals })
         setLoadingCurrentUser(false)

      }).catch(err => {
         // console.log('ERRO: ', err)
         setLoadingCurrentUser(false)
      })
   }

   const doLogout = () => {
      destroyCookie(undefined, SESSION_COOKIE)
      delete api.defaults.headers.delete['Authorization']
      setUser(null)
   }

   const getSessionCookie = () => {
      const { [SESSION_COOKIE]: token } = parseCookies()
      return token
   }

   const checkPermission = (permissions) => {

      if (user.permissions.includes('root'))
         return true

      let success = true

      permissions.forEach(item => {
         if (!user.permissions.includes(item)) {
            success = false
         }
      })

      return success
   }

   const context = {
      user,
      doLogin,
      doLogout,
      getSessionCookie,
      loadingCurrentUser,
      requestUserInformation,
      checkPermission,
      statistics
   }

   return (
      <AuthContext.Provider value={context}>
         {children}
      </AuthContext.Provider>
   )
}