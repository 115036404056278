import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import api from "../services/api";

export function useDelete({
  url,
  successMsg = "Documento excluído com sucesso",
}) {
  const { setLoading, loading: isLoading, alert } = useContext(AppContext);

  async function deleteData() {
    try {
      if (!Boolean(url)) throw "URL não informada";

      setLoading(true);
      const response = await api.delete(url);
      if (!response.data.ok) throw "Não foi possível excluir o documento.";
      alert.success(successMsg);
    } catch (error) {
      const errorMsg = typeof error === "string" ? error : "Houve um problema";
      alert.error(errorMsg);
    } finally {
      setLoading(false);
    }
  }

  return { isLoading, deleteData };
}
