import { Box } from "@mui/material"
import { Text } from "../../atoms"
import { Colors } from "./Colors"

export const SmallSectionTitle = ({ title, size = null }) => {

   const small = size === 'small';
   const medium = size === 'medium';

   return (
      <>
         <Box style={{ display: 'flex' }}>
            <Box sx={{ width: 4, backgroundColor: Colors.darkGreen, marginRight: 1, borderRadius: 1 }} />
            <Text
               small={small}
               medium={medium}
               bold='true'
            >
               {title}
            </Text>
         </Box>
      </>
   )
}