import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#094047'
        },
        secondary: {
            main: '#666'
        },
        third: {
            main: '#caee30'
        }
    }
})

export default theme