import { Box } from "@mui/material";
import { Text } from "../../atoms";
import { Colors } from "../layout/Colors";

export const TuimboxButton = (props) => {
  const {
    text,
    width = "fullWidth", // [small, large, full] (default 'flex: 1')
    onClick,
    style,
    disabled = false,
    variant = "primary", // [primary, secondary, tertiary, destructive]
  } = props;

  return (
    <Box
      sx={{
        ...styles.buttonContainer,
        ...(width === "fullWidth" && { width: "100%" }),
      }}
    >
      <Box
        onClick={disabled ? () => {} : onClick}
        sx={{
          color: "#444",
          ...styles[variant],
          ...(width === "small"
            ? { maxWidth: 150 }
            : width === "large"
            ? { minWidth: 150, maxWidth: 250 }
            : { width: "100%" }),
          ...(disabled && {
            backgroundColor: "#ccc",
            "&:hover": {
              cursor: "not-allowed",
            },
          }),
          ...style,
        }}
      >
        <Text
          style={{
            ...styles.regularText,
            color: "inherit",
            ...(disabled && { color: "#777" }),
          }}
        >
          {text}
        </Text>
      </Box>
    </Box>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  primary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `8px 16px 8px 16px`,
    borderRadius: 2,
    width: "100%",
    backgroundColor: Colors.darkGreen,
    border: `1px solid ${Colors.darkGreen}`,
    color: "#fff",
    "&:hover": {
      backgroundColor: Colors.darkGreen + "e8",
      cursor: "pointer",
    },
  },
  secondary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `8px 16px 8px 16px`,
    borderRadius: 2,
    width: "100%",
    backgroundColor: "#d9d9d9",
    border: `1px solid #d9d9d9`,
    "&:hover": {
      backgroundColor: "#d9d9d9da",
      cursor: "pointer",
    },
  },
  tertiary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `8px 16px 8px 16px`,
    borderRadius: 2,
    width: "100%",
    border: `1px solid #ddd`,
    paddingRight: `13px`,
    "&:hover": {
      border: `1px solid #bbb`,
      cursor: "pointer",
    },
  },
  destructive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: `8px 16px 8px 16px`,
    borderRadius: 2,
    width: "100%",
    backgroundColor: Colors.red,
    border: `1px solid ${Colors.red}`,
    color: "#fff",
    "&:hover": {
      backgroundColor: Colors.red + "e8",
      cursor: "pointer",
    },
  },
  regularText: {
    fontSize: 14,
  },
  boldText: {
    fontSize: 14,
    fontWeight: 600,
  },
};
