import api from "../../../services/api";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { Paper, Box, Badge, Table, Text, TableBody, TableCell, TableContainer, TableHead, TableRow, SectionHeader, SectionContainer, TaskFilters } from "../../W3libComponents";
import moment from "moment";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IconButton } from "@mui/material";

export const TaskList = () => {

   const history = useHistory();
   const { setLoading } = useContext(AppContext);
   const [tasks, setTasks] = useState([]);
   const [filteredTasks, setFilteredTasks] = useState([]);
   const [orderBy, setOrderBy] = useState({
      createdAt: 'desc',
      lastInteraction: 'desc'
   })
   // const [createdAtOrder, setCreatedAtOrder] = useState('desc');
   // const [lastInteractionOrder, setLastInteractionOrder] = useState('desc');

   useEffect(() => {
      setLoading(true);
      api
         .get(`/task`)
         .then((response) => {
            setTasks(response.data.tasks);
            setLoading(false);
         })
         .catch((err) => {
            setLoading(false);
         });
   }, []);

   useEffect(async () => {
      if (window.localStorage.getItem('w3lib-admin-orderBy')) {
         const localOrderBy = await JSON.parse(window.localStorage.getItem('w3lib-admin-orderBy'))
         setOrderBy(localOrderBy)
      }
   }, []);

   const handleOrderBy = ({ name, value }) => {
      setOrderBy({
         ...orderBy,
         [name]: value
      })

      window.localStorage.setItem('w3lib-admin-orderBy', JSON.stringify({
         ...orderBy,
         [name]: value
      }))
   }

   // const handleCreatedAtOrder = () => {
   //   setCreatedAtOrder(createdAtOrder === 'desc' ? 'asc' : 'desc')
   //   window.localStorage.setItem('w3lib-admin-createdAtOrder', JSON.stringify(createdAtOrder === 'desc' ? 'asc' : 'desc'));
   // }

   // const handleLastInteractionOrder = () => {
   //   setLastInteractionOrder(lastInteractionOrder === 'desc' ? 'asc' : 'desc')
   //   window.localStorage.setItem('w3lib-admin-lastInteractionOrder', JSON.stringify(lastInteractionOrder === 'desc' ? 'asc' : 'desc'));
   // }

   return (
      <SectionContainer>
         <SectionHeader
            title="Tarefas"
            newButtonAction={() => history.push(`/task/new`)}
            newButton
         />
         <>
            <TaskFilters tasks={tasks} setFilteredTasks={setFilteredTasks} filteredTasks={filteredTasks} orderBy={orderBy} />
            <TableContainer component={Paper}>
               <Table>
                  <TableHead>
                     <TableRow>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> # </TableCell>
                        <TableCell align="left" sx={{ fontWeight: "bold" }}> Titulo </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Autor</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Cliente</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Executor</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Status</TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Data de Criação
                           <IconButton onClick={() => handleOrderBy({ name: 'createdAt', value: orderBy.createdAt === 'asc' ? 'desc' : 'asc' })}>{orderBy.createdAt === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</IconButton>
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}> Úiltima Interação
                           <IconButton onClick={() => handleOrderBy({ name: 'lastInteraction', value: orderBy.lastInteraction === 'asc' ? 'desc' : 'asc' })}>{orderBy.lastInteraction === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</IconButton>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {filteredTasks.map((task, index) => (
                        <TaskListRow task={task} index={index} key={`tasklistrow${index}`} />
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </>
      </SectionContainer>
   );
};


const TaskListRow = ({ task }) => {

   const { user } = useContext(AuthContext);

   const history = useHistory();

   const handleTask = (id) => history.push(`/task/${id}`)

   const notViewedinteractions = task.interactions.filter(item => {
      return !item.viewedBy.includes(user._id)
   }).length

   return (
      <TableRow hover sx={{ cursor: "pointer" }} onClick={() => handleTask(task?._id)}>
         <TableCell align="center">
            <Text>{task?.number}</Text>
         </TableCell>
         <TableCell align="left" aria-label="Titulo">
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>

               <Text>{task?.title}</Text>
               {!task.viewedBy.includes(user._id) && <Badge color='primary' badgeContent={'Nova'} />}
               {notViewedinteractions > 0 && task.viewedBy.includes(user._id) && <Badge color='primary' badgeContent={notViewedinteractions} />}
            </Box>
         </TableCell>
         <TableCell align="center" aria-label="Criador">
            <Text>{task?.createdBy.name}</Text>
         </TableCell>
         <TableCell align="center">
            <Text>{task.company?.name}</Text>
         </TableCell>
         <TableCell align="center" aria-label="Destinatário">
            <Box>
               <Text>
                  {!task?.assignTo && "Não especificado"}
                  {task?.assignTo && task.assignTo.name}
               </Text>
               <Text sx={{
                  color: 'gray',
                  fontSize: 12,
                  cursor: 'pointer',
                  "&:hover": {
                     color: "#093261",
                     "& .Participants": {
                        display: 'block'
                     }
                  },
                  "& .Participants": {
                     display: 'none'
                  }
               }}>
                  {task?.participants.length > 0 ? `+${task?.participants.length} Participantes` : ''}
                  <Box className="Participants">
                     <Paper sx={{
                        textAlign: 'left',
                        padding: 1,
                        marginLeft: 8,
                        color: 'gray',
                        position: 'absolute',
                        minWidth: 200,
                        minHeight: 60,
                     }}>
                        <Text sx={{ fontWeight: "bold", fontSize: 14, marginBottom: 1 }}>Participantes</Text>
                        {task.participants.map(participant => <Text sx={{ fontSize: 12 }}>{participant.name}</Text>)}
                     </Paper>
                  </Box>
               </Text>
            </Box>
         </TableCell>
         <TableCell align="center" aria-label="Status">
            <Text>{task?.status === "open" ? "Aberto" : "Fechado"}</Text>
         </TableCell>
         <TableCell align="center" aria-label="Data de Criação">
            <Text>{moment(task?.createdAt).format('L')}</Text>
         </TableCell>
         <TableCell align="center" aria-label="Última Interação">
            {task.interactions.length > 0 && <Text>{moment(task?.interactions[task?.interactions?.length - 1].createdAt).format('L')}</Text>}
            {task.interactions.length === 0 && <Text>{moment(task?.createdAt).format('L')}</Text>}
         </TableCell>
      </TableRow>
   );
};