import { useState } from "react";
import { Box, Text } from "../../atoms";
import { Colors } from "./Colors";

export const DropDown = (props) => {
  const [showOptions, setShowOptions] = useState(false);

  const {
    options = [],
    currentValue = {},
    onClick = () => {},
    title = "",
    fullWidth = false,
    placeholder = "Selecione uma opção",
  } = props;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        ...(fullWidth && { flex: 1 }),
      }}
    >
      {title && <Text bold="true">{`${title}:`}</Text>}
      <Box sx={styles.dropDownContainer}>
        <Box
          sx={styles.topContainer}
          onClick={() => setShowOptions((prev) => !prev)}
        >
          <Box sx={styles.dropDownTextContainer}>
            {currentValue?.name ? (
              <>
                <Text>{currentValue?.name}</Text>
                <Text
                  style={{ color: "#999" }}
                >{`(${currentValue?.description})`}</Text>
              </>
            ) : (
              <Text style={{ color: "#999" }}>{placeholder}</Text>
            )}
          </Box>
          <Box
            sx={{
              ...styles.arrowContainer,
              ...(showOptions && { transform: "rotate(180deg)" }),
            }}
          />
        </Box>
        {showOptions && (
          <Box
            sx={{
              ...styles.dropDownContainer,
              flexDirection: "column",
              position: "absolute",
              left: 0,
              top: 50,
              width: "100%",
              height: 150,
              backgroundColor: "#fff",
              boxSizing: "border-box",
              overflow: "hidden",
              overflowY: "auto",
              zIndex: 999,
            }}
          >
            {options.map((option, index) => (
              <Box
                key={`${index}_list_lockers_${option?._id}`}
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: 1.5,
                  gap: 1,
                  "&:hover": {
                    backgroundColor: Colors.background,
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  onClick(option);
                  setShowOptions(false);
                }}
              >
                <Text>{option?.name}</Text>
                <Text
                  style={{ color: "#999" }}
                >{`(${option?.description})`}</Text>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const styles = {
  dropDownContainer: {
    display: "flex",
    border: `1px solid #ddd`,
    borderRadius: 2,
    position: "relative",
    flex: 1,
  },
  arrowContainer: {
    backgroundImage: `url('/icons/gray_arrow_down.png')`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 15,
    height: 1,
  },
  dropDownTextContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    gap: 1,
  },
  topContainer: {
    display: "flex",
    width: "100%",
    padding: 1.5,
    borderRadius: 2,
    "&:hover": {
      backgroundColor: Colors.background,
      cursor: "pointer",
    },
  },
};
