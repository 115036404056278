import { Typography } from '@mui/material'
export const Text = (props) => {

  const {
    style,
    className = null,
    bold = false,
    medium = false,
    smaller = false,
    small = false,
    xsmall = false,
    title = false,
    center = false,
  } = props;

  return (
    <Typography
      {...props}
      className={className ?? ''}
      style={{
        color: '#555',
        // color: 'inherit',
        letterSpacing: '-0.10px',
        fontSize: 14,
        fontFamily: 'Ubuntu',
        ...(bold && { fontFamily: 'UbuntuBold' }),
        ...(title && { fontSize: 18 }),
        ...(medium && { fontSize: 16 }),
        ...(smaller && { fontSize: 13 }),
        ...(small && { fontSize: 12 }),
        ...(xsmall && { fontSize: 12 }),
        ...(center && { textAlign: 'center' }),
        ...style
      }} />
  )
}
