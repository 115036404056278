import { useEffect, useRef, useState } from "react";
import { formatTimestamp } from "../../../helpers/user/formatTimestamp";
import api from "../../../services/api";
import { Box, Spacer, Text, TextField } from "../../W3libComponents";
import { PrimaryButton, SecondaryButton } from "../button/Buttons";
import { Colors } from "../layout/Colors";
import { SmallSectionTitle } from "../layout/SmallSectionTitle";

/*
- Reference id: the subject you're writing notes about.
- Reference type: is used not only as an indicator of the
   subject we're writing about, but also the reference
   database will use if you try to populate the reference id field.
   So you need to pass the reference type exactly as the model name.
*/

export const InternalNotes = (props) => {
  const { reference, authorId } = props;
  const { type: referenceType, id: referenceId } = reference;

  const [showNewNoteInput, setShowNewNoteInput] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteNote, setShowDeleteNote] = useState({
    active: false,
    noteId: "",
  });
  const [editingNote, setEditingNote] = useState({
    active: false,
    noteId: "",
    note: "",
  });
  const [newNote, setNewNote] = useState({
    author: authorId,
    note: "",
    reference: {
      type: referenceType,
      id: referenceId,
    },
  });

  const internalNotesContainer = useRef(null);

  useEffect(() => {
    if (
      typeof referenceId === "undefined" ||
      typeof referenceType === "undefined"
    ) {
      return;
    }
    loadNotes();
  }, []);

  useEffect(() => {
    if (showNewNoteInput) {
      setEditingNote({
        active: false,
        noteId: "",
        note: "",
      });
      setShowDeleteNote({
        active: false,
        noteId: "",
      });
      let height = internalNotesContainer.current.scrollHeight;
      internalNotesContainer.current.scroll({
        top: height,
        behavior: "smooth",
      });
    } else {
      setNewNote({
        ...newNote,
        note: "",
      });
    }
  }, [showNewNoteInput]);

  const onChange = ({ target }) => {
    const { value } = target;
    setNewNote({
      ...newNote,
      note: value,
    });
  };

  const loadNotes = async () => {
    setLoading(true);
    try {
      api
        .get(
          `/v3/admin/internalnotes/reference/${referenceId}?referenceType=${referenceType}`
        )
        .then((response) => {
          setNotes(response.data.notes);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log("Error trying to fetch notes");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log("Caught error trying to fetch notes");
    }
  };

  const saveNote = async () => {
    if (
      newNote.note === "" ||
      typeof newNote.author === "undefined" ||
      typeof newNote.reference.type === "undefined" ||
      typeof newNote.reference.id === "undefined"
    ) {
      return;
    }

    try {
      setLoading(true);
      api
        .post("/v3/admin/internalnotes/create", { newNote })
        .then(async (response) => {
          setLoading(false);
          setShowNewNoteInput(false);
          await loadNotes();
          internalNotesContainer.current.scroll({ top: 0, behavior: "smooth" });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log("Error trying to create new note");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log("Caught error trying to create new note");
    }
  };

  const updateNote = () => {
    try {
      setLoading(true);
      api
        .patch(`/v3/admin/internalnotes/edit/${editingNote.noteId}`, {
          note: editingNote.note,
        })
        .then(async (response) => {
          await loadNotes();
          setEditingNote({
            active: false,
            noteId: "",
            note: "",
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          console.log("Error trying to update");
        });
    } catch (error) {
      setLoading(false);
      console.log(error);
      console.log("Caught error trying to update");
    }
  };

  const deleteNote = async (noteId) => {
    try {
      setLoading(true);
      api
        .delete(`/v3/admin/internalnotes/delete/${noteId}`)
        .then(async (response) => {
          setShowDeleteNote({
            active: false,
            noteId: "",
          });
          await loadNotes();
        })
        .catch((error) => {
          console.log("Error trying to delete");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      console.log("Caught error trying to delete");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={styles.mainContainer}>
      <SmallSectionTitle title="Observações Internas" />
      <Box
        ref={internalNotesContainer}
        sx={{
          ...styles.notesContainer,
          //  maxHeight: 400,
        }}
        onClick={() => {
          setShowNewNoteInput(false);
          setShowDeleteNote({
            active: false,
            noteId: "",
          });
          setEditingNote({
            active: false,
            noteId: "",
            note: "",
          });
        }}
      >
        {notes.length > 0 ? (
          <>
            {notes.map((note, index) => (
              <Box
                key={`${index}-internal_notes-${index}`}
                sx={{
                  ...styles.singleNoteContainer,
                  ...(authorId === note.author.id && { alignSelf: "end" }),
                  ...(showDeleteNote.active &&
                    showDeleteNote.noteId === note._id && {
                      border: `1px solid ${Colors.red}`,
                    }),
                }}
              >
                <Box>
                  {editingNote.active && editingNote.noteId === note._id ? (
                    <TextField
                      value={editingNote.note}
                      multiline
                      rows={7}
                      onChange={({ target }) =>
                        setEditingNote({ ...editingNote, note: target.value })
                      }
                      onClick={(event) => event.stopPropagation()}
                      InputProps={{ style: { fontSize: 14 } }}
                    />
                  ) : (
                    <Text
                      sx={{
                        overflowWrap: "break-word",
                        display: "flex",
                        marginBottom: 1,
                      }}
                    >
                      {note.note}
                    </Text>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flex: 1,
                    }}
                  >
                    <Text style={{ fontSize: 13, color: "#999" }}>{`${
                      note?.author.name
                    } em ${formatTimestamp({
                      timestamp: note?.createdAt,
                      showSeconds: false,
                    })}`}</Text>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                  style={
                    authorId === note.author.id
                      ? { justifyContent: "flex-end" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {note.author._id === authorId && (
                    <>
                      {!editingNote.active && (
                        <>
                          <Box
                            sx={{
                              ...styles.iconContainer,
                              backgroundImage: `url('/icons/edit_icon.png')`,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowDeleteNote({
                                active: false,
                                noteId: "",
                              });
                              setEditingNote({
                                active: true,
                                noteId: note._id,
                                note: note.note,
                              });
                            }}
                          />
                          <Spacer horizontal />
                          <Box
                            sx={{
                              ...styles.iconContainer,
                              width: 18,
                              height: 20,
                              backgroundImage: `url('/icons/trash_icon.png')`,
                            }}
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowDeleteNote({
                                active: true,
                                noteId: note._id,
                              });
                            }}
                          />
                          {showDeleteNote.active &&
                            showDeleteNote.noteId === note._id && (
                              <Box sx={{ display: "flex" }}>
                                <Spacer horizontal />
                                <Box
                                  onClick={() => deleteNote(note._id)}
                                  sx={styles.deleteButton}
                                >
                                  <Text style={{ color: "#eee", fontSize: 13 }}>
                                    Excluir definitivamente
                                  </Text>
                                </Box>
                              </Box>
                            )}
                        </>
                      )}
                      {editingNote.active &&
                        editingNote.noteId === note._id && (
                          <>
                            <Spacer horizontal />
                            <Box
                              sx={{
                                ...styles.iconContainer,
                                backgroundImage: `url('/icons/save_icon.png')`,
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                updateNote();
                              }}
                            />
                            <Spacer horizontal />
                            <Box
                              sx={{
                                ...styles.iconContainer,
                                backgroundImage: `url('/icons/x_icon.png')`,
                              }}
                              onClick={(event) => {
                                event.stopPropagation();
                                setEditingNote({
                                  active: false,
                                  noteId: "",
                                  note: "",
                                });
                              }}
                            />
                          </>
                        )}
                    </>
                  )}
                </Box>
              </Box>
            ))}
          </>
        ) : (
          <Box sx={styles.noNotesContainer}>
            <Text style={{ fontSize: 14 }}>Nenhuma observação registrada.</Text>
          </Box>
        )}

        {showNewNoteInput && (
          <Box>
            <Spacer />
            <Box
              sx={{ width: "100%", height: "1px", backgroundColor: "#d9dbe0" }}
            />
            <Spacer size="2" />
            <Box
              sx={styles.newNoteInputContainer}
              onClick={(event) => event.stopPropagation()}
            >
              <TextField
                multiline
                rows={7}
                onClick={(event) => event.stopPropagation()}
                onChange={onChange}
                InputProps={{ style: { fontSize: 14 } }}
              />
              <Spacer />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <PrimaryButton
                  text="Adicionar Observação"
                  onClick={() => saveNote()}
                  width="large"
                />
                <SecondaryButton
                  text="Cancelar"
                  onClick={() => setShowNewNoteInput(false)}
                  width="large"
                />
              </Box>
            </Box>
          </Box>
        )}
        {loading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#ffffff33",
              backdropFilter: "blur(2px)",
              WebkitBackdropFilter: "blur(2px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>Carregando...</Text>
          </Box>
        )}
      </Box>
      {!showNewNoteInput && (
        <Box
          sx={styles.addNoteButton}
          onClick={(event) => {
            event.stopPropagation();
            setShowNewNoteInput(true);
          }}
        >
          <Text style={{ fontSize: 20, color: "#fff" }}>+</Text>
        </Box>
      )}
    </Box>
  );
};

const styles = {
  mainContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    gap: 3,
    overflow: "hidden",
  },
  notesContainer: {
    backgroundColor: Colors.background,
    borderRadius: 2,

    flex: 1,
    overflowY: "auto",
    padding: 5,
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  singleNoteContainer: {
    position: "relative",
    backgroundColor: "#ffffffbb",
    border: `1px solid #ededed`,
    marginBottom: 2,
    padding: 2,
    borderRadius: 2,
    width: "80%",
  },
  iconContainer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: 20,
    height: 22,
    opacity: 0.4,
    "&:hover": {
      cursor: "pointer",
      opacity: 1,
    },
  },
  deleteButton: {
    backgroundColor: Colors.red,
    padding: 0.35,
    borderRadius: 2,
    paddingLeft: 2,
    paddingRight: 2,
    "&:hover": { backgroundColor: Colors.red + "dd", cursor: "pointer" },
  },
  noNotesContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  newNoteInputContainer: {
    width: "100%",
    boxSizing: "border-box",
    padding: 2,
    backgroundColor: "#ffffffbb",
    borderRadius: 2,
  },
  addNoteButton: {
    position: "absolute",
    bottom: 20,
    right: 20,
    width: 46,
    height: 46,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.darkGreen,
    "&:hover": {
      backgroundColor: Colors.darkGreen + "cc",
      cursor: "pointer",
    },
  },
};
