class Formatter {
   date(timestamp = null, showHours = false) {
      if (timestamp) {

         const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            ...(showHours && {
               hour: 'numeric',
               minute: 'numeric',
               second: 'numeric'
            })
         }
         const date = new Date(timestamp);
         return new Intl.DateTimeFormat('pt', options).format(date);
      }
      return `-`
   }

   currency(number = null) {
      if (number && typeof number === 'number') {
         return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
      }
      return `-`
   }

   titleCase(string = null) {
      if (string) {
         let splitString = string.toLowerCase().split(' ');
         for (let i = 0; i < splitString.length; i++) {
            splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].substring(1);
         }
         return splitString.join(' ');
      }
      return `-`
   }
}

export const Format = new Formatter;