import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { uid } from '../../../helpers/uid_generator'
import { formatTimestamp } from "../../../helpers/user/formatTimestamp"
import api from "../../../services/api"
import { ContentContainer, Pagination, SearchBar, SectionHeader } from "../../W3libComponents"
import { Box, Text } from "../../atoms"
import { Colors } from "../../organisms/layout/Colors"
import { StatusLabel } from "../../organisms/layout/StatusLabel"

export const Feedbacks = (props) => {

  const { context } = props;
  const { setLoading } = context;
  const [feedbacks, setFeedbacks] = useState([]);
  const [dataFilter, setDataFilter] = useState(() => (item, index) => item)
  const [pagination, setPagination] = useState(10)
  const [searchFilter, setSearchFilter] = useState('')

  const [filters, setFilters] = useState({
    filterName: 'id',
    filterOrder: 'asc'
  })
  const { filterName, filterOrder } = filters;
  const statusOptions = [
    {
      value: 'aberto',
      display: 'Aberto',
      color: Colors.red
    },
    {
      value: 'em andamento',
      display: 'Em andamento',
      color: Colors.yellow
    },
    {
      value: 'pendente',
      display: 'Pendente',
      color: Colors.yellow
    },
    {
      value: 'resolvido',
      display: 'Resolvido',
      color: Colors.green
    }
  ]

  const handleFetchFeedbacks = async () => {
    try {
      setLoading(true)
      const response = await api.get(`/v3/admin/feedbacks`)
      const { data: feedbacks } = response
      setFeedbacks(feedbacks)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'TuimBox Admin - Feedbacks'

    handleFetchFeedbacks()

    if (window.localStorage.getItem('tuimbox-list-feedbacks-filters')) {
      const tuimBoxLocalStorage = JSON.parse(window.localStorage.getItem('tuimbox-list-feedbacks-filters'));
      setFilters({
        filterName: tuimBoxLocalStorage?.filterName,
        filterOrder: tuimBoxLocalStorage?.filterOrder
      })
    }
    if (window.localStorage.getItem('tuimbox-list-feedbacks-pagination')) {
      const tuimboxFeedbackPagination = JSON.parse(window.localStorage.getItem('tuimbox-list-feedbacks-pagination'));
      setPagination(tuimboxFeedbackPagination)
    }
  }, [])

  useEffect(() => {
    window.localStorage.setItem('tuimbox-list-feedbacks-filters', JSON.stringify({ filterName, filterOrder }));
  }, [filters])

  const sortFeedbacks = () => {

    const { filterName, filterOrder } = filters;

    const sortedFeedbacks = feedbacks.filter(feedback =>
      feedback?.subject?.toLowerCase().includes(searchFilter.toLowerCase()) ||
      feedback?.name?.toLowerCase().includes(searchFilter.toLowerCase()) ||
      feedback?.id_user?.name?.toLowerCase().includes(searchFilter.toLowerCase())
    ).sort((a, b) => {
      if (filterName === 'idCode') {
        return (filterOrder === 'desc') ? a[filterName] - b[filterName] : b[filterName] - a[filterName]
      }
      if (filterName === 'id_user') {
        if ((String(a[filterName]?.name).toLowerCase() || String(a?.name).toLowerCase()) > (String(b[filterName]?.name).toLowerCase() || String(b?.name).toLowerCase())) return (filterOrder === 'asc') ? 1 : -1
        if ((String(a[filterName]?.name).toLowerCase() || String(a?.name).toLowerCase()) < (String(b[filterName]?.name).toLowerCase() || String(b?.name).toLowerCase())) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
      if (filterName === 'user_type' || filterName === 'subject' || filterName === 'status') {
        if (a[filterName] > b[filterName]) return (filterOrder === 'asc') ? 1 : -1
        if (a[filterName] < b[filterName]) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
      if (filterName === 'email') {
        if ((a?.id_user?.[filterName] || a?.[filterName]) > (b?.id_user?.[filterName] || b?.[filterName])) return (filterOrder === 'asc') ? 1 : -1
        if ((a?.id_user?.[filterName] || a?.[filterName]) < (b?.id_user?.[filterName] || b?.[filterName])) return (filterOrder === 'asc') ? -1 : 1
        return 0
      } else {
        if (a?.[filterName] > b?.[filterName]) return (filterOrder === 'asc') ? 1 : -1
        if (a?.[filterName] < b?.[filterName]) return (filterOrder === 'asc') ? -1 : 1
        return 0
      }
    })
    return sortedFeedbacks
  }

  return (
    <>
      <SectionHeader
        title={`Feedbacks`}
        subtitle={`Abertos ou Pendentes: ${feedbacks.filter(i => i.status !== 'Resolvido').length}`}
      />
      <ContentContainer>
        <Box sx={{ display: 'flex', gap: 3 }}>
          <SearchBar placeholder='Sugestão, Outro, Bruno Silva...' onChange={({ target }) => setSearchFilter(target.value)} />
          <Pagination
            localStorageName='tuimbox-list-feedbacks-pagination'
            dataLength={feedbacks.length}
            onChange={setDataFilter}
            initialItemsPerPage={pagination}
          />
        </Box>

        <Box style={{ border: '1px solid #e4e4e4', backgroundColor: '#fff', borderRadius: 5, overflow: 'hidden' }}>
          <Box style={{ width: '100%', display: 'flex' }}>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 70 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'idCode' ? 'underline' : 'none' }}>ID</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'idCode' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'idCode', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 150 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'status' ? 'underline' : 'none' }}>Status</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'status' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'status', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 150 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'createdAt' ? 'underline' : 'none' }}>Data</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'createdAt' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'createdAt', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 250 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'subject' ? 'underline' : 'none' }}>Motivo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'subject' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'subject', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, flex: 1 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'id_user' ? 'underline' : 'none' }}>Nome</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'id_user' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'id_user', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
            <Box style={{ ...styles.tableHeadRow, height: 50, width: 200 }}>
              <Text style={{ ...styles.tableHeadText, textDecorationLine: filterName === 'user_type' ? 'underline' : 'none' }}>Tipo</Text>
              <Box sx={{
                ...styles.checkIcon,
                ...styles.filterIconContainer,
                backgroundImage: filterName === 'user_type' ? filterOrder === 'asc' ? `url('/icons/gray_arrow_down.png')` : `url('/icons/gray_arrow_up.png')` : `url('/icons/gray_arrow_down.png')`
              }}
                onClick={() => setFilters({ filterName: 'user_type', filterOrder: filters?.filterOrder === 'asc' ? 'desc' : 'asc' })}
              />
            </Box>
          </Box>

          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            {sortFeedbacks().filter(dataFilter).map((item, index) =>
              <Link
                key={`feedbacks_report_${uid()}`}
                to={`/relatorios/feedbacks/${item._id}`}
                style={{ textDecoration: 'none' }}
              >
                <Box sx={styles.tableRow}>
                  <Box style={{ ...styles.row, width: 70 }}><Text style={{ ...styles.text, textAlign: 'center' }}>{item?.idCode}</Text></Box>
                  <Box style={{ ...styles.row, width: 150 }}>
                    <StatusLabel
                      background='light'
                      currentStatus={item?.status}
                      dropDownOptions={statusOptions}
                    />
                  </Box>
                  <Box style={{ ...styles.row, width: 150 }}><Text style={{ ...styles.text, textAlign: 'center' }}>{formatTimestamp({ timestamp: item?.createdAt, showTime: false })}</Text></Box>
                  <Box style={{ ...styles.row, width: 250 }}><Text style={{ ...styles.text, textAlign: 'center' }}>{item?.subject}</Text></Box>
                  <Box style={{ ...styles.row, flex: 1 }}><Text style={{ ...styles.text, textAlign: 'center' }}>{item?.id_user?.name || item?.name || '-'}</Text></Box>
                  <Box style={{ ...styles.row, width: 200 }}><Text style={{ ...styles.text, textAlign: 'center' }}>{item?.user_type}</Text></Box>
                </Box>
              </Link>
            )}
          </Box>
        </Box>
      </ContentContainer>
    </>
  )
}

const styles = {
  mainContainer: {
    width: '100%',
    position: 'relative'
  },
  tableHeadRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableHeadText: {
    fontSize: '14px',
    color: '#333',
    textAlign: 'center',
    fontFamily: 'UbuntuBold',
    marginLeft: 7,
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: '#e4e4e4',
    height: 45,
    "&:hover": {
      backgroundColor: '#6b858e14',
      cursor: 'pointer'
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  sectionTitle: {
    fontWeight: 500,
    color: '#333'
  },
  tableRowHover: {
    "&:hover": {
      cursor: 'pointer'
    }
  },
  textTitle: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#333'
  },
  text: {
    fontSize: '14px',
    color: '#333'
  },
  headerContainer: {
    display: 'flex',
    marginBottom: 22
  },
  feedbackStatus: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'red'
  },
  checkIcon: {
    backgroundImage: `url('/icons/green_check_icon.png')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 20,
    height: 20,
    "&:hover": {
      cursor: 'pointer'
    }
  },
  filterIconContainer: {
    padding: 0.5,
    borderRadius: 6.5,
    backgroundOrigin: 'content-box',
    width: 13,
    height: 13,
    "&:hover": {
      backgroundColor: Colors.lightBackground,
      cursor: 'pointer'
    }
  }
}