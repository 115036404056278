import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import { ContentContainer, SectionHeader, TableCell, TableRow, Text } from "../../W3libComponents";
import { Table } from "../../organisms/table/Table";

const tableHeader = [
  { text: 'Nome' },
  { text: 'E-mail' },
  { text: 'Permissão' },
];

export const UserList = ({ context }) => {
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const { setLoading } = context

  useEffect(() => {
    document.title = `TuimBox Admin - Usuários`
    setLoading(true)
    api.get(`/v3/admin/user`).then((response) => {
      setLoading(false)
      setUsers(response.data.users);
    })
      .catch((err) => {
        setLoading(false)
        console.log(err);
      });
  }, []);

  const handleNewUser = useCallback(() => history.push(`/user/new`), [history]);

  return (
    <>
      <SectionHeader
        title={`Usuários`}
        newButtonAction={() => handleNewUser()}
        newButton={true}
      />

      <ContentContainer>
        <Table
          size='small'
          header={tableHeader}
          rows={users?.map((user, index) => {
            return {
              to: `/user/${user._id}`,
              data: [
                <Text>{user?.name || '-'}</Text>,
                <Text>{user?.email || '-'}</Text>,
                <Text>{user?.permission || '-'}</Text>
              ]
            }
          })}
        />
      </ContentContainer>
    </>
  );
};

const UserListRow = ({ user }) => {
  const history = useHistory();
  const handleUser = useCallback(
    (id) => history.push(`/user/${id}`),
    [history]
  );

  return (
    <TableRow
      hover
      sx={{ cursor: "pointer" }}
      onClick={() => handleUser(user?._id)}
    >
      <TableCell align="left" aria-label="Nome">
        <Text sx={{ fontSize: 14 }}>{user?.name}</Text>
      </TableCell>
      <TableCell align="left" aria-label="E-mail">
        <Text sx={{ fontSize: 14 }}>{user?.email}</Text>
      </TableCell>
    </TableRow >
  );
};
