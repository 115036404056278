import { Box, Text, TextField } from "../../W3libComponents";
import { Colors } from "../../organisms/layout/Colors";
import { useCallback, useRef } from "react";
import { SecondaryButton } from "../../organisms/button/Buttons";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

export const ForgotPassword = ({ context }) => {
  const { alert, setLoading } = context;

  const email = useRef("");
  const history = useHistory();

  const smallWidthDevice = window.innerWidth < 1000;

  const handleChange = useCallback(
    (e) => {
      email.current = e.target.value;
    },
    [email]
  );

  const handleSendEmail = useCallback(async () => {
    setLoading(true);
    try {
      if (email.current === "")
        return alert.error("Parece que você não preencheu um dos campos.");

      const { data } = await api.post("/v3/app/forgotpassword", {
        email: email.current,
      });
      if (!data.success) return alert.error("Email não encontrado.");

      return history.push(`/reset-password/${data.token}`);
    } catch (error) {
      return alert.error(error.response.data.error);
    } finally {
      setLoading(false);
    }
  }, [alert, history, setLoading]);

  return (
    <Box style={styles.container}>
      <div style={styles.firstBackground} />
      <div style={styles.secondBackground} />
      <div style={styles.thirdBackground} />
      <div style={styles.fourthBackground} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 6,
          alignItems: "center",
          maxWidth: smallWidthDevice ? "80%" : "540px",
          margin: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            flex: 1,
            gap: 8,
            padding: smallWidthDevice ? 5 : 7,
            borderRadius: "12px",
            backgroundColor: Colors.darkGreen,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Text
              style={{ fontSize: 25, fontFamily: "UbuntuBold", color: "#fff" }}
            >
              Esqueci minha senha.
            </Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              width: "100%",
            }}
          >
            <TextField
              placeholder="email@tuimbox.com.br"
              onChange={handleChange}
              name="email"
              margin="none"
              fullWidth
              InputProps={{
                style: {
                  fontSize: 14,
                  backgroundColor: "#ffffff22",
                  color: "#ffffffbb",
                  outline: "none",
                },
              }}
            />

            <Text style={{ color: "#ffffff77" }}>
              Coloque seu e-mail registrado e iremos enviar um token para que
              você possa redefinir sua senha.
            </Text>
          </Box>
          <SecondaryButton
            style={{
              paddingTop: 2,
              paddingBottom: 2,
              backgroundColor: "#f1f1f1",
            }}
            text="Alterar minha senha"
            onClick={handleSendEmail}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    flex: 1,
    backgroundColor: Colors.background,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  },
  firstBackground: {
    position: "absolute",
    top: -370,
    right: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
  secondBackground: {
    position: "absolute",
    top: 70,
    left: 400,
    width: 200,
    height: 200,
    borderRadius: 8,
    transform: "rotate(-10deg)",
    backgroundColor: Colors.darkGreen + "21",
  },
  thirdBackground: {
    position: "absolute",
    bottom: 60,
    right: 250,
    width: 200,
    height: 200,
    borderRadius: "50%",
    backgroundColor: Colors.darkGreen + "21",
  },
  fourthBackground: {
    position: "absolute",
    bottom: -370,
    left: -60,
    width: 300,
    height: 600,
    borderRadius: "50%",
    transform: "rotate(-45deg)",
    backgroundColor: Colors.darkGreen + "11",
  },
};
