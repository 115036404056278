import { Dialog, DialogActions, DialogContent, DialogContentText, Text, Button } from '../../W3libComponents'

export const ErrorDialog = (props) => {

    const { title, msg, handleClose } = props
    return (
        <Dialog 
        fullWidth='sm'
        maxWidth='sm'
        open={true}>
            <Text style={{ padding: 10, fontSize: 20, fontWeight: 700 }}>{title}</Text>
            <DialogContent>
                <DialogContentText>
                    {msg}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant='contained'>Ok</Button>
            </DialogActions>
        </Dialog>)
}